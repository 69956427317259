import { Autocomplete, FormHelperText, Stack, TextField } from "@mui/material";
import {
  isArray,
  isEmpty,
  isFunction,
  isObject,
  sortBy,
  sortedUniqBy,
} from "lodash";
import { ChangeEvent, memo } from "react";
import { SafeLink } from "../../SafeLink";
import {
  DataPoint,
  Log,
  Span,
  useSnapshot,
} from "../../SnapShotConsole/SnapshotContext";
import { FieldType, MetricDataType } from "../../SnapShotConsole/types";
import { getFieldValues } from "../../SnapShotConsole/utils";
import { useValidationContext } from "../ValidationContext";
import { validateOTTLField } from "../validation-functions";
import { ConditionMatch } from "./ConditionInput";
import { ParamInputProps } from "./ParameterInput";

interface OTTLFieldInputProps extends ParamInputProps<string> {
  getOptions?: (
    match: ConditionMatch,
    logs: Log[],
    metrics: DataPoint[],
    traces: Span[],
    processedLogs: Log[],
    processedMetrics: DataPoint[],
    processedTraces: Span[],
  ) => string[];
}

const OTTLFieldInputComponent: React.FC<OTTLFieldInputProps> = ({
  getOptions = getFieldKeyOptions,
  definition,
  value,
  readOnly,
  onValueChange,
}) => {
  const { errors, touched, touch, setError } = useValidationContext();
  const {
    logs,
    metrics,
    traces,
    processedLogs,
    processedMetrics,
    processedTraces,
  } = useSnapshot();

  function handleValueChange(e: ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    isFunction(onValueChange) && onValueChange(e.target.value);

    if (!touched[definition.name]) {
      touch(definition.name);
    }

    setError(definition.name, validateOTTLField(value, definition.required));
  }

  return (
    <Autocomplete
      sx={{ flexGrow: 1 }}
      freeSolo
      options={getOptions(
        definition.options?.ottlContext as ConditionMatch,
        logs,
        metrics,
        traces,
        processedLogs,
        processedMetrics,
        processedTraces,
      )}
      slotProps={{
        popper: {
          placement: "bottom-start",
          style: {
            width: "auto",
            left: "0px",
          },
        },
      }}
      value={value}
      onChange={(_e, newValue) => {
        isFunction(onValueChange) && onValueChange(newValue || "");
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={params.InputProps}
          value={value || ""}
          onChange={handleValueChange}
          disabled={readOnly}
          onBlur={() => touch(definition.name)}
          name={definition.name}
          fullWidth
          size="small"
          label={definition.label}
          FormHelperTextProps={{
            sx: { paddingLeft: "-2px" },
          }}
          helperText={
            <>
              {errors[definition.name] && touched[definition.name] && (
                <>
                  <FormHelperText sx={{ marginLeft: 0 }} component="span" error>
                    {errors[definition.name]}
                  </FormHelperText>
                  <br />
                </>
              )}

              {!isEmpty(definition.description) && (
                <FormHelperText sx={{ marginLeft: 0 }} component="span">
                  {definition.description}
                </FormHelperText>
              )}
              {definition.documentation && (
                <Stack component={"span"}>
                  {definition.documentation?.map((d) => (
                    <SafeLink
                      href={d.url}
                      rel="noreferrer"
                      target="_blank"
                      key={d.url}
                    >
                      {d.text}
                    </SafeLink>
                  ))}
                </Stack>
              )}
            </>
          }
          required={definition.required}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
        />
      )}
    />
  );
};

export const OTTLFieldInput = memo(OTTLFieldInputComponent);

function getFieldKeyOptions(
  match: ConditionMatch,
  logs: Log[],
  metrics: DataPoint[],
  traces: Span[],
  processedLogs: Log[],
  processedMetrics: DataPoint[],
  processedTraces: Span[],
): string[] {
  return sortedUniqBy(
    sortBy(
      [
        ...getFieldOptions(match, logs, metrics, traces),
        ...getFieldOptions(
          match,
          processedLogs,
          processedMetrics,
          processedTraces,
        ),
      ],
      sortKey,
    ),
    (p) => p.toLocaleLowerCase(),
  );
}

function getFieldOptions(
  match: ConditionMatch,
  logs: Log[],
  metrics: DataPoint[],
  traces: Span[],
): string[] {
  let paths: string[] = [];

  switch (match) {
    case ConditionMatch.BODY:
      logs.forEach((log) => {
        paths = getPaths(log.body, "", paths);
      });
      break;
    case ConditionMatch.ATTRIBUTES:
      logs.forEach((log) => {
        paths = getPaths(log.attributes, "", paths);
      });
      metrics.forEach((metric) => {
        paths = getPaths(metric.attributes, "", paths);
      });
      traces.forEach((trace) => {
        paths = getPaths(trace.attributes, "", paths);
      });
      break;
    case ConditionMatch.RESOURCE:
      logs.forEach((log) => {
        paths = getPaths(log.resource, "", paths);
      });
      metrics.forEach((metric) => {
        paths = getPaths(metric.resource, "", paths);
      });
      traces.forEach((trace) => {
        paths = getPaths(trace.resource, "", paths);
      });
      break;
    case ConditionMatch.METRIC:
      paths = [
        "name",
        "description",
        "type",
        "unit",
        "aggregation_temporality",
        "is_monotonic",
      ];
      break;
    case ConditionMatch.DATAPOINT:
      // some of the commented fields aren't useful for OTTL
      const metricTypeFields: { [metricType: number]: string[] } = {
        [MetricDataType.METRIC_DATA_TYPE_GAUGE]: ["value_int", "value_double"],
        [MetricDataType.METRIC_DATA_TYPE_SUM]: ["value_int", "value_double"],
        [MetricDataType.METRIC_DATA_TYPE_HISTOGRAM]: [
          "count",
          "sum",
          // "explicit_bounds",
          // "bucket_counts",
        ],
        [MetricDataType.METRIC_DATA_TYPE_EXPONENTIAL_HISTOGRAM]: [
          "count",
          "sum",
          "scale",
          "zero_count",
          "positive.offset",
          // "positive.bucket_counts",
          "negative.offset",
          // "negative.bucket_counts",
        ],
        [MetricDataType.METRIC_DATA_TYPE_SUMMARY]: ["count", "sum"],
      };
      for (const metricType of [
        MetricDataType.METRIC_DATA_TYPE_GAUGE,
        MetricDataType.METRIC_DATA_TYPE_SUM,
        MetricDataType.METRIC_DATA_TYPE_HISTOGRAM,
        MetricDataType.METRIC_DATA_TYPE_EXPONENTIAL_HISTOGRAM,
        MetricDataType.METRIC_DATA_TYPE_SUMMARY,
      ]) {
        if (metrics.some((m) => m.type === metricType)) {
          paths.push(...(metricTypeFields[metricType] ?? []));
        }
      }
      paths.sort();
      break;
    case ConditionMatch.LOG:
      paths = ["body", "severity_text", "severity_number"];
      break;
    case ConditionMatch.SPAN:
      paths = ["name", "kind", "kind.string", "status.code", "status.message"];
      break;
  }

  return paths;
}

function getPaths(obj: any, prefix = "", paths: string[] = []): string[] {
  if (!isObject(obj)) return paths;

  for (const key in obj) {
    if (!obj.hasOwnProperty(key)) continue;
    if (key === "__bindplane_id__") continue;

    const formattedKey = prefix
      ? isArray(obj)
        ? `[${key}]`
        : `["${key}"]`
      : key;
    const path = `${prefix}${formattedKey}`;

    if (!paths.includes(path)) {
      paths.push(path);
    }

    const value = (obj as any)[key];
    if (isObject(value)) {
      paths = getPaths(value, path, paths);
    }
  }

  return paths;
}

function sortKey(path: string): string {
  if (path.startsWith("[")) {
    return path.slice(2);
  }
  return path;
}

export function getFieldValueOptions(
  fieldKey: string,
): (
  match: ConditionMatch,
  logs: Log[],
  metrics: DataPoint[],
  traces: Span[],
  processedLogs: Log[],
  processedMetrics: DataPoint[],
  processedTraces: Span[],
) => string[] {
  return (
    match: ConditionMatch,
    logs: Log[],
    metrics: DataPoint[],
    traces: Span[],
    processedLogs: Log[],
    processedMetrics: DataPoint[],
    processedTraces: Span[],
  ) => {
    const allTelemetry = [
      ...logs,
      ...metrics,
      ...traces,
      ...processedLogs,
      ...processedMetrics,
      ...processedTraces,
    ];
    return getFieldValues(
      fieldKey,
      match as unknown as FieldType,
      allTelemetry,
    ).map((s) => String(s));
  };
}
