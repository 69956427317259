import { memo } from "react";
import { hasPipelineTypeFlag } from "../../../types/configuration";
import { getRCFromSpec } from "../../../utils/classes/configuration";
import { CardMeasurementContent } from "../../CardMeasurementContent/CardMeasurementContent";
import { ResourceDestinationCard } from "../../Cards/ResourceDestinationCard";
import { useV2PipelineGraph } from "../PipelineGraphV2Context";
import { useRouting } from "../RoutingContext/RoutingContext";
import { RoutingNodeWrapper } from "../RoutingContext/RoutingNodeWrapper";
import { AttributeName } from "../types";

function DestinationNode({
  data,
}: {
  data: {
    pipelineType: string;
    id: string;
    label: string;
    attributes: Record<string, any>;
    metric: string;
    connectedNodesAndEdges: string[];
  };
}) {
  const {
    hoveredSet,
    setHoveredSet,
    selectedTelemetryType,
    configuration,
    refetchConfiguration,
    readOnlyGraph,
  } = useV2PipelineGraph();
  const { connectableComponentPaths } = useRouting();

  const { id, attributes, metric } = data;

  const isConnectable = connectableComponentPaths.includes(
    attributes["componentPath"],
  );

  const isInactive = !hasPipelineTypeFlag(
    selectedTelemetryType,
    attributes[AttributeName.ActiveTypeFlags],
  );

  const isNotInHoverSet =
    hoveredSet.length > 0 && !hoveredSet.find((elem) => elem === data.id);

  if (configuration?.spec == null) {
    return null;
  }

  if (
    getRCFromSpec(
      configuration.spec,
      "destination",
      attributes[AttributeName.DestinationIndex],
    ) == null
  ) {
    return null;
  }

  return (
    <RoutingNodeWrapper
      nodeType={"destination"}
      index={attributes[AttributeName.DestinationIndex]}
      componentPath={attributes[AttributeName.ComponentPath]}
    >
      <div
        onMouseEnter={() => {
          setHoveredSet(data.connectedNodesAndEdges);
        }}
        onMouseLeave={() => setHoveredSet([])}
      >
        <ResourceDestinationCard
          // Override the onClick so we don't open the edit dialog when trying to connect.
          onClick={isConnectable ? () => {} : undefined}
          key={id}
          destinationIndex={attributes[AttributeName.DestinationIndex]}
          name={attributes.resourceId}
          disabled={isInactive || isNotInHoverSet}
          onDeleteSuccess={() => setHoveredSet([])}
          configuration={configuration}
          refetchConfiguration={refetchConfiguration}
          readOnly={!!readOnlyGraph}
        />
        <CardMeasurementContent>{metric}</CardMeasurementContent>
      </div>
    </RoutingNodeWrapper>
  );
}

export default memo(DestinationNode);
