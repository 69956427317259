import React from "react";
import { useNavigate } from "react-router-dom";
import { TwoStepBreadcrumb } from "../../components/CardContainer";
import { withEENavBar } from "../../components/EENavBar";
import { withRBAC } from "../../contexts/RBAC";
import { withRequireLogin } from "../../contexts/RequireLogin";
import { RawConfigWizard } from "./wizards/RawConfigWizard";

export const NewRawConfigurationPageContent: React.FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <TwoStepBreadcrumb
        navLink="/configurations"
        navLabel="Configurations"
        current="New"
        spacing="short"
      />
      <RawConfigWizard onSuccess={() => navigate("/configurations")} />;
    </>
  );
};

export const NewRawConfigurationPage: React.FC = withRequireLogin(
  withRBAC(withEENavBar(NewRawConfigurationPageContent)),
);
