import { ApolloError, gql } from "@apollo/client";
import {
  Stack,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { MouseEvent } from "react";
import {
  MoreVertical,
  UserMinusIcon,
  UserPlusIcon,
  UserXIcon,
} from "../../../components/Icons";
import {
  useDemoteUserMutation,
  usePromoteUserMutation,
  useRemoveUserFromOrgMutation,
} from "../../../graphql/generated";
import colors from "../../../styles/colors";

gql`
  mutation promoteUser($input: UserIDInput!) {
    promoteOrgAdmin(input: $input)
  }

  mutation demoteUser($input: UserIDInput!) {
    demoteOrgAdmin(input: $input)
  }

  mutation removeUserFromOrg($input: UserIDInput!) {
    removeUserFromOrganization(input: $input)
  }
`;

interface UserOptionsCellProps {
  isOrgAdmin: boolean;
  refetch: () => void;
  id: string;
}

export const UserOptionsCell: React.FC<UserOptionsCellProps> = ({
  isOrgAdmin,
  refetch,
  id,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { enqueueSnackbar } = useSnackbar();

  function onError(error: ApolloError) {
    console.error(error);
    enqueueSnackbar(error.message, {
      variant: "error",
    });
  }

  const [[promoteUser], [demoteUser], [removeUser]] = [
    usePromoteUserMutation({
      variables: {
        input: {
          userId: id,
        },
      },
      onError,
    }),
    useDemoteUserMutation({
      variables: {
        input: {
          userId: id,
        },
      },
      onError,
    }),
    useRemoveUserFromOrgMutation({
      variables: {
        input: {
          userId: id,
        },
      },
      onError,
    }),
  ];

  function handleButtonClick(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  async function handleDemoteClick() {
    await demoteUser();
    refetch();
    setAnchorEl(null);
  }

  async function handlePromoteClick() {
    await promoteUser();
    refetch();
    setAnchorEl(null);
  }

  async function handleRemoveClick() {
    await removeUser();
    refetch();
    setAnchorEl(null);
  }

  return (
    <Stack
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
    >
      <IconButton
        onClick={handleButtonClick}
        data-testid={`user-menu-btn-${id}`}
      >
        <MoreVertical />
      </IconButton>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        {isOrgAdmin ? (
          <MenuItem onClick={handleDemoteClick}>
            <ListItemIcon>
              <UserMinusIcon width={20} />
            </ListItemIcon>
            <ListItemText>
              <Typography>Demote from Organization Admin</Typography>
            </ListItemText>
          </MenuItem>
        ) : (
          <MenuItem onClick={handlePromoteClick}>
            <ListItemIcon>
              <UserPlusIcon width={20} />
            </ListItemIcon>
            <ListItemText>Make Organization Admin</ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={handleRemoveClick}>
          <ListItemIcon>
            <UserXIcon color={colors.muiErrorDark} width={20} />
          </ListItemIcon>
          <ListItemText>
            <Typography color="error">Remove from Organization</Typography>
          </ListItemText>
        </MenuItem>
      </Menu>
    </Stack>
  );
};
