import { Autocomplete, TextField } from "@mui/material";
import { Config } from "./types";

interface ConfigurationSelectProps {
  setSelectedConfig: (c: Config | undefined) => void;
  selectedConfig?: Config;
  configurations: Config[];
}

export const ConfigurationSelect: React.FC<ConfigurationSelectProps> = ({
  setSelectedConfig,
  selectedConfig,
  configurations,
}) => {
  function onSelectChange(configName: string) {
    if (configName !== "") {
      setSelectedConfig(
        configurations.find((c) => c.metadata.name === configName)!,
      );
    } else {
      setSelectedConfig(undefined);
    }
  }

  return (
    <Autocomplete
      options={configurations.map((c: Config) => c.metadata.name)}
      renderInput={(params) => <TextField {...params} label="Configurations" />}
      onChange={(_event, newValue) => onSelectChange(newValue || "")}
      value={selectedConfig?.metadata.name || ""}
    />
  );
};
