import { gql } from "@apollo/client";
import { Button, Card, Stack, Tooltip, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AssistedWizardFormValues, ResourceConfigurationAction } from ".";
import { ConfirmDeleteResourceDialog } from "../../../../components/ConfirmDeleteResourceDialog";
import { BookmarkIcon, PlusCircleIcon } from "../../../../components/Icons";
import {
  DialogResource,
  NewResourceDialog,
} from "../../../../components/ResourceDialog";
import { EditResourceDialog } from "../../../../components/ResourceDialog/EditResourceDialog";
import { EditDestinationDialog } from "../../../../components/Tables/DestinationsTable/EditDestinationDialog";
import { useWizard } from "../../../../components/Wizard/WizardContext";
import {
  Destination,
  DestinationType,
  Kind,
  ProcessorType,
  SourceType,
  useDestinationsAndTypesQuery,
} from "../../../../graphql/generated";
import colors from "../../../../styles/colors";
import {
  APIVersion,
  Resource,
  UpdateStatus,
} from "../../../../types/resources";
import { BPConfiguration } from "../../../../utils/classes/configuration";
import { BPResourceConfiguration } from "../../../../utils/classes/resource-configuration";
import { applyResources } from "../../../../utils/rest/apply-resources";
import { trimVersion } from "../../../../utils/version-helpers";
import styles from "./assisted-config-wizard.module.scss";

type ResourceType = SourceType | DestinationType | ProcessorType;

gql`
  query DestinationsAndTypes {
    destinationTypes {
      kind
      apiVersion
      metadata {
        id
        version
        name
        displayName
        stability
        description
        icon
        version
        additionalInfo {
          message
          documentation {
            text
            url
          }
        }
        resourceDocLink
      }
      spec {
        version
        parameters {
          label
          type
          name
          description
          default
          validValues
          relevantIf {
            name
            value
            operator
          }
          documentation {
            text
            url
          }
          advancedConfig
          required
          options {
            creatable
            multiline
            trackUnchecked
            sectionHeader
            subHeader
            horizontalDivider
            gridColumns
            labels
            metricCategories {
              label
              column
              metrics {
                name
                description
                kpi
              }
            }
            password
            sensitive
            subHeader
            horizontalDivider
            variant
          }
        }
        supportedPlatforms
        telemetryTypes
      }
    }
    destinations {
      kind
      metadata {
        id
        version
        name
      }
      spec {
        type
        parameters {
          name
          value
        }
        disabled
      }
    }
  }
`;

export const StepThree: React.FC = () => {
  const { goToStep, formValues, setValues } =
    useWizard<AssistedWizardFormValues>();

  const snackbar = useSnackbar();

  const [addDestinationOpen, setAddDestinationOpen] = useState(false);
  const [editingDestination, setEditingDestination] = useState<boolean>(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const { data } = useDestinationsAndTypesQuery({
    fetchPolicy: "network-only",
    onError() {
      enqueueSnackbar(
        "There was an error retrieving Destinations and DestinationTypes.",
        {
          variant: "error",
        },
      );
    },
  });
  const navigate = useNavigate();

  async function onSaveConfiguration() {
    // Resources to create, could be just the Configuration or
    // a Configuration and a Destination.
    const resources: Resource[] = [];

    // Create the destination resource if present and not an existing destination.
    if (formValues.destination != null && formValues.destination.create) {
      const destinationResource: Destination = {
        apiVersion: APIVersion.V1,
        kind: Kind.Destination,
        spec: {
          type: formValues.destination.resourceConfiguration.type!,
          parameters: formValues.destination.resourceConfiguration.parameters,
          disabled: false,
        },
        metadata: {
          name: formValues.destination.resourceConfiguration.name!,
          id: formValues.destination.resourceConfiguration.name!,
          version: 0,
        },
      };

      resources.push(destinationResource);
    }

    // Create the configuration
    const configuration = new BPConfiguration({
      metadata: {
        id: formValues.name,
        name: formValues.name,
        description: formValues.description,
        labels: {
          platform: isEmpty(formValues.secondaryPlatform)
            ? formValues.platform
            : formValues.secondaryPlatform,
        },
        version: 0,
      },
    });

    for (const src of formValues.sources) {
      configuration.addSource(src);
    }

    if (formValues.destination) {
      configuration.addDestination({
        name: formValues.destination.resourceConfiguration.name,
        disabled: false,
      });
    }

    configuration.addMatchLabels({ configuration: formValues.name });

    resources.push(configuration);

    // Apply Resources
    try {
      const { updates } = await applyResources(resources);
      const update = updates.find(
        (u) => u.resource.metadata.name === formValues.name,
      );

      if (update == null) {
        throw new Error(
          `failed to create configuration, no configuration returned with name ${formValues.name}`,
        );
      }

      // Configuration was returned but not created, likely if it was valid.
      if (update.status !== UpdateStatus.CREATED) {
        throw new Error(
          `failed to create configuration, got status ${update.status}`,
        );
      }

      const configPagePath = `/configurations/${update.resource.metadata.name}`;
      // Redirect to configuration page
      navigate(configPagePath);
    } catch (err) {
      snackbar.enqueueSnackbar("Failed to create configuration.", {
        variant: "error",
      });
      console.error(err);
      return;
    }
  }

  // This is the callback passed to the Dialog when a new Destination is created.
  // Here we need to set the formValues.destination with a new Resource configuration
  // with create = true.
  function onNewDestinationSave(
    values: { [key: string]: any },
    resourceType: ResourceType,
  ) {
    const resourceConfiguration = new BPResourceConfiguration({
      type: resourceType.metadata.name,
      disabled: false,
    });
    resourceConfiguration.setParamsFromMap(values);
    const destinationConfiguration: ResourceConfigurationAction = {
      resourceConfiguration,
      create: true,
    };

    setValues({ destination: destinationConfiguration });
    setAddDestinationOpen(false);
  }

  function onChooseExistingDestination(resource: DialogResource) {
    setValues({
      destination: {
        resourceConfiguration: {
          name: resource.metadata.name,
          type: resource.spec.type,
          disabled: resource.spec.disabled ?? false,
        },
        create: false,
      },
    });
    setAddDestinationOpen(false);
  }

  function onEditDestinationSave(values: { [key: string]: any }) {
    const newDestination = new BPResourceConfiguration({
      name: formValues.destination?.resourceConfiguration.name,
      type: formValues.destination?.resourceConfiguration.type,
      disabled: formValues.destination?.resourceConfiguration.disabled ?? false,
    });
    newDestination.setParamsFromMap(values);

    setValues({
      destination: { resourceConfiguration: newDestination, create: true },
    });
    setEditingDestination(false);
  }

  function onDestinationDelete() {
    setConfirmDeleteOpen(false);
    setValues({ destination: null });
    setEditingDestination(false);
  }

  function renderEditDestinationDialog() {
    const currentDestinationType = data?.destinationTypes.find(
      (st) =>
        st.metadata.name ===
        trimVersion(formValues.destination?.resourceConfiguration.type ?? ""),
    );

    if (currentDestinationType == null || formValues.destination == null) {
      return null;
    }

    return !formValues.destination.create ? (
      editingDestination && (
        <EditDestinationDialog
          name={formValues.destination.resourceConfiguration.name ?? ""}
          onSaveSuccess={() => {}}
          onCancel={() => {
            setEditingDestination(false);
          }}
          readOnly
        />
      )
    ) : (
      <EditResourceDialog
        resourceTypeDisplayName={
          formValues.destination.resourceConfiguration.name!
        }
        description={currentDestinationType.metadata.description ?? ""}
        fullWidth
        maxWidth="sm"
        parameterDefinitions={currentDestinationType.spec.parameters}
        parameters={
          formValues.destination?.resourceConfiguration.parameters ?? []
        }
        open={editingDestination}
        onClose={() => setEditingDestination(false)}
        onCancel={() => {
          setEditingDestination(false);
        }}
        onDelete={() => setConfirmDeleteOpen(true)}
        onSave={onEditDestinationSave}
        kind={Kind.Destination}
      />
    );
  }

  function renderDestinationAccordion() {
    if (formValues.destination == null) {
      return null;
    }

    const destinationConfig = new BPResourceConfiguration(
      formValues.destination!.resourceConfiguration,
    );

    const destinationType = data?.destinationTypes.find(
      (dt) => dt.metadata.name === trimVersion(destinationConfig.type!),
    );

    const icon = destinationType?.metadata.icon;

    const labelTitle = `${destinationType?.metadata.displayName ?? "Unknown"}${destinationConfig.name ? ": " : ""}${destinationConfig.name ?? "Unknown"}`;

    return (
      <Card style={{ marginTop: "4px" }}>
        <Stack
          key={`step-three-destination-card-${destinationType?.metadata.name}`}
          data-testid={`step-three-destination-card`}
          padding={"12px 16px"}
          minHeight={"48px"}
        >
          <Stack
            direction={"row"}
            alignItems="center"
            alignContent={"space-between"}
            spacing={1}
            position={"relative"}
          >
            <span
              className={styles.icon}
              style={{ backgroundImage: `url(${icon})` }}
            />
            <Tooltip title={labelTitle}>
              <Typography
                fontWeight={600}
                maxWidth={"65%"}
                overflow={"hidden"}
                whiteSpace={"nowrap"}
                textOverflow={"ellipsis"}
              >
                {labelTitle}
              </Typography>
            </Tooltip>
            <Stack
              direction="row"
              style={{ position: "absolute", right: "0px" }}
            >
              {!formValues.destination.create && (
                <BookmarkIcon
                  width={18}
                  height={18}
                  stroke={colors.pixelPointBlue}
                  fill={colors.pixelPointBlue}
                  style={{ position: "relative", top: "-17px", left: "2px" }}
                />
              )}
              <Button onClick={() => setEditingDestination(true)}>
                {!formValues.destination.create ? "View" : "Edit"}
              </Button>
              <Button color="error" onClick={() => setConfirmDeleteOpen(true)}>
                Remove
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Card>
    );
  }

  return (
    <>
      <div className={styles.container} data-testid="step-three">
        <Stack spacing={2} marginBottom={2}>
          <Typography variant="h6" fontWeight={600}>
            Add a destination where you'd like to send your telemetry.
          </Typography>
          <Typography>
            A destination simply represents where you'd like to send your
            telemetry data. You can configure that here. Depending on the
            destination you choose, we'll configure specific OpenTelemetry
            processors for you, ensuring your data shows up in a useful state.
          </Typography>
        </Stack>

        {/* ------------------- Add Destination button or Accordion ------------------ */}
        <div>
          {formValues.destination == null ? (
            <Button
              variant="contained"
              endIcon={<PlusCircleIcon />}
              onClick={() => setAddDestinationOpen(true)}
              data-testid="add-destination-button"
            >
              Add Destination
            </Button>
          ) : (
            renderDestinationAccordion()
          )}
        </div>
      </div>

      {/* ---------------------- Back and Save footer buttons ---------------------- */}
      <Stack direction="row" justifyContent={"space-between"}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => goToStep(1)}
        >
          Back
        </Button>
        <Button
          data-testid="save-button"
          variant="contained"
          onClick={onSaveConfiguration}
        >
          Save
        </Button>
      </Stack>

      {renderEditDestinationDialog()}

      <ConfirmDeleteResourceDialog
        open={confirmDeleteOpen}
        onDelete={onDestinationDelete}
        onCancel={() => setConfirmDeleteOpen(false)}
        action="remove"
      >
        <Typography>
          Are you sure you want to remove this destination?
        </Typography>
      </ConfirmDeleteResourceDialog>

      <NewResourceDialog
        platform={
          isEmpty(formValues.secondaryPlatform)
            ? formValues.platform
            : formValues.secondaryPlatform
        }
        title="Choose a Destination"
        kind={Kind.Destination}
        open={addDestinationOpen}
        onClose={() => setAddDestinationOpen(false)}
        onSaveNew={onNewDestinationSave}
        onSaveExisting={onChooseExistingDestination}
        resourceTypes={data?.destinationTypes ?? []}
        resources={data?.destinations}
        existingResourceNames={
          data?.destinations.map((d) => d.metadata.name) ?? []
        }
      />
    </>
  );
};
