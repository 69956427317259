import { Chip } from "@mui/material";
import { useMemo } from "react";
import { classes } from "../../utils/styles";
import styles from "./invitations-table.module.scss";

export const StatusChip: React.FC<{
  status: "outstanding" | "redeemed" | "expired";
}> = ({ status }) => {
  const { colorClass, label } = useMemo(() => {
    switch (status) {
      case "expired":
        return { colorClass: styles.expired, label: "Expired" };
      case "redeemed":
        return { colorClass: styles.redeemed, label: "Accepted" };
      case "outstanding":
        return { colorClass: styles.outstanding, label: "Pending" };
    }
  }, [status]);

  return (
    <Chip
      className={classes([styles.chip, colorClass])}
      classes={{
        label: styles["chip-label"],
      }}
      label={label}
      variant={status === "expired" ? "outlined" : "filled"}
    />
  );
};
