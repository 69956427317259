import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  RolloutStage,
  useGetConfigurationLazyQuery,
} from "../../graphql/generated";
import { AGENTS_PAGE_QUERY_PARAM } from "../../pages/agents";
import { platformIsContainer } from "../../pages/agents/InstallPage/InstallWizard/utils";
import { ApplyConfigDialog } from "../../pages/configurations/configuration/ApplyConfigDialog";
import colors from "../../styles/colors";
import { EditingState } from "../EditingControlBar/EditingControlBar";
import {
  AlertIcon,
  ExternalLinkIcon,
  PlusCircleIcon,
  SendIcon,
} from "../Icons";
import { SafeLink } from "../SafeLink";
import styles from "../RolloutProgressBar/rollout-progress.module.scss";

interface RolloutProgressiveProgressProps {
  configurationName: string;
  totalCount: number;
  errors: number;
  completedCount: number;
  rolloutStatus: number;
  hideActions?: boolean;
  paused: boolean;
  loading?: boolean;
  onPause: () => void;
  onStart: () => void;
  onResume: () => void;
  editingState: EditingState;
  rolloutStages: RolloutStage[];
  rolloutStage: number;
}

/**
 * RolloutProgress is a component that displays the progress of a rollout
 * and allows the user to pause or start the rollout.
 *
 * @param configurationName the name of the configuration
 * @param totalCount the total number of agents in the rollout
 * @param errors the number of errored agents in the rollout
 * @param completedCount the number of agents that have completed the rollout
 * @param rolloutStatus used to determine the verbiage of the control button
 * @param hideActions whether to hide the pause/resume/start buttons
 * @param paused whether the rollout is paused, if true,
 * the control button will be "Start Rollout", otherwise it will be "Pause"
 * @param loading whether to display a loading state in the action button
 * @param onPause callback for when the "Pause" button is clicked
 * @param onResume callback for when the "Resume" button is clicked
 * @param editingState Used to sync with EditingControlBar render states
 * @param rolloutStages Stages to render in the progress bar
 * @param rolloutStage Index keeping track of which stage is current
 * @returns
 */
export const RolloutProgressiveProgressBar: React.FC<
  RolloutProgressiveProgressProps
> = ({
  configurationName,
  totalCount,
  errors,
  completedCount,
  rolloutStatus,
  hideActions,
  loading,
  onPause,
  onStart,
  onResume,
  editingState,
  rolloutStages,
  rolloutStage,
}) => {
  let totalCountByStage: number[] = [];
  rolloutStages?.forEach((s) => {
    let stageTotal =
      s.progress.completed +
      s.progress.errors +
      s.progress.pending +
      s.progress.waiting;
    totalCountByStage.push(stageTotal);
  });

  const [showApplyDialog, setShowApply] = useState(false);

  const label = useMemo(() => {
    const weightText = (text: string, weight: number): JSX.Element => (
      <span style={{ fontWeight: weight }}>{text}</span>
    );

    if (editingState === "discarding") {
      return <>{"Discarding"}</>;
    }

    switch (rolloutStatus) {
      case 1: // started
        return (
          <>
            {"Rolling out to "}
            {weightText(rolloutStages[rolloutStage]?.name ?? "next stage", 900)}
          </>
        );
      case 2: // paused
        return rolloutStages[rolloutStage - 1] ? (
          <>
            {"Rollout to "}
            {weightText(rolloutStages[rolloutStage - 1].name, 900)}
            {" Complete"}
          </>
        ) : (
          <>{"Rollout Paused"}</>
        );
      case 3: // errored
        return <>{"Rollout Paused for Errors"}</>;
      case 4: // stable
        return <>{"Rollout Complete"}</>;
      default:
        return <>{"Rollout Pending"}</>;
    }
  }, [rolloutStatus, rolloutStage, editingState, rolloutStages]);

  const agentsWithErrorsUrl = useMemo(() => {
    const query = `status:error configuration:${configurationName}`;
    const params = new URLSearchParams();
    params.set(AGENTS_PAGE_QUERY_PARAM, query);

    return `/agents?${params.toString()}`;
  }, [configurationName]);

  const { enqueueSnackbar } = useSnackbar();

  function toast(msg: string, variant: "error" | "success") {
    enqueueSnackbar(msg, { variant: variant, autoHideDuration: 3000 });
  }

  function onApplySuccess() {
    toast("Saved configuration!", "success");
    setShowApply(false);
  }

  const { name } = useParams();

  const [fetchConfig, { data }] = useGetConfigurationLazyQuery({
    fetchPolicy: "cache-and-network",
  });

  const actionButton = useMemo(() => {
    if (hideActions) {
      return null;
    }

    if (totalCount === 0) {
      return (
        !platformIsContainer(
          data?.configuration?.metadata?.labels?.platform,
        ) && (
          <Button
            color="primary"
            variant="contained"
            onClick={() => setShowApply(true)}
            startIcon={<PlusCircleIcon />}
            data-testid="config-edit-bar-add-agents-button"
          >
            Add Agents
          </Button>
        )
      );
    }

    switch (rolloutStatus) {
      case 0: // pending
        return (
          <Button
            color="primary"
            variant="contained"
            onClick={onStart}
            startIcon={<SendIcon />}
            data-testid="config-edit-bar-start-rollout-button"
          >
            Start Rollout
          </Button>
        );
      case 1: // started
        return (
          <LoadingButton
            color="primary"
            variant="contained"
            onClick={onPause}
            loading={loading}
            data-testid="config-edit-bar-pause-button"
          >
            Pause Rollout to {rolloutStages[rolloutStage]?.name}
          </LoadingButton>
        );
      case 2: // paused
      case 3: // errored
        return (
          <LoadingButton
            color="primary"
            variant="contained"
            onClick={onResume}
            loading={loading}
            data-testid="config-edit-bar-resume-button"
          >
            Continue Rollout to {rolloutStages[rolloutStage]?.name}
          </LoadingButton>
        );
    }
  }, [
    hideActions,
    loading,
    onPause,
    onResume,
    onStart,
    totalCount,
    rolloutStatus,
    rolloutStage,
    rolloutStages,
    data?.configuration?.metadata?.labels?.platform,
  ]);

  useEffect(() => {
    if (name) {
      fetchConfig({
        variables: {
          name: `${name}`,
        },
      });
    }
  }, [fetchConfig, name]);

  return (
    <Box flexGrow={1}>
      <Stack direction="row" width="100%" alignItems={"center"} spacing={2}>
        {actionButton && (
          <Box className={styles["control-box"]}>{actionButton}</Box>
        )}
        {data?.configuration && (
          <ApplyConfigDialog
            configuration={data.configuration}
            maxWidth="lg"
            fullWidth
            open={showApplyDialog}
            onError={() => toast("Failed to apply configuration.", "error")}
            onSuccess={onApplySuccess}
            onClose={() => setShowApply(false)}
            onCancel={() => setShowApply(false)}
          />
        )}
        <Box
          flexGrow={1}
          className={
            totalCount === 0 ? styles["rollout-progress-disabled"] : ""
          }
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            marginBottom={"1px"}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              <Typography fontSize={18} fontWeight={300}>
                {label}
              </Typography>
              {errors > 0 && editingState !== "discarding" && (
                <SafeLink
                  href={agentsWithErrorsUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="hover"
                  style={{ color: colors.muiErrorDark }}
                  data-testid="rollout-progress-agents-error-link"
                >
                  <Button
                    color="error"
                    endIcon={<ExternalLinkIcon width={15} />}
                    sx={{
                      padding: "0",
                      "& .MuiButton-endIcon": {
                        marginRight: "0",
                      },
                    }}
                  >
                    <Typography color="error" fontSize={14}>
                      {errors} error{errors > 1 ? "s" : ""}
                    </Typography>
                  </Button>
                </SafeLink>
              )}
            </Stack>

            <Typography fontSize={16} fontWeight={600}>
              {rolloutStages
                .reduce(
                  (accumulator, currentValue) =>
                    accumulator + currentValue.progress.completed,
                  0,
                )
                .toLocaleString()}
              /{totalCount.toLocaleString()}
            </Typography>
          </Stack>
          <Stack direction={"row"} spacing={0.5}>
            {rolloutStages.map((s, i) => {
              return (
                <ProgressiveRolloutSegment
                  stageName={s.name}
                  stageIndex={i}
                  stageCompletedCount={rolloutStages[i].progress.completed}
                  stageTotalCount={totalCountByStage[i]}
                  editingState={editingState}
                  key={`progressive-rollout-segment-${i}`}
                  isFinalStage={i === rolloutStages.length - 1}
                />
              );
            })}
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

interface ProgressiveRolloutSegmentProps {
  stageName: string;
  stageIndex: number;
  stageCompletedCount: number;
  stageTotalCount: number;
  editingState: EditingState;
  isFinalStage?: boolean;
}

/**
 * ProgressiveRolloutSegment renders one stage of a Progressive Rollout
 *
 * @param stageName name of this stage
 * @param stageIndex index of this stage
 * @param stageCompletedCount completed count for this stage
 * @param stageTotalCount total count for this stage
 * @param editingState
 * @param isFinalStage
 * @returns
 */
const ProgressiveRolloutSegment: React.FC<ProgressiveRolloutSegmentProps> = ({
  stageName,
  stageIndex,
  stageCompletedCount,
  stageTotalCount,
  editingState,
  isFinalStage,
}) => {
  if (stageTotalCount === 0 && isFinalStage) {
    return null;
  }

  let flex =
    stageTotalCount === 0
      ? 1
      : Math.log(stageTotalCount) < 1
        ? 1
        : Math.log(stageTotalCount);

  return (
    <Stack flex={flex} minWidth={"20px"}>
      <Tooltip
        title={
          <Stack>
            <Typography>
              <strong>Stage {stageIndex + 1}:</strong> {stageName}
            </Typography>
            {stageTotalCount === 0 ? (
              <>
                <Typography>
                  <AlertIcon
                    style={{
                      position: "relative",
                      top: "4px",
                      marginRight: "4px",
                    }}
                    width={"20px"}
                    height={"20px"}
                    color={colors.neonCarrot}
                  />
                  No agents in this stage
                </Typography>
              </>
            ) : (
              <Typography>
                <strong>Completed:</strong>{" "}
                {stageCompletedCount.toLocaleString()}/
                {stageTotalCount.toLocaleString()}
              </Typography>
            )}
          </Stack>
        }
        placement="top"
        arrow
        slotProps={{
          tooltip: {
            sx: {
              bgcolor: `${colors.white}`,
              border: `1px solid ${colors.middleLightGray}`,
            },
          },
          arrow: {
            sx: {
              color: `${colors.white}`,
            },
          },
          popper: {
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -5],
                },
              },
            ],
          },
        }}
      >
        <LinearProgress
          variant="determinate"
          value={
            stageTotalCount === 0 || editingState === "discarding"
              ? 0
              : (stageCompletedCount / stageTotalCount) * 100
          }
          sx={{
            backgroundColor:
              stageTotalCount === 0
                ? `${colors.lightGray}`
                : `${colors.middleLightGray}`,
          }}
        />
      </Tooltip>
    </Stack>
  );
};
