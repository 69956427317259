import { Box, Card, Stack } from "@mui/material";
import { Handle, Position } from "reactflow";
import { CountChip } from "../CountChip";
import { ProcessorIcon } from "../Icons";
import styles from "./cards.module.scss";

interface ProcessorCardProps {
  editProcessors: (
    resourceType: "source" | "destination",
    resourceIndex: number,
  ) => void;
  processorCount: number;
  resourceType: "source" | "destination";
  resourceIndex: number;
  recommendation?: boolean;
}

export const ProcessorCard: React.FC<ProcessorCardProps> = ({
  processorCount,
  resourceType,
  resourceIndex,
  recommendation,
  editProcessors,
}) => {
  return (
    <Box className={recommendation ? styles["processor-recommendation"] : ""}>
      <Card
        className={styles["processor-card"]}
        onClick={() => editProcessors(resourceType, resourceIndex)}
      >
        <Handle type="source" position={Position.Right} />
        <Handle type="target" position={Position.Left} />
        <Stack
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <ProcessorIcon />
        </Stack>
      </Card>
      {processorCount > 0 && (
        <CountChip className={styles["count-chip"]} count={processorCount} />
      )}
    </Box>
  );
};
