import { gql, useApolloClient } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { TabContext, TabPanel } from "@mui/lab";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  ClickAwayListener,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useNavigate } from "react-router";
import { Navigate } from "react-router-dom";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import { withEENavBar } from "../../../components/EENavBar";
import { BusinessIcon } from "../../../components/Icons";
import { LinkButton } from "../../../components/LinkButton";
import { withRBAC } from "../../../contexts/RBAC";
import { withRequireLogin } from "../../../contexts/RequireLogin";
import { useOrganizationPageQuery } from "../../../graphql/generated";
import { AuthType } from "../../../types/auth";
import { getAuthType } from "../../../utils/get-auth-type";
import { renameOrganization } from "../../../utils/rest/rename-organization";
import { validateOrganizationName } from "../../../utils/rest/verify-organization";
import { DeleteOrganizationDialog } from "./DeleteOrganizationDialog";
import { OrganizationAccountTable } from "./OrganizationAccountTable";
import { OrganizationUserTable } from "./OrganizationUserTable";
import styles from "./view-organization-page.module.scss";

enum OverviewPageTab {
  Accounts = "accounts",
  Users = "users",
}

const DEFAULT_SEARCH_PARAM = withDefault(StringParam, OverviewPageTab.Accounts);

gql`
  query OrganizationPage {
    user {
      metadata {
        name
        id
        version
      }
    }

    organizationInfo {
      organization {
        metadata {
          name
          id
          version
        }
        licenseType
      }

      accounts {
        metadata {
          name
          id
          version
          displayName
          dateModified
        }
        userCount
      }

      users {
        metadata {
          name
          id
          version
          displayName
        }
        spec {
          requestedMFA
        }
        isOrgAdmin
      }
    }
  }
`;

export const ViewOrganizationPageComponent: React.FC = () => {
  const [tab, setTab] = useQueryParam("tab", DEFAULT_SEARCH_PARAM);
  const [editingName, setEditingName] = useState(false);
  const [orgNameValue, setOrgNameValue] = useState<string>("");
  const [orgNameError, setOrgNameError] = useState<string | null>(null);
  const [deleteOrgDialogOpen, setDeleteOrgDialogOpen] = useState(false);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { logout } = useAuth0();
  const apolloClient = useApolloClient();

  async function logoutUser() {
    try {
      if (getAuthType() === AuthType.Auth0) {
        await logout({ openUrl: false });
      }
      localStorage.removeItem("user");
      await fetch("/logout", {
        method: "PUT",
      });
      navigate("/login");
    } catch (err) {
      console.error(err);
    }
  }

  function onEditClick() {
    setEditingName(true);
  }

  async function handleNameFieldKeyDown(
    e: React.KeyboardEvent<HTMLInputElement>,
  ) {
    if (e.key === "Enter") {
      if (orgNameError) {
        return;
      }
      await handleRenameOrg();
      return;
    }

    if (e.key === "Escape") {
      setEditingName(false);
      setOrgNameValue(data!.organizationInfo.organization!.metadata.name);
      setOrgNameError(null);
    }
  }

  async function handleNameFieldChange(e: React.ChangeEvent<HTMLInputElement>) {
    setOrgNameValue(e.target.value);
    setOrgNameError(null);

    if (e.target.value === data?.organizationInfo.organization?.metadata.name) {
      return;
    }

    setOrgNameError(await validateOrganizationName(e.target.value));
  }

  async function handleRenameOrg() {
    try {
      const resp = await renameOrganization(
        orgNameValue,
        data!.organizationInfo.organization!.metadata.id,
      );

      if (!resp.ok) {
        throw new Error(
          `Failed to rename organization, got status: ${resp.status}`,
        );
      }
    } catch (err) {
      enqueueSnackbar(err, { variant: "error" });
      console.error(err);
    }

    setEditingName(false);
    setOrgNameError(null);
    await refetch();
    setEditingName(false);
  }

  const { data, refetch } = useOrganizationPageQuery({
    onError(error) {
      if (error.message.includes("does not have access to organization")) {
        navigate("/agents");
        enqueueSnackbar("You do not have access to this view this page.", {
          variant: "info",
        });
      }
    },
    onCompleted(data) {
      setOrgNameValue(data.organizationInfo.organization?.metadata.name || "");
    },
  });

  if (!data) return null;

  if (!data.organizationInfo.organization) {
    enqueueSnackbar("Organization not found.", { variant: "error" });
    return <Navigate to="/overview" />;
  }

  function handleNameFieldClickAway() {
    handleRenameOrg();
  }

  async function handleSuccessfulOrganizationDeletion() {
    setDeleteOrgDialogOpen(false);
    await logoutUser();
    await apolloClient.clearStore();
  }

  return (
    <>
      <div className={styles.container}>
        <Box paddingLeft={3}>
          <Typography className={styles.header} marginBottom={2}>
            Organization Info
          </Typography>
          <Card>
            <CardContent>
              <Stack direction="row" spacing={2}>
                <Avatar classes={{ root: styles.avatar }}>
                  <BusinessIcon />
                </Avatar>

                <Stack spacing={1}>
                  <Stack direction="row">
                    <Typography fontWeight={600} className={styles.key}>
                      Name:
                    </Typography>

                    {editingName ? (
                      <ClickAwayListener onClickAway={handleNameFieldClickAway}>
                        <TextField
                          variant="standard"
                          onKeyDown={handleNameFieldKeyDown}
                          className={styles.nameField}
                          value={orgNameValue}
                          onChange={handleNameFieldChange}
                          error={!!orgNameError}
                          helperText={orgNameError}
                          autoFocus
                        />
                      </ClickAwayListener>
                    ) : (
                      <>
                        <Typography>
                          {data?.organizationInfo.organization?.metadata.name}
                        </Typography>
                        <Box marginLeft={1}>
                          <LinkButton onClick={onEditClick}>edit</LinkButton>
                        </Box>
                      </>
                    )}
                  </Stack>
                  <Stack direction="row">
                    <Typography fontWeight={600} className={styles.key}>
                      License:
                    </Typography>
                    <Typography>
                      {data.organizationInfo.organization?.licenseType ??
                        "Unknown"}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </CardContent>
            <Stack alignItems="flex-end" paddingRight={2} paddingBottom={1}>
              <LinkButton
                buttonClassName={styles.deleteButton}
                onClick={() => setDeleteOrgDialogOpen(true)}
              >
                Delete this organization
              </LinkButton>
            </Stack>
          </Card>
        </Box>
        <TabContext value={tab}>
          <Tabs
            value={tab}
            onChange={(_, v) => setTab(v)}
            classes={{ root: styles.tabs }}
          >
            <Tab
              value={OverviewPageTab.Accounts}
              label="Projects"
              data-testid="accounts-tab"
            />
            <Tab
              value={OverviewPageTab.Users}
              label="Users"
              data-testid="users-tab"
            />
          </Tabs>

          <TabPanel value={OverviewPageTab.Accounts} sx={{ paddingRight: 0 }}>
            <OrganizationAccountTable
              accounts={data.organizationInfo.accounts}
            />
          </TabPanel>

          <TabPanel value={OverviewPageTab.Users} sx={{ paddingRight: 0 }}>
            <OrganizationUserTable
              refetch={refetch}
              users={data.organizationInfo.users}
              currentUserId={data.user.metadata.id}
            />
          </TabPanel>
        </TabContext>
      </div>

      <DeleteOrganizationDialog
        open={deleteOrgDialogOpen}
        onClose={() => setDeleteOrgDialogOpen(false)}
        onDeleteSuccess={handleSuccessfulOrganizationDeletion}
        organizationId={data.organizationInfo.organization.metadata.id}
      />
    </>
  );
};

export const ViewOrganizationPage = withRequireLogin(
  withRBAC(withEENavBar(ViewOrganizationPageComponent)),
);
