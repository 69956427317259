import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
} from "@mui/material";
import { lowerCase } from "lodash";
import { useMemo } from "react";
import { Kind } from "../../../graphql/generated";
import colors from "../../../styles/colors";
import { trimVersion } from "../../../utils/version-helpers";
import { ExternalLinkIcon } from "../../Icons";
import { SafeLink } from "../../SafeLink";

interface EditResourceInUseWarningDialogProps extends DialogProps {
  resourceName: string;
  resourceKind: Kind;
  inProcessorDialog?: boolean;
  configurationCount: number;
  onCancel: () => void;
  onConfirm: () => void;
}

/**
 * EditResourceInUseWarningDialog
 *
 * @param param0
 * @returns
 */
export const EditResourceInUseWarningDialog: React.FC<
  EditResourceInUseWarningDialogProps
> = ({
  resourceName,
  resourceKind,
  inProcessorDialog,
  configurationCount,
  open,
  onCancel,
  onConfirm,
}) => {
  const configsWithResourceURL = useMemo(() => {
    const query = `${lowerCase(resourceKind)}:${resourceName}`;
    const params = new URLSearchParams();
    params.set("query", query);

    return `/configurations?${params.toString()}`;
  }, [resourceName, resourceKind]);

  return (
    <Dialog open={open} data-testid="edit-resource-in-use-warning-dialog">
      <DialogTitle>
        {resourceKind}{" "}
        <Box sx={{ fontWeight: "bold", display: "inline" }}>
          {trimVersion(resourceName)}
        </Box>{" "}
        is being used by {configurationCount} configurations
        <SafeLink
          href={configsWithResourceURL}
          target="_blank"
          rel="noopener noreferrer"
          underline="hover"
          style={{ color: colors.semitoneBlue }}
          data-testid={`warning-dialog-configuration-count-link-${resourceName}`}
        >
          <Button
            endIcon={
              <ExternalLinkIcon color={colors.semitoneBlue} width={20} />
            }
            sx={{
              padding: "0",
              minWidth: "0",
              "& .MuiButton-endIcon": {
                margin: "0px 0px 4px 4px",
              },
            }}
          ></Button>
        </SafeLink>
      </DialogTitle>
      <DialogContent>
        {inProcessorDialog ? (
          <Typography>
            After hitting 'Save' on the next page, this shared resource will be
            updated in every configuration using it. Each configuration must be
            rolled out for its agent(s) to receive the update.
          </Typography>
        ) : (
          <Typography>
            This action will update the shared resource in every configuration
            using it. Each configuration must be rolled out for its agent(s) to
            receive the update.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={onCancel}
          data-testid="edit-resource-in-use-warning-dialog-cancel"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onConfirm}
          data-testid="edit-resource-in-use-warning-dialog-confirm"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
