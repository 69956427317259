import { Edge, MarkerType, Node, Position } from "reactflow";
import { PipelineTypeFlags } from "../../types/configuration";
import { EdgeData, NodeData } from "../../utils/graph/types";
import {
  getConnectedNodesAndEdges,
  pipelineOffsets,
} from "../../utils/graph/utils";
import { isSourceID } from "../PipelineGraph/Nodes/ProcessorNode";
import { isNodeDisabled } from "../PipelineGraph/Nodes/nodeUtils";
import { AttributeName, V2Config } from "../PipelineGraphV2/types";
import { BPGraph } from "./graph";
import { LayoutGrid } from "./layout-grid";

export function layoutV2Graph(
  configuration: V2Config,
  readOnly: boolean,
  selectedTelemetry: string,
  onAddSource: () => void,
  onAddDestination: () => void,
): {
  nodes: Node<NodeData>[];
  edges: Edge<EdgeData>[];
} {
  const graph = configuration?.graph;
  if (!graph) {
    return { nodes: [], edges: [] };
  }

  // add node positions
  const bpGraph = new BPGraph(graph);
  const grid = new LayoutGrid(bpGraph);
  for (const n of bpGraph.getAllNodes()) {
    grid.setNodePosition(n);
  }

  const nodes: Node<NodeData>[] = [];
  const edges: Edge<EdgeData>[] = [];
  const offsets = pipelineOffsets(graph.edges);

  // if there's only one source or one destination we need to layout add source and add destination cards
  // we also need to add edges between the source/destination and the add source/add destination cards
  const addSourceCard = graph.sources?.length === 0;
  const addDestinationCard = graph.targets?.length === 0;

  // layout sources
  if (addSourceCard) {
    nodes.push({
      id: "add-source",
      data: {
        buttonText: "Source",
        id: "add-source",
        handlePosition: Position.Right,
        handleType: "source",
        onClick: onAddSource,
      },
      position: grid.getPosition("add-source", "sourceNode"),
      type: "uiControlNode",
    });

    nodes.push({
      id: "add-source-proc",
      data: {
        id: "add-source-proc",
      },
      position: grid.getPosition("add-source-proc", "dummyProcessorNode"),
      type: "dummyProcessorNode",
    });

    var edge: Edge<any> & { key: string } = {
      key: "add-source_add-source-proc",
      id: "add-source_add-source-proc",
      source: "add-source",
      target: "add-source-proc",
      markerEnd: {
        type: MarkerType.ArrowClosed,
      },
      data: {
        connectedNodesAndEdges: [],
        attributes: {
          [AttributeName.ActiveTypeFlags]: PipelineTypeFlags.ALL,
          [AttributeName.SupportedTypeFlags]: PipelineTypeFlags.ALL,
        },
      },
      type: "configurationEdge",
    };
    edges.push(edge);
    // connect add-source-proc to all the destination processors
    for (let i = 0; i < (graph.intermediates ?? []).length; i++) {
      const n = graph.intermediates[i];
      if (!isSourceID(n.id)) {
        edge = {
          key: `${n.id}_add-source-proc`,
          id: `${n.id}_add-source-proc`,
          target: `${n.id}`,
          source: "add-source-proc",
          markerEnd: {
            type: MarkerType.ArrowClosed,
          },
          data: {
            connectedNodesAndEdges: [],
            attributes: {
              [AttributeName.ActiveTypeFlags]: PipelineTypeFlags.ALL,
              [AttributeName.SupportedTypeFlags]: PipelineTypeFlags.ALL,
            },
          },
          type: "configurationEdge",
        };
        edges.push(edge);
      }
    }
  } else {
    for (let i = 0; i < (graph.sources ?? []).length; i++) {
      const n = graph.sources[i];

      nodes.push({
        id: n.id,
        data: {
          id: n.id,
          label: n.label,
          attributes: n.attributes,
          connectedNodesAndEdges: [n.id],
        },
        position: n.attributes.position,
        sourcePosition: Position.Right,
        type: n.type,
      });
    }
  }

  // layout processors
  for (let i = 0; i < (graph.intermediates ?? []).length; i++) {
    const n = graph.intermediates[i];
    if (n.type === "connectorNode") {
      nodes.push({
        id: n.id,
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        position: n.attributes.position,
        data: {
          id: n.id,
          label: n.label,
          attributes: n.attributes,
          connectedNodesAndEdges: [n.id],
        },
      });
    } else {
      nodes.push({
        id: `${n.id}`,
        data: {
          id: n.id,
          attributes: n.attributes,
        },
        position: n.attributes.position,
        type: n.type,
      });
    }
  }

  // Lay out destinations
  if (addDestinationCard) {
    nodes.push({
      id: "add-destination",
      data: {
        id: "add-destination",
        buttonText: "Destination",
        handlePosition: Position.Left,
        handleType: "target",
        isButton: false,
        onClick: onAddDestination,
      },
      position: grid.getPosition("add-destination", "destinationNode"),
      type: "uiControlNode",
    });
    nodes.push({
      id: "add-destination-proc",
      data: {
        id: "add-destination-proc",
      },
      position: grid.getPosition("add-destination-proc", "dummyProcessorNode"),
      type: "dummyProcessorNode",
    });
    edge = {
      key: "add-destination-proc_add-destination",
      id: "add-destination-proc_add-destination",
      source: "add-destination-proc",
      target: "add-destination",
      markerEnd: {
        type: MarkerType.ArrowClosed,
      },
      data: {
        connectedNodesAndEdges: [],
        attributes: {
          [AttributeName.ActiveTypeFlags]: PipelineTypeFlags.ALL,
          [AttributeName.SupportedTypeFlags]: PipelineTypeFlags.ALL,
        },
      },
      type: "configurationEdge",
    };
    edges.push(edge);
    // connect dummy processor to all the source processors
    for (let i = 0; i < (graph.intermediates ?? []).length; i++) {
      const n = graph.intermediates[i];
      if (isSourceID(n.id)) {
        edge = {
          key: `${n.id}_add-destination-proc`,
          id: `${n.id}_add-destination-proc`,
          source: `${n.id}`,
          target: "add-destination-proc",
          markerEnd: {
            type: MarkerType.ArrowClosed,
          },
          data: {
            connectedNodesAndEdges: [],
            attributes: {
              [AttributeName.ActiveTypeFlags]: PipelineTypeFlags.ALL,
              [AttributeName.SupportedTypeFlags]: PipelineTypeFlags.ALL,
            },
          },
          type: "configurationEdge",
        };
        edges.push(edge);
      }
    }
  } else {
    for (let i = 0; i < (graph.targets ?? []).length; i++) {
      const n = graph.targets[i];
      nodes.push({
        id: n.id,
        data: {
          id: n.id,
          label: n.label,
          attributes: n.attributes,
          connectedNodesAndEdges: [n.id],
        },
        position: n.attributes.position,
        targetPosition: Position.Left,
        type: n.type,
      });
    }
  }

  // find max pipeline position
  let max = 0;

  // This seems like it should be Object.keys(offsets), but alas...
  for (const key in offsets) {
    if (offsets[key] > max) {
      max = offsets[key];
    }
  }

  // Add the add source and add destination buttons
  if (max < 3) {
    max = 3;
  }

  // Add an Add Source button if we aren't using the Add Source Card and
  // the graph is not read only.
  if (!addSourceCard && !readOnly) {
    nodes.push({
      id: "add-source",
      data: {
        id: "add-source",
        buttonText: "Source",
        handlePosition: Position.Right,
        handleType: "source",
        isButton: true,
        onClick: onAddSource,
        attributes: {
          kind: "source",
          sourceIndex: 0,
          resourceId: "add-source",
          [AttributeName.ActiveTypeFlags]: PipelineTypeFlags.ALL,
          [AttributeName.SupportedTypeFlags]: PipelineTypeFlags.ALL,
        },
      },
      position: grid.getPosition("add-source", "uiControlNode"),
      type: "uiControlNode",
    });
  }

  // Add an Add Destination button if we aren't using the Add Destination Card and
  // the graph is not read only.
  if (!addDestinationCard && !readOnly) {
    nodes.push({
      id: "add-destination",
      data: {
        id: "add-destination",
        buttonText: "Destination",
        handlePosition: Position.Left,
        handleType: "target",
        isButton: true,
        onClick: onAddDestination,
      },
      position: grid.getPosition("add-destination", "uiControlNode"),
      type: "uiControlNode",
    });
  }
  if (addDestinationCard && addSourceCard) {
    edge = {
      key: "add-source-proc_add-destination-proc",
      id: "add-source-proc_add-destination-proc",
      source: "add-source-proc",
      target: "add-destination-proc",
      markerEnd: {
        type: MarkerType.ArrowClosed,
      },
      data: {
        connectedNodesAndEdges: [],
        attributes: {
          [AttributeName.ActiveTypeFlags]: PipelineTypeFlags.ALL,
          [AttributeName.SupportedTypeFlags]: PipelineTypeFlags.ALL,
        },
      },
      type: "configurationEdge",
    };
    edges.push(edge);
  }

  for (const e of graph.edges || []) {
    // skip edges that are not of the telemetry type
    const edgePipelineType = e.attributes?.["pipelineType"];
    if (edgePipelineType != null && edgePipelineType !== selectedTelemetry) {
      continue;
    }
    const edge: Edge<any> & { key: string } = {
      key: e.id,
      id: e.id,
      source: e.source,
      target: e.target,
      markerEnd: {
        type: MarkerType.ArrowClosed,
      },
      data: {
        attributes: e.attributes ?? {},
        connectedNodesAndEdges: [e.id],
      },
      type: "configurationEdge",
      zIndex: 1,
    };

    // Set the edge's ActiveTypeFlag attribute from the intersection of the source
    // and target node ActiveTypeFlag attribute

    // Find the source and target nodes
    if (graph.attributes?.["type"] !== "routes") {
      const sourceNode = nodes.find((n) => n.id === e.source);
      const targetNode = nodes.find((n) => n.id === e.target);

      if (sourceNode && targetNode) {
        // Get the ActiveTypeFlag attribute from the source and target nodes
        const sourceNodeActiveTypeFlag =
          sourceNode.data.attributes?.[AttributeName.ActiveTypeFlags];
        const targetNodeActiveTypeFlag =
          targetNode.data.attributes?.[AttributeName.ActiveTypeFlags];

        // If the source and target nodes have ActiveTypeFlag attributes
        if (sourceNodeActiveTypeFlag && targetNodeActiveTypeFlag) {
          // Set the ActiveTypeFlag attribute as the intersection of the two nodes
          edge.data.attributes = {
            ...(targetNode.data.attributes ?? {}),
            [AttributeName.ActiveTypeFlags]:
              sourceNodeActiveTypeFlag & targetNodeActiveTypeFlag,
          };
        }
      }
    }

    if (isNodeDisabled(selectedTelemetry || "", edge.data.attributes)) {
      edge.zIndex = 0;
    }
    edges.push(edge);
  }

  // First create a map from node id to all child nodes and edges
  const nodeMap: NodeMap = {};
  for (const edge of edges) {
    if (!nodeMap[edge.source]) {
      nodeMap[edge.source] = [];
    }
    nodeMap[edge.source].push(edge.target, edge.id);
  }
  nodes.forEach((node) => {
    node.data.connectedNodesAndEdges = getConnectedNodesAndEdges(
      node.id,
      nodeMap,
    );
  });

  // Next, create a reverse map
  const reverseNodeMap: NodeMap = {};
  for (const edge of edges) {
    if (!reverseNodeMap[edge.target]) {
      reverseNodeMap[edge.target] = [];
    }
    reverseNodeMap[edge.target].push(edge.source, edge.id);
  }
  // Then recursively find all connected nodes and edges
  nodes.forEach((node) => {
    if (!node.data.connectedNodesAndEdges) {
      node.data.connectedNodesAndEdges = [];
    }
    node.data.connectedNodesAndEdges.push(
      ...getConnectedNodesAndEdges(node.id, reverseNodeMap),
    );
  });

  return { nodes, edges };
}
type NodeMap = { [id: string]: string[] };
