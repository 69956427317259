import { gql } from "@apollo/client";
import { Stack } from "@mui/material";
import { useProcessorTypeDisplayInfoQuery } from "../../../graphql/generated";
import { ProcessorIcon } from "../../Icons";
import styles from "./cells.module.scss";

gql`
  query ProcessorTypeDisplayInfo($name: String!) {
    processorType(name: $name) {
      metadata {
        id
        displayName
        name
        version
        icon
      }
    }
  }
`;

interface ResourceTypeCellProps {
  type: string;
}

/**
 * Data grid cell that shows display name of a processor type
 */
export const ProcessorTypeCell: React.FC<ResourceTypeCellProps> = ({
  type,
}) => {
  const { data } = useProcessorTypeDisplayInfoQuery({
    variables: { name: type },
  });
  const text = data?.processorType?.metadata.displayName ?? type;
  const icon = data?.processorType?.metadata.icon;
  return (
    <Stack direction="row" spacing={1} className={styles.cell}>
      {icon ? (
        <img className={styles.icon} src={icon} alt={`icon for ${type}`} />
      ) : (
        <ProcessorIcon className={styles.icon} />
      )}
      <span>{text}</span>
    </Stack>
  );
};
