import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { debounce, trim } from "lodash";
import { useCallback, useMemo } from "react";
import { validateOrganizationName } from "../../utils/rest/verify-organization";
import { useWizard } from "../Wizard/WizardContext";
import { EULAField } from "./EULAField";
import { OnboardingWizardValues } from "./types";
import styles from "./onboarding-steps.module.scss";

interface NewOrganizationStepProps {
  promptEula: boolean;
}

export const NewOrganizationStep: React.FC<NewOrganizationStepProps> = ({
  promptEula,
}) => {
  const {
    goToStep,
    setValues,
    setFormErrors,
    setTouched,
    formValues,
    formErrors,
    formTouched,
    step,
  } = useWizard<OnboardingWizardValues>();

  function clearError() {
    setFormErrors((prev) => ({
      ...prev,
      organizationName: "",
      accountName: "",
    }));
  }

  async function handleNextClick() {
    const sanitized = trim(formValues.organizationName);
    setValues({ organizationName: sanitized });
    goToStep(step + 1);
  }

  const validateOrgName = useCallback(
    async (orgName: string) => {
      const error = await validateOrganizationName(orgName);
      setFormErrors((prev) => ({ ...prev, organizationName: error }));
    },
    [setFormErrors],
  );

  const debouncedValidate = useMemo(
    () => debounce(validateOrgName, 200),
    [validateOrgName],
  );

  async function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    clearError();
    setValues({ organizationName: e.target.value });
    debouncedValidate(e.target.value);
  }

  const helperText = formTouched.organizationName
    ? formErrors.organizationName
    : null;

  const nextDisabled =
    formValues.organizationName === "" || !!formErrors.organizationName;

  return (
    <>
      <div className={styles.stepContainer}>
        <Typography variant="h6" fontWeight={600}>
          Create your organization in BindPlane OP
        </Typography>

        <Typography marginBottom={4}>
          Managing your telemetry pipeline is easy with BindPlane OP. Lets start
          by creating your Organization.
        </Typography>

        <Stack alignItems="center" justifyContent="center">
          <Stack width="100%">
            <Typography variant="h6" fontWeight={600}>
              Name your Organization
            </Typography>
            <Typography marginBottom={2}>
              Usually this is your company name. You can change this any time.
            </Typography>

            <Box width={400}>
              <TextField
                fullWidth
                size="small"
                label="Organization Name"
                value={formValues.organizationName}
                onChange={onChange}
                onKeyUp={() => setTouched({ organizationName: true })}
                error={
                  formTouched.organizationName && !!formErrors.organizationName
                }
                helperText={helperText}
              />
              {promptEula && (
                <EULAField
                  onChange={(e) => setValues({ acceptEula: e.target.checked })}
                  checked={formValues.acceptEula}
                />
              )}
            </Box>
          </Stack>
        </Stack>
      </div>

      <Stack direction="row" width="100%" justifyContent="flex-end" spacing={2}>
        {step > 0 && (
          <Button variant="outlined" onClick={() => goToStep(step - 1)}>
            Back
          </Button>
        )}
        <Button
          variant="contained"
          onClick={handleNextClick}
          disabled={nextDisabled}
        >
          Next
        </Button>
      </Stack>
    </>
  );
};
