import {
  Breadcrumbs,
  Divider,
  Paper,
  PaperProps,
  Typography,
} from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import colors from "../../styles/colors";
import { classes } from "../../utils/styles";
import styles from "./card-container.module.scss";

export const CardContainer: React.FC<PaperProps> = ({ children, ...rest }) => {
  return (
    <Paper
      className={classes([styles.root, styles.bordered])}
      elevation={1}
      {...rest}
    >
      {children}
    </Paper>
  );
};

interface TwoStepBreadcrumbProps {
  spacing?: "long" | "short"; // affects maxWidth, default is long
  navLabel: string;
  navLink: string;
  current: string;
}

export const TwoStepBreadcrumb: React.FC<TwoStepBreadcrumbProps> = ({
  spacing,
  navLabel,
  navLink,
  current,
}) => {
  return (
    <Paper
      classes={{ root: styles.root }}
      elevation={1}
      sx={{ borderWidth: 0, backgroundColor: colors.backgroundWhite }}
      style={{
        marginBottom: "0.5rem",
        marginTop: "-1.25rem",
        maxWidth: `${spacing === "short" ? "1016px" : "1600px"}`,
      }}
    >
      <Breadcrumbs>
        <NavLink to={navLink} style={{ textDecoration: "none" }}>
          <Typography color={colors.pixelPointBlue}>{navLabel}</Typography>
        </NavLink>
        <Typography>{current}</Typography>
      </Breadcrumbs>
    </Paper>
  );
};

/**
 * CardContainerDivider is a divider that can be used to separate content
 */
export const CardContainerDivider: React.FC = () => {
  return <Divider classes={{ root: styles.divider }} />;
};
