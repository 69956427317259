import { Kind, Metadata } from "../graphql/generated";

export type Resource = {
  apiVersion: string;
  kind: Kind;
  metadata: Metadata;
  spec: any;
};

/** ResourceStatus contains a resource and its UpdateStatus after a change */
export interface ResourceStatus {
  resource: Resource;
  status: UpdateStatus;
  reason?: string;
}

export enum APIVersion {
  V1 = "bindplane.observiq.com/v1",
  V2Alpha = "bindplane.observiq.com/v2alpha",
}

export enum UpdateStatus {
  CREATED = "created",
  CONFIGURED = "configured",
  UNCHANGED = "unchanged",
  DELETED = "deleted",
  INVALID = "invalid",
}

export enum Stability {
  DEVELOPMENT = "development",
  ALPHA = "alpha",
  BETA = "beta",
  STABLE = "stable",
  DEPRECATED = "deprecated",
  UNKNOWN = "",
}
