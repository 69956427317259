import { Stack, Button, Typography } from "@mui/material";
import { memo } from "react";
import { Handle, Position } from "reactflow";
import { AddResourceCard } from "../../Cards/AddResourceCard";
import { PlusCircleIcon } from "../../Icons";
import styles from "../pipeline-graph.module.scss";

function UIControlNode({
  data,
}: {
  data: {
    onClick: (value: boolean) => void;
    buttonText: string;
    handlePosition: Position;
    handleType: "source" | "target";
    isButton: boolean;
  };
}) {
  return (
    <>
      {data.isButton ? (
        <Stack alignItems={"center"}>
          <Button
            onClick={() => data.onClick(true)}
            className={styles["add-resource-button"]}
          >
            <PlusCircleIcon />
          </Button>
          <Typography className={styles["add-resource-text"]}>
            {data.buttonText}
          </Typography>
        </Stack>
      ) : (
        <>
          <AddResourceCard
            onClick={() => data.onClick(true)}
            buttonText={data.buttonText}
            dataTestId={`add-${data.handleType}-button`}
          />
          <Handle type={data.handleType} position={data.handlePosition} />
        </>
      )}
    </>
  );
}

export default memo(UIControlNode);
