import { Link, LinkProps } from "@mui/material";
import { forwardRef } from "react";

interface SafeLinkProps extends LinkProps {}

// SafeLink is a react component that will return an empty element if
// the href of the link may execute javascript.
export const SafeLink: React.FC<SafeLinkProps> = forwardRef((props, ref) => {
  // We are not using a script url, but rather protecting against it
  // eslint-disable-next-line no-script-url
  if (props.href?.startsWith("javascript:")) {
    return <></>;
  }

  return <Link ref={ref} {...props} />;
});
