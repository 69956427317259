import { Step, Wizard } from "../../../../components/Wizard";
import { ChooseYourPlatform } from "./ChooseYourPlatform";
import { InstallWizardProvider } from "./InstallWizardContext";
import { InstallYourAgents } from "./InstallYourAgents";
import { Platform } from "./types";

export interface AgentInstallFormValues {
  // Choose your platform form values
  platform: string;
  secondaryPlatform: string | null;
  secondaryPlatformRequired: boolean;
  selectedConfig: string | null;
}

const installSteps: Step[] = [
  {
    label: "Choose your platform",
    description:
      "Specify some basic settings from the platform you're shipping telemetry from.",
  },
  {
    label: "Install your agents",
    description: "Get agents added to your environment.",
  },
];

export const InstallWizard: React.FC = () => {
  return (
    <InstallWizardProvider>
      <Wizard<AgentInstallFormValues>
        steps={installSteps}
        stepComponents={[<ChooseYourPlatform />, <InstallYourAgents />]}
        initialFormValues={{
          platform: Platform.Linux,
          secondaryPlatform: null,
          secondaryPlatformRequired: false,
          selectedConfig: null,
        }}
      />
    </InstallWizardProvider>
  );
};
