import { edition } from "../components/BindplaneVersion/utils";
import {
  BindPlaneLogo,
  BindPlaneLogoSquare,
  HoneycombLogo,
  HoneycombLogoSquare,
} from "../components/Logos";

/**
 * useLogo returns an SVG of the primary brand logo, its the BindPlane OP logo
 * except for Honeycomb licenses.
 * @param props svg props for the logo
 * @param variant square or regular logo, defaults to regular
 * @returns
 */
export function useLogo(
  props: React.ComponentProps<"svg">,
  variant: "square" | "regular" | undefined,
): JSX.Element {
  if (edition() === "Honeycomb") {
    if (variant === "square") {
      return <HoneycombLogoSquare {...props} />;
    } else {
      return <HoneycombLogo {...props} />;
    }
  }

  if (variant === "square") {
    return <BindPlaneLogoSquare {...props} />;
  }

  return <BindPlaneLogo {...props} />;
}
