import {
  Stack,
  Accordion,
  AccordionSummary,
  IconButton,
  FormLabel,
  AccordionDetails,
  Box,
  Typography,
  Button,
} from "@mui/material";
import { clone, isEqual } from "lodash";
import { useState } from "react";
import {
  EnumParamInput,
  MapParamInput,
  OTTLFieldInput,
  StringParamInput,
} from ".";
import { ParameterType } from "../../../graphql/generated";
import colors from "../../../styles/colors";
import { ChevronDown, ChevronUp, EditIcon, TrashIcon } from "../../Icons";
import { useValidationContext } from "../ValidationContext";
import {
  ConditionMatch,
  ConditionInputValue,
  ConditionInput,
} from "./ConditionInput";
import { ParamInputProps } from "./ParameterInput";
import { parameterErrors } from "./utils";

export type ExtractMetricsValue = ExtractMetric[];

interface ExtractMetric {
  condition: ConditionInputValue | string;
  metricName: string;
  match: "Body" | "Attributes" | "Resource";
  metricField: string;
  metricType: "gauge_double" | "gauge_int" | "counter_double" | "counter_int";
  metricUnit: string;
  metricAttributes: Record<string, string>;
}

const EMPTY_ITEM: ExtractMetric = {
  condition: {
    ottl: "",
    ui: {
      operator: "",
      statements: [
        {
          match: ConditionMatch.ATTRIBUTES,
          key: "",
          operator: "Equals",
          value: "",
        },
      ],
    },
  },
  metricName: "New Metric",
  match: "Body",
  metricField: "",
  metricType: "gauge_double",
  metricUnit: "{logs}",
  metricAttributes: {},
};

const EMPTY_VALUE: ExtractMetricsValue = [];

export const ExtractMetricsInput: React.FC<
  ParamInputProps<ExtractMetricsValue>
> = ({ definition, value: paramValue, readOnly, onValueChange }) => {
  const initValue =
    paramValue != null && paramValue.length > 0 ? paramValue : EMPTY_VALUE;
  const [controlValue, setControlValue] =
    useState<ExtractMetricsValue>(initValue);
  const [expanded, setExpanded] = useState<string | false>();

  const { errors, touched, touch, setError } = useValidationContext();

  function handleValueChange(newValue: ExtractMetricsValue) {
    if (isEqual(newValue, EMPTY_VALUE)) {
      onValueChange && onValueChange([]);
    } else {
      onValueChange && onValueChange(newValue);
    }
  }

  function handleParameterValueChange(index: number, key: string, value: any) {
    const item: ExtractMetric = clone(controlValue[index]);
    if (key in item) {
      (item as any)[key] = value;
    }

    const newControlValue = [...controlValue];
    newControlValue[index] = item;
    setControlValue(newControlValue);
    onValueChange && onValueChange(newControlValue);

    setError(definition.name, null);
    if (!touched[definition.name]) {
      touch(definition.name);
    }
  }

  function handleAddMetricClick() {
    const newValue = [...controlValue, EMPTY_ITEM];
    setControlValue(newValue);
    handleValueChange(newValue);
    setError(definition.name, null);
    const index = newValue.length - 1;
    touch(`extract-metric-${index}-metric-name`);
    touch(`extract-metric-${index}-metric-field`);
    setError(`extract-metric-${index}-metric-name`, null);
    setError(`extract-metric-${index}-metric-field`, null);
    setExpanded("m-" + index);
  }

  function handleDeleteMetricClick(index: number) {
    const newValue = [...controlValue];
    newValue.splice(index, 1);
    setControlValue(newValue);
    handleValueChange(newValue);
    setError(definition.name, null);
    setError(`extract-metric-${index}-metric-name`, null);
    setError(`extract-metric-${index}-metric-field`, null);

    // close accordion if open on index
    if (typeof expanded === "string") {
      const parts = expanded.split("-");
      if (parts.length === 2 && Number(parts[1]) === index) {
        setExpanded(false);
      } else if (parts.length === 2 && Number(parts[1]) > index) {
        // need to decrement 'expanded' to keep it open
        setExpanded("m-" + (Number(parts[1]) - 1));
      }
    }
  }

  function handleAccordionChange(acc: string) {
    if (expanded === acc) {
      setExpanded(false);
    } else {
      setExpanded(acc);
    }
  }

  return (
    <Stack spacing={1}>
      {/* form title */}
      <Stack flexGrow={1}>
        <FormLabel filled required={definition.required}>
          {definition.label}
        </FormLabel>
        {parameterErrors(definition, errors, touched)}
      </Stack>

      {/* metric definitions */}
      {controlValue.map((item, index) => (
        <div key={`extract-metric-${index}-item-container`}>
          <Accordion
            sx={{
              border: 1,
              borderColor: colors.middleLightGray,
            }}
            expanded={expanded === `m-${index}`}
            disableGutters
          >
            <AccordionSummary>
              <Stack
                direction="row"
                justifyContent={"center"}
                alignItems={"center"}
                spacing={"auto"}
                width={"100%"}
              >
                <Typography>{item.metricName}</Typography>
                <Stack direction={"row"} spacing={"10px"} alignItems={"center"}>
                  <IconButton
                    size={"small"}
                    onClick={() => handleAccordionChange(`m-${index}`)}
                    sx={{
                      width: "28px",
                      height: "28px",
                    }}
                  >
                    {expanded === `m-${index}` ? (
                      <ChevronUp />
                    ) : readOnly ? (
                      <ChevronDown />
                    ) : (
                      <EditIcon />
                    )}
                  </IconButton>
                  <IconButton
                    size={"small"}
                    onClick={() => handleDeleteMetricClick(index)}
                    sx={{
                      width: "28px",
                      height: "28px",
                    }}
                  >
                    <TrashIcon />
                  </IconButton>
                </Stack>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Stack padding={"10px"} spacing={2}>
                {/* condition */}
                <Box paddingBottom={"20px"}>
                  <ConditionInput
                    value={item.condition}
                    onValueChange={(v) =>
                      handleParameterValueChange(index, "condition", v)
                    }
                    definition={{
                      description:
                        "An OTTL condition that must evaluate to true to apply this processor. By default, the processor applies to all logs.",
                      label: "Condition",
                      name: `extract-metric-${index}-condition`,
                      options: {},
                      required: false,
                      type: ParameterType.Condition,
                    }}
                  />
                </Box>

                {/* metric name */}
                <StringParamInput
                  value={item.metricName}
                  onValueChange={(v) =>
                    handleParameterValueChange(index, "metricName", v)
                  }
                  definition={{
                    __typename: undefined,
                    description: "Name the metric that will be created.",
                    label: "Metric Name",
                    name: `extract-metric-${index}-metric-name`,
                    required: true,
                    type: ParameterType.String,
                    options: {},
                  }}
                />

                {/* match and field */}
                <Stack direction={"row"} spacing={2}>
                  <Box width={"20%"}>
                    <EnumParamInput
                      value={item.match}
                      onValueChange={(v) =>
                        handleParameterValueChange(index, "match", v)
                      }
                      definition={{
                        __typename: undefined,
                        description: "",
                        label: "Match",
                        name: `extract-metric-${index}-match`,
                        options: {},
                        required: true,
                        type: ParameterType.Enum,
                        validValues: ["Body", "Attributes", "Resource"],
                      }}
                    />
                  </Box>
                  <OTTLFieldInput
                    value={item.metricField}
                    onValueChange={(v) =>
                      handleParameterValueChange(index, "metricField", v)
                    }
                    definition={{
                      __typename: undefined,
                      description: "The name of the field storing metric data.",
                      label: "Metric Field",
                      name: `extract-metric-${index}-metric-field`,
                      required: true,
                      type: ParameterType.OttlField,
                      options: {
                        ottlContext: item.match.toLowerCase(),
                      },
                    }}
                  />
                </Stack>

                {/* type and unit */}
                <Stack direction={"row"} spacing={2}>
                  <Box width={"50%"}>
                    <EnumParamInput
                      value={item.metricType}
                      onValueChange={(v) =>
                        handleParameterValueChange(index, "metricType", v)
                      }
                      definition={{
                        __typename: undefined,
                        description:
                          "The type of metric that should be created.",
                        label: "Metric Type",
                        name: `extract-metric-${index}-metric-type`,
                        options: {},
                        required: true,
                        type: ParameterType.Enum,
                        validValues: [
                          "gauge_double",
                          "gauge_int",
                          "counter_double",
                          "counter_int",
                        ],
                      }}
                    />
                  </Box>
                  <Box width={"50%"}>
                    <EnumParamInput
                      value={item.metricUnit}
                      onValueChange={(v) =>
                        handleParameterValueChange(index, "metricUnit", v)
                      }
                      definition={{
                        __typename: undefined,
                        description:
                          "What unit the metric should have. Create a new one if needed.",
                        label: "Metric Unit",
                        name: `extract-metric-${index}-metric-unit`,
                        options: {
                          creatable: true,
                        },
                        required: true,
                        type: ParameterType.Enum,
                        validValues: [
                          "s",
                          "ms",
                          "bytes",
                          "megabytes",
                          "gigabytes",
                          "b/s",
                          "MB/s",
                          "GB/s",
                          "%",
                          "requests/second",
                          "{logs}",
                        ],
                      }}
                    />
                  </Box>
                </Stack>

                {/* attributes map */}
                <Stack>
                  <MapParamInput
                    value={item.metricAttributes}
                    onValueChange={(v) =>
                      handleParameterValueChange(index, "metricAttributes", v)
                    }
                    definition={{
                      __typename: undefined,
                      default: {},
                      description:
                        "Existing attributes that should be carried over. Can also specify new attributes.",
                      label: "Attributes",
                      name: `metric-extract-${index}-metric-attributes`,
                      options: {
                        labels: {
                          key: "Attribute",
                          value: "Expression",
                        },
                      },
                      required: false,
                      type: ParameterType.Map,
                    }}
                  />
                </Stack>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </div>
      ))}

      {/* new metric button */}
      {!readOnly && (
        <Stack width="100%">
          <Button variant="outlined" onClick={handleAddMetricClick}>
            Add Metric
          </Button>
        </Stack>
      )}
    </Stack>
  );
};
