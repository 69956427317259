import { createContext, useContext, useState } from "react";
import { usePipelineGraphV2MetricsSubscription } from "../../graphql/generated";
import { DEFAULT_TELEMETRY_TYPE } from "../MeasurementControlBar/MeasurementControlBar";
import {
  initialData,
  PipelineGraphMetricsData,
} from "./PipelineGraphMetricsData";
import { V2Config } from "./types";

export interface PipelineGraphContextValue {
  configuration: V2Config;
  refetchConfiguration: () => void;

  selectedTelemetryType: string;
  period: string;

  hoveredSet: string[];
  setHoveredSet: React.Dispatch<React.SetStateAction<string[]>>;

  editProcessorsInfo: EditProcessorsInfo | null;
  // editProcessor opens up the editing dialog for a source or destination
  editProcessors: (
    resourceType: "source" | "destination",
    index: number,
  ) => void;
  closeProcessorDialog(): void;
  editProcessorsOpen: boolean;

  metricData: PipelineGraphMetricsData;

  readOnlyGraph?: boolean;

  addSourceOpen: boolean;
  addDestinationOpen: boolean;
  setAddSourceOpen: (value: boolean) => void;
  setAddDestinationOpen: (value: boolean) => void;

  agentID?: string;
}

export interface PipelineGraphProviderProps {
  configuration: V2Config;
  refetchConfiguration: () => void;
  selectedTelemetryType: string;
  period: string;
  readOnly?: boolean;
  addSourceOpen: boolean;
  addDestinationOpen: boolean;
  setAddSourceOpen: (value: boolean) => void;
  setAddDestinationOpen: (value: boolean) => void;
  agentID?: string;
}

interface EditProcessorsInfo {
  resourceType: "source" | "destination";
  index: number;
}

const defaultValue: PipelineGraphContextValue = {
  configuration: null,
  refetchConfiguration: () => {},
  selectedTelemetryType: DEFAULT_TELEMETRY_TYPE,
  period: "1m",
  hoveredSet: [],
  setHoveredSet: () => {},
  editProcessors: () => {},
  closeProcessorDialog: () => {},
  editProcessorsInfo: null,
  editProcessorsOpen: false,
  metricData: initialData,
  setAddSourceOpen: () => {},
  setAddDestinationOpen: () => {},
  addSourceOpen: false,
  addDestinationOpen: false,
  agentID: undefined,
};

export const V2PipelineContext = createContext(defaultValue);

export const V2PipelineGraphProvider: React.FC<PipelineGraphProviderProps> = ({
  children,
  selectedTelemetryType,
  period,
  configuration,
  refetchConfiguration,
  setAddSourceOpen,
  setAddDestinationOpen,
  addSourceOpen,
  addDestinationOpen,
  readOnly,
  agentID,
}) => {
  const [hoveredSet, setHoveredSet] = useState<string[]>([]);
  const [metricData, setMetricData] =
    useState<PipelineGraphMetricsData>(initialData);
  const [editProcessorsInfo, setEditingProcessors] = useState<{
    resourceType: "source" | "destination";
    index: number;
  } | null>(null);

  const [editProcessorsOpen, setEditProcessorsOpen] = useState(false);

  function editProcessors(
    resourceType: "source" | "destination",
    index: number,
  ) {
    setEditingProcessors({ resourceType, index });
    setEditProcessorsOpen(true);
  }

  function closeProcessorDialog() {
    setEditProcessorsOpen(false);
    // Reset the editing processors on a timeout to avoid a flash of empty state.
    setTimeout(() => {
      setEditingProcessors(null);
    }, 300);
  }

  usePipelineGraphV2MetricsSubscription({
    variables: {
      period: period,
      name: configuration?.metadata.name,
      agent: agentID,
    },
    onSubscriptionData(opts) {
      if (opts.subscriptionData.data) {
        setMetricData(new PipelineGraphMetricsData(opts.subscriptionData.data));
      }
    },
  });

  return (
    <V2PipelineContext.Provider
      value={{
        configuration,
        refetchConfiguration,
        setHoveredSet,
        hoveredSet,
        selectedTelemetryType,
        editProcessors,
        closeProcessorDialog,
        editProcessorsInfo,
        editProcessorsOpen,
        readOnlyGraph: readOnly,
        setAddSourceOpen,
        setAddDestinationOpen,
        addSourceOpen,
        addDestinationOpen,
        agentID,
        metricData,
        period,
      }}
    >
      {children}
    </V2PipelineContext.Provider>
  );
};

export function useV2PipelineGraph(): PipelineGraphContextValue {
  return useContext(V2PipelineContext);
}
