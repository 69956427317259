import { Extension, Kind } from "../../graphql/generated";
import { BPBaseResource } from "./base-resource";

export type MinimumExtension = Pick<Extension, "spec" | "metadata">;

export class BPExtension extends BPBaseResource implements Extension {
  __typename?: Kind.Extension | undefined;

  constructor(d: MinimumExtension) {
    super();

    this.kind = Kind.Extension;
    this.metadata = d.metadata;
    this.spec = d.spec;
  }
}
