import { Box, Stack, Tab, Tabs } from "@mui/material";
import { LineChart } from "@mui/x-charts/LineChart";
import colors from "../../styles/colors";
import {
  AgentHealthInterval,
  AgentHealthIntervalValues,
} from "./AgentHealthTab";
import styles from "./agent-health.module.scss";

export interface AgentHealthWidgetProps {
  dataset: AgentHealthGraphData;
  label: JSX.Element;
  measurementInterval: AgentHealthInterval;
  onMeasurementIntervalChange: (v: AgentHealthInterval) => void;
}

export interface AgentDataPoint extends Record<string, Date | number> {
  value: number;
  timestamp: Date;
}

type AgentHealthGraphData = AgentDataPoint[];

export const AgentHealthWidget: React.FC<AgentHealthWidgetProps> = ({
  dataset,
  label,
  measurementInterval,
  onMeasurementIntervalChange,
}) => {
  // Some metrics (e.g. process.cpu.utilization) can be greater than 100% (e.g. 200% for 2 cores)
  const max = Math.max(...dataset.map((d) => d.value));
  return (
    <>
      <Stack direction="row" spacing={1} className={styles.header}>
        {label}
        <Box flex={1} />

        <Tabs
          value={measurementInterval}
          onChange={(_e, v) => onMeasurementIntervalChange(v)}
          classes={{ scroller: styles.scroller }}
        >
          {AgentHealthIntervalValues.map((value) => (
            <Tab
              sx={{ minWidth: "auto", px: 1 }}
              key={value}
              value={value}
              label={value}
            />
          ))}
        </Tabs>
      </Stack>
      {dataset.length < 2 ? (
        <Stack
          height={400}
          width="100%"
          justifyContent="center"
          alignItems="center"
        >
          No Data
        </Stack>
      ) : (
        <LineChart
          sx={{ padding: 1 }}
          xAxis={[{ dataKey: "timestamp", scaleType: "time" }]}
          yAxis={[
            {
              scaleType: "linear",
              valueFormatter: (value: number) => `${value.toFixed(0)} %`,
              max: max > 100 ? max : 100,
            },
          ]}
          series={[
            {
              dataKey: "value",
              color: colors.pixelPointBlue,
              curve: "linear",
              valueFormatter,
              showMark: false,
            },
          ]}
          dataset={dataset}
          height={400}
        />
      )}
    </>
  );
};

function valueFormatter(value: number | null): string {
  if (value === null) {
    return "0 %";
  }

  return `${value.toFixed(2)} %`;
}
