import React from "react";
import colors from "../../styles/colors";

/**
 * The full size BindPlane logo, updated 2023.
 */
export const BindPlaneLogo: React.FC<React.ComponentProps<"svg">> = (props) => {
  return (
    <svg
      width="87"
      height="26"
      viewBox="0 0 87 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.35286 9.51986C4.87617 9.51986 3.61904 10.0694 2.91743 11.0052V5.2721C2.91743 4.675 2.42878 4.18945 1.82787 4.18945C1.22696 4.18945 0.759766 4.675 0.759766 5.2721V18.9529C0.759766 19.55 1.23934 20.0355 1.82787 20.0355C2.4164 20.0355 2.91743 19.55 2.91743 18.9529V18.6133C3.624 19.5516 4.92404 20.143 6.33057 20.143C9.29798 20.143 11.5357 17.8546 11.5357 14.8207C11.5357 11.7869 9.35576 9.52068 6.35203 9.52068L6.35286 9.51986ZM9.33513 14.7986C9.33513 16.6473 7.97895 18.0408 6.18034 18.0408C4.61946 18.0408 2.93889 17.0262 2.93889 14.7986C2.93889 12.735 4.60873 11.6212 6.18034 11.6212C8.00784 11.6212 9.33513 12.9573 9.33513 14.7986Z"
        fill={colors.black}
      />
      <path
        d="M13.6345 9.62695C13.046 9.62695 12.5664 10.1125 12.5664 10.7096V18.9525C12.5664 19.5496 13.046 20.0352 13.6345 20.0352C14.223 20.0352 14.7241 19.5496 14.7241 18.9525V10.7096C14.7241 10.1125 14.2354 9.62695 13.6345 9.62695Z"
        fill={colors.black}
      />
      <path
        d="M13.6338 4.80859C12.8174 4.80859 12.1777 5.40651 12.1777 6.17011C12.1777 6.9337 12.8174 7.5308 13.6338 7.5308C14.4501 7.5308 15.0898 6.93288 15.0898 6.17011C15.0898 5.40733 14.4501 4.80859 13.6338 4.80859Z"
        fill={colors.black}
      />
      <path
        d="M21.5403 9.51953C20.4788 9.51953 19.1812 9.97146 18.4936 10.9713V10.7096C18.4936 10.1125 18.005 9.62698 17.404 9.62698C16.8031 9.62698 16.3359 10.1125 16.3359 10.7096V18.9525C16.3359 19.5496 16.8155 20.0352 17.404 20.0352C17.9926 20.0352 18.4936 19.5496 18.4936 18.9525V14.4349C18.4936 11.8243 20.5951 11.6217 21.239 11.6217C22.5671 11.6217 23.3595 12.5772 23.3595 14.1782V18.9525C23.3595 19.5496 23.8391 20.0352 24.4284 20.0352C25.0178 20.0352 25.518 19.5496 25.518 18.9525V14.0494C25.518 11.1706 24.0685 9.51953 21.5411 9.51953H21.5403Z"
        fill={colors.black}
      />
      <path
        d="M36.1696 4.18945C35.5687 4.18945 35.0792 4.675 35.0792 5.2721V11.0052C34.3784 10.0694 33.1213 9.51986 31.6446 9.51986C28.6409 9.51986 26.4609 11.7491 26.4609 14.8199C26.4609 17.8907 28.6987 20.1421 31.6661 20.1421C33.0726 20.1421 34.3735 19.5508 35.0792 18.6133V18.9529C35.0792 19.55 35.5679 20.0355 36.1696 20.0355C36.7713 20.0355 37.2377 19.55 37.2377 18.9529V5.2721C37.2377 4.675 36.759 4.18945 36.1696 4.18945ZM35.0586 14.7994C35.0586 17.0271 33.3788 18.0416 31.8171 18.0416C30.0185 18.0416 28.6624 16.6481 28.6624 14.7994C28.6624 12.9507 29.9888 11.622 31.8171 11.622C33.3879 11.622 35.0586 12.7358 35.0586 14.7994Z"
        fill={colors.black}
      />
      <path
        d="M44.4868 9.51953C43.0861 9.51953 41.7852 10.1183 41.0737 11.0689V10.7088C41.0737 10.1117 40.585 9.62616 39.9841 9.62616C39.3832 9.62616 38.916 10.1117 38.916 10.7088V23.7687C38.916 24.3658 39.3948 24.8513 39.9841 24.8513C40.5735 24.8513 41.0737 24.3658 41.0737 23.7687V18.6589C41.7637 19.5808 43.0374 20.1418 44.4868 20.1418C47.4658 20.1418 49.6276 17.9216 49.6276 14.8622C49.6276 11.8029 47.4658 9.51953 44.4868 9.51953ZM47.4476 14.8844C47.4476 16.7429 46.1501 18.0405 44.2928 18.0405C42.7039 18.0405 41.0951 16.9562 41.0951 14.8844C41.0951 12.6428 42.7526 11.6209 44.2928 11.6209C46.1212 11.6209 47.4476 12.993 47.4476 14.8844Z"
        fill={colors.black}
      />
      <path
        d="M51.7478 4.18945C51.1593 4.18945 50.6797 4.675 50.6797 5.2721V18.9529C50.6797 19.55 51.1593 20.0355 51.7478 20.0355C52.3363 20.0355 52.8374 19.55 52.8374 18.9529V5.2721C52.8374 4.675 52.3487 4.18945 51.7478 4.18945Z"
        fill={colors.black}
      />
      <path
        d="M63.3825 9.62698C62.7931 9.62698 62.3136 10.1125 62.3136 10.7096V11.0082C61.6169 10.0953 60.3094 9.51953 58.879 9.51953C55.8629 9.51953 53.6738 11.7488 53.6738 14.8196C53.6738 17.8904 55.9116 20.1418 58.879 20.1418C60.2979 20.1418 61.6062 19.5504 62.3136 18.6121V18.9517C62.3136 19.5488 62.7931 20.0344 63.3825 20.0344C63.9719 20.0344 64.4721 19.5488 64.4721 18.9517V10.7088C64.4721 10.1117 63.9834 9.62616 63.3825 9.62616V9.62698ZM62.2921 14.7991C62.2921 17.0267 60.6124 18.0413 59.0515 18.0413C57.2405 18.0413 55.8752 16.6478 55.8752 14.7991C55.8752 12.9504 57.2108 11.6217 59.0515 11.6217C60.6223 11.6217 62.2921 12.7355 62.2921 14.7991Z"
        fill={colors.black}
      />
      <path
        d="M71.0735 9.51953C70.012 9.51953 68.7144 9.97146 68.0268 10.9713V10.7096C68.0268 10.1125 67.5382 9.62698 66.9372 9.62698C66.3363 9.62698 65.8691 10.1125 65.8691 10.7096V18.9525C65.8691 19.5496 66.3487 20.0352 66.9372 20.0352C67.5258 20.0352 68.0268 19.5496 68.0268 18.9525V14.4349C68.0268 11.8243 70.1283 11.6217 70.7722 11.6217C72.0995 11.6217 72.8927 12.5772 72.8927 14.1782V18.9525C72.8927 19.5496 73.3723 20.0352 73.9616 20.0352C74.551 20.0352 75.0512 19.5496 75.0512 18.9525V14.0494C75.0512 11.1706 73.6017 9.51953 71.0743 9.51953H71.0735Z"
        fill={colors.black}
      />
      <path
        d="M86.34 14.7983C86.34 11.6906 84.2443 9.51953 81.243 9.51953C78.2418 9.51953 75.9941 11.7398 75.9941 14.7983C75.9941 17.8568 78.2013 20.1418 81.243 20.1418C83.0004 20.1418 84.4903 19.4742 85.4329 18.2668C85.5864 18.0921 85.6582 17.8584 85.6343 17.6082C85.6079 17.331 85.4667 17.0743 85.2447 16.9021C84.827 16.6027 84.2063 16.72 83.7994 17.1727C83.1085 17.924 82.4564 18.1898 81.3082 18.1898C79.6582 18.1898 78.4382 17.2039 78.17 15.6669H85.4667C85.9488 15.6669 86.3409 15.2773 86.3409 14.7974L86.34 14.7983ZM84.1873 13.951H78.1691C78.4333 12.4402 79.6277 11.4708 81.243 11.4708C82.8584 11.4708 83.924 12.4181 84.1873 13.951Z"
        fill={colors.black}
      />
    </svg>
  );
};

export const BindPlaneLogoSquare: React.FC<React.ComponentProps<"svg">> = (
  props,
) => {
  return (
    <svg
      width="25"
      height="28"
      viewBox="0 0 25 28"
      fill="none"
      version="1.1"
      id="svg56"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="g56" transform="translate(0.17366493)">
        <circle
          cx="12.3271"
          cy="5.9347801"
          fill="url(#paint0_linear_5648_19223)"
          id="ellipse1"
          r="1.36957"
          style={{ fill: "url(#paint0_linear_5648_19223)" }}
        />
        <circle
          cx="6.54389"
          cy="5.9347801"
          fill="url(#paint1_linear_5648_19223)"
          id="ellipse2"
          r="1.36957"
          style={{ fill: "url(#paint1_linear_5648_19223)" }}
        />
        <circle
          opacity="0.7"
          cx="12.3271"
          cy="1.36957"
          fill="url(#paint2_linear_5648_19223)"
          id="ellipse3"
          r="1.36957"
          style={{ fill: "url(#paint2_linear_5648_19223)" }}
        />
        <circle
          cx="18.1098"
          cy="1.36957"
          fill="url(#paint3_linear_5648_19223)"
          id="ellipse4"
          r="1.36957"
          style={{ fill: "url(#paint3_linear_5648_19223)" }}
        />
        <circle
          cx="6.54389"
          cy="1.36957"
          fill="url(#paint4_linear_5648_19223)"
          id="ellipse5"
          r="1.36957"
          style={{ fill: "url(#paint4_linear_5648_19223)" }}
        />
        <circle
          opacity="0.7"
          cx="6.54389"
          cy="10.5"
          fill="url(#paint5_linear_5648_19223)"
          id="ellipse6"
          r="1.36957"
          style={{ fill: "url(#paint5_linear_5648_19223)" }}
        />
        <circle
          opacity="0.7"
          cx="1.36957"
          cy="5.9347801"
          fill="url(#paint6_linear_5648_19223)"
          id="ellipse7"
          r="1.36957"
          style={{ fill: "url(#paint6_linear_5648_19223)" }}
        />
        <circle
          cx="1.36957"
          cy="10.5"
          fill="url(#paint7_linear_5648_19223)"
          id="ellipse8"
          r="1.36957"
          style={{ fill: "url(#paint7_linear_5648_19223)" }}
        />
        <circle
          cx="1.36957"
          cy="14.7609"
          fill="url(#paint8_linear_5648_19223)"
          id="ellipse9"
          r="1.36957"
          style={{ fill: "url(#paint8_linear_5648_19223)" }}
        />
        <circle
          cx="23.2831"
          cy="5.9347801"
          fill="url(#paint9_linear_5648_19223)"
          id="ellipse10"
          r="1.36957"
          style={{ fill: "url(#paint9_linear_5648_19223)" }}
        />
        <circle
          opacity="0.7"
          cx="23.2831"
          cy="10.5"
          fill="url(#paint10_linear_5648_19223)"
          id="ellipse11"
          r="1.36957"
          style={{ fill: "url(#paint10_linear_5648_19223)" }}
        />
        <circle
          cx="23.2831"
          cy="14.7609"
          fill="url(#paint11_linear_5648_19223)"
          id="ellipse12"
          r="1.36957"
          style={{ fill: "url(#paint11_linear_5648_19223)" }}
        />
        <rect
          x="4.87012"
          y="13.3913"
          width="3.3478401"
          height="7"
          rx="1.67392"
          fill="url(#paint12_linear_5648_19223)"
          id="rect12"
          style={{ fill: "url(#paint12_linear_5648_19223)" }}
        />
        <rect
          x="10.6528"
          y="9.1304302"
          width="3.3478401"
          height="11.2609"
          rx="1.67392"
          fill="url(#paint13_linear_5648_19223)"
          id="rect13"
          style={{ fill: "url(#paint13_linear_5648_19223)" }}
        />
        <rect
          x="16.435499"
          y="4.5652199"
          width="3.3478401"
          height="15.8261"
          rx="1.67392"
          fill="url(#paint14_linear_5648_19223)"
          id="rect14"
          style={{ fill: "url(#paint14_linear_5648_19223)" }}
        />
        <rect
          x="21.913101"
          y="-17.9575"
          width="2.4347799"
          height="11.2609"
          rx="1.2173899"
          transform="rotate(90)"
          fill="url(#paint15_linear_5648_19223)"
          id="rect15"
          style={{ fill: "url(#paint15_linear_5648_19223)" }}
        />
        <rect
          x="25.565201"
          y="-15.978999"
          width="2.4347799"
          height="7.3043699"
          rx="1.2173899"
          transform="rotate(90)"
          fill="url(#paint16_linear_5648_19223)"
          id="rect16"
          style={{ fill: "url(#paint16_linear_5648_19223)" }}
        />
      </g>
      <defs id="defs56">
        <linearGradient
          id="paint0_linear_5648_19223"
          x1="11.0717"
          y1="7.4184799"
          x2="13.6966"
          y2="4.5652099"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#17191C" id="stop23" />
          <stop offset="0.935195" stop-color="#666673" id="stop24" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5648_19223"
          x1="5.2884498"
          y1="7.4184799"
          x2="7.9134498"
          y2="4.5652099"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#17191C" id="stop25" />
          <stop offset="0.935195" stop-color="#666673" id="stop26" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5648_19223"
          x1="11.0717"
          y1="2.85326"
          x2="13.6966"
          y2="-8.3854902e-06"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#17191C" id="stop27" />
          <stop offset="0.935195" stop-color="#666673" id="stop28" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_5648_19223"
          x1="16.854401"
          y1="2.85326"
          x2="19.479401"
          y2="-8.3854902e-06"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#17191C" id="stop29" />
          <stop offset="0.935195" stop-color="#666673" id="stop30" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_5648_19223"
          x1="5.2884498"
          y1="2.85326"
          x2="7.9134498"
          y2="-8.3854902e-06"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#17191C" id="stop31" />
          <stop offset="0.935195" stop-color="#666673" id="stop32" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_5648_19223"
          x1="5.2884498"
          y1="11.9837"
          x2="7.9134498"
          y2="9.1304502"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#17191C" id="stop33" />
          <stop offset="0.935195" stop-color="#666673" id="stop34" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_5648_19223"
          x1="0.114131"
          y1="7.4184799"
          x2="2.73913"
          y2="4.5652099"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#17191C" id="stop35" />
          <stop offset="0.935195" stop-color="#666673" id="stop36" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_5648_19223"
          x1="0.114131"
          y1="11.9837"
          x2="2.73913"
          y2="9.1304502"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#17191C" id="stop37" />
          <stop offset="0.935195" stop-color="#666673" id="stop38" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_5648_19223"
          x1="0.114131"
          y1="16.2446"
          x2="2.73913"
          y2="13.3913"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#17191C" id="stop39" />
          <stop offset="0.935195" stop-color="#666673" id="stop40" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_5648_19223"
          x1="22.0277"
          y1="7.4184799"
          x2="24.6527"
          y2="4.5652099"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#17191C" id="stop41" />
          <stop offset="0.935195" stop-color="#666673" id="stop42" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_5648_19223"
          x1="22.0277"
          y1="11.9837"
          x2="24.6527"
          y2="9.1304502"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#17191C" id="stop43" />
          <stop offset="0.935195" stop-color="#666673" id="stop44" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_5648_19223"
          x1="22.0277"
          y1="16.2446"
          x2="24.6527"
          y2="13.3913"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#17191C" id="stop45" />
          <stop offset="0.935195" stop-color="#666673" id="stop46" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_5648_19223"
          x1="4.87012"
          y1="20.3913"
          x2="10.3194"
          y2="17.785101"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#17191C" id="stop47" />
          <stop offset="1" stop-color="#494E59" id="stop48" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_5648_19223"
          x1="10.6528"
          y1="20.3913"
          x2="16.8048"
          y2="18.5623"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#17191C" id="stop49" />
          <stop offset="1" stop-color="#494E59" id="stop50" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_5648_19223"
          x1="16.435499"
          y1="20.3913"
          x2="22.8444"
          y2="19.035601"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#17191C" id="stop51" />
          <stop offset="1" stop-color="#494E59" id="stop52" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_5648_19223"
          x1="17.9575"
          y1="33.174"
          x2="22.6096"
          y2="32.168098"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(3.9556,-39.8706)"
        >
          <stop stop-color="#17191C" id="stop53" />
          <stop offset="1" stop-color="#494E59" id="stop54" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_5648_19223"
          x1="15.979"
          y1="32.869598"
          x2="20.361601"
          y2="31.4088"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(9.5862,-41.5442)"
        >
          <stop stop-color="#17191C" id="stop55" />
          <stop offset="1" stop-color="#494E59" id="stop56" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const HoneycombLogo: React.FC<React.ComponentProps<"svg">> = (props) => {
  return (
    <svg
      width="164"
      height="48"
      viewBox="0 0 164 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M151.695 26.0424C151.755 26.1018 151.8 26.1743 151.827 26.2541C151.861 26.333 151.879 26.4178 151.879 26.5036V27.1389C151.879 27.3115 151.818 27.4785 151.705 27.6094C151.652 27.667 151.588 27.7132 151.517 27.7456C151.445 27.7779 151.368 27.7956 151.29 27.7977H150.918C150.839 27.7986 150.761 27.7822 150.689 27.7496C150.618 27.7171 150.554 27.6692 150.503 27.6094C150.39 27.4785 150.328 27.3115 150.329 27.1389V26.5036C150.326 26.3305 150.388 26.1628 150.503 26.033C150.556 25.9753 150.62 25.9288 150.691 25.8965C150.762 25.8641 150.839 25.8465 150.918 25.8447H151.276C151.351 25.843 151.425 25.8591 151.493 25.8918C151.569 25.9285 151.638 25.9796 151.695 26.0424Z"
          fill="#25303E"
        />
        <path
          d="M64.6293 28.8801H61.5331C61.0567 28.8741 60.5841 28.9668 60.1453 29.1523C59.7065 29.3378 59.311 29.612 58.9836 29.9577C58.6441 30.2861 58.3734 30.6786 58.1873 31.1125C58.0012 31.5463 57.9034 32.0128 57.8997 32.4848V40.0001H59.1438V33.0777C59.1533 32.2922 59.4655 31.5405 60.0156 30.9789C60.5644 30.4332 61.3054 30.124 62.0798 30.1177H63.9271C64.3091 30.1176 64.6872 30.1937 65.0392 30.3416C65.3913 30.4895 65.7102 30.7062 65.9771 30.9789C66.2546 31.2535 66.4746 31.5805 66.6242 31.9408C66.7739 32.3012 66.8503 32.6876 66.849 33.0777V40.0001H68.1497V32.4706C68.1547 31.9974 68.0609 31.5282 67.8743 31.0931C67.6877 30.658 67.4123 30.2665 67.0658 29.9436C66.7669 29.5936 66.3923 29.3159 65.9702 29.1317C65.5481 28.9474 65.0896 28.8614 64.6293 28.8801Z"
          fill="#25303E"
        />
        <path
          d="M52.3573 28.6635H49.9161C48.8835 28.6805 47.8948 29.0835 47.1451 29.7929C46.4296 30.5294 46.0219 31.5104 46.0046 32.5364V36.3011C46.0122 37.3296 46.4135 38.3162 47.1262 39.0587C47.4865 39.4259 47.9181 39.7156 48.3947 39.9098C48.8713 40.104 49.3826 40.1988 49.8973 40.1882H52.3384C53.3813 40.1805 54.3822 39.7767 55.1377 39.0587C55.5023 38.7011 55.7911 38.2739 55.987 37.8026C56.1829 37.3313 56.2819 36.8255 56.2782 36.3152V32.5505C56.2646 31.5196 55.8565 30.533 55.1377 29.7929C54.3857 29.0813 53.3932 28.6782 52.3573 28.6635ZM54.9115 32.4282V36.5176C54.8979 37.1594 54.646 37.7732 54.2046 38.2399C53.7513 38.6749 53.1507 38.9235 52.5222 38.9364H49.8077C49.1756 38.9343 48.5697 38.6842 48.1206 38.2399C47.6725 37.7779 47.4209 37.1607 47.4184 36.5176V32.4282C47.4296 31.7882 47.6801 31.1755 48.1206 30.7105C48.5741 30.2724 49.1769 30.0219 49.8077 30.0093H52.5222C53.1507 30.0133 53.7521 30.2654 54.1952 30.7105C54.6405 31.1736 54.896 31.7864 54.9115 32.4282Z"
          fill="#25303E"
        />
        <path
          d="M41.0003 28.8801H37.9135C37.4465 28.8641 36.9821 28.9559 36.5563 29.1483C36.1749 29.2725 35.8235 29.4744 35.5242 29.7412V24.8424H34.2754V40.0001H35.5808V33.0212C35.5904 32.2366 35.9008 31.4856 36.4479 30.9224C36.9983 30.3793 37.7383 30.0705 38.5121 30.0612H40.3594C40.7419 30.062 41.1204 30.1386 41.4731 30.2864C41.8258 30.4342 42.1456 30.6504 42.4141 30.9224C42.6897 31.1982 42.9081 31.5255 43.0569 31.8856C43.2056 32.2458 43.2819 32.6317 43.2812 33.0212V39.9624H44.5866V32.433C44.5919 31.9592 44.4978 31.4896 44.3103 31.0544C44.1228 30.6191 43.8461 30.2279 43.498 29.9059C43.1814 29.564 42.7939 29.295 42.3625 29.1179C41.9311 28.9407 41.4663 28.8595 41.0003 28.8801Z"
          fill="#25303E"
        />
        <path
          d="M108.071 28.7059H105.63C104.596 28.7207 103.607 29.124 102.858 29.8353C102.142 30.5713 101.734 31.5526 101.718 32.5788V36.3435C101.735 37.3695 102.143 38.3505 102.858 39.0871C103.219 39.4542 103.65 39.7439 104.127 39.9381C104.604 40.1324 105.115 40.2271 105.63 40.2165H108.071C109.103 40.1985 110.091 39.7956 110.842 39.0871C111.557 38.3505 111.965 37.3695 111.982 36.3435V32.5788C111.966 31.5526 111.558 30.5713 110.842 29.8353C110.481 29.4681 110.05 29.1785 109.573 28.9842C109.097 28.79 108.585 28.6953 108.071 28.7059ZM110.625 32.4706V36.56C110.611 37.2003 110.359 37.8126 109.918 38.2776C109.701 38.5044 109.44 38.6839 109.151 38.8046C108.861 38.9253 108.549 38.9846 108.236 38.9788H105.516C104.885 38.9763 104.28 38.7242 103.834 38.2776C103.385 37.8182 103.133 37.202 103.132 36.56V32.4706C103.144 31.8293 103.394 31.2154 103.834 30.7482C104.287 30.3128 104.888 30.0626 105.516 30.0471H108.236C108.867 30.0506 109.471 30.3025 109.918 30.7482C110.359 31.2149 110.611 31.8288 110.625 32.4706Z"
          fill="#25303E"
        />
        <path
          d="M128.433 28.9319H125.394C124.806 28.9346 124.227 29.0834 123.711 29.3648C123.248 29.616 122.844 29.9643 122.528 30.386C122.224 29.948 121.812 29.5962 121.331 29.3648C120.833 29.0903 120.275 28.9417 119.705 28.9319H116.774C115.834 28.9565 114.939 29.3425 114.277 30.0095C113.948 30.3406 113.691 30.7359 113.522 31.1708C113.353 31.6057 113.276 32.0707 113.296 32.5366V40.066H114.517V33.1813C114.511 32.794 114.585 32.4095 114.734 32.0519C114.87 31.7026 115.074 31.383 115.332 31.1107C115.61 30.8455 115.928 30.6263 116.275 30.4613C116.615 30.3097 116.986 30.2373 117.359 30.2495H119.098C119.752 30.2489 120.385 30.477 120.888 30.8942C121.38 31.2896 121.725 31.838 121.869 32.4519V40.1225H123.226V32.3201C123.398 31.7193 123.761 31.1904 124.26 30.8128C124.758 30.4352 125.366 30.2293 125.992 30.226H127.788C128.56 30.2331 129.3 30.5422 129.847 31.0872C130.397 31.626 130.71 32.3604 130.719 33.1295V40.0707H132.076V32.5413C132.079 32.073 131.989 31.6088 131.812 31.1752C131.635 30.7415 131.374 30.347 131.044 30.0142C130.33 29.3551 129.405 28.9714 128.433 28.9319Z"
          fill="#25303E"
        />
        <path
          d="M142.444 29.7412C142.085 29.3913 141.659 29.1158 141.193 28.9309C140.726 28.7459 140.227 28.6551 139.725 28.6636H137.284C136.339 28.6603 135.428 29.0113 134.73 29.6471V24.7906H133.368V36.2354C133.387 37.2609 133.795 38.241 134.508 38.9789C134.869 39.3447 135.301 39.6335 135.778 39.8276C136.254 40.0218 136.765 40.1173 137.279 40.1083H139.72C140.234 40.113 140.744 40.0155 141.219 39.8216C141.695 39.6277 142.128 39.3412 142.491 38.9789C142.856 38.6213 143.146 38.1943 143.342 37.723C143.539 37.2518 143.639 36.7459 143.637 36.2354V32.4706C143.631 31.9738 143.538 31.4818 143.363 31.0165C143.119 30.5501 142.81 30.1207 142.444 29.7412ZM134.678 32.3765C134.691 31.7347 134.943 31.1209 135.385 30.6542C135.837 30.2171 136.438 29.9667 137.067 29.953H139.782C140.413 29.9565 141.017 30.2085 141.464 30.6542C141.914 31.1154 142.167 31.7327 142.171 32.3765V36.4659C142.158 37.1065 141.906 37.7191 141.464 38.1836C141.013 38.6226 140.411 38.8734 139.782 38.8848H137.067C136.754 38.884 136.445 38.8217 136.156 38.7014C135.867 38.5811 135.605 38.4051 135.385 38.1836C134.934 37.7246 134.681 37.1085 134.678 36.4659V32.3765Z"
          fill="#25303E"
        />
        <path
          d="M99.4367 38.08C99.2387 38.3036 98.9985 38.4859 98.7298 38.6165C98.4414 38.7734 98.1154 38.8483 97.7873 38.833H95.2378C94.6185 38.7967 94.036 38.5277 93.6072 38.08C93.3933 37.8586 93.2256 37.5968 93.1139 37.31C93.0023 37.0232 92.9489 36.7171 92.9569 36.4095V32.3201C92.9502 31.7018 93.1832 31.1048 93.6072 30.6542C94.0432 30.2168 94.6216 29.9497 95.2378 29.9012H97.7873C98.3346 29.9003 98.8606 30.1131 99.2529 30.4942L99.3095 30.5459H100.851L100.794 30.3859C100.476 29.8387 100.03 29.3769 99.4933 29.0401C98.9457 28.7306 98.327 28.5684 97.6978 28.5695H95.4169C94.4297 28.6234 93.4992 29.0468 92.8108 29.7553C92.1288 30.4822 91.7584 31.4466 91.7787 32.4424V36.2071C91.7567 36.7018 91.837 37.1956 92.0145 37.6579C92.1921 38.1203 92.4632 38.5411 92.8108 38.8942C93.4915 39.6135 94.4266 40.039 95.4169 40.08H97.6978C98.3518 40.0648 98.9897 39.8747 99.5451 39.5295C100.113 39.2007 100.568 38.7087 100.851 38.1177L100.902 37.9577H99.4367V38.08Z"
          fill="#25303E"
        />
        <path
          d="M85.8074 37.647L85.699 37.5952C85.3598 37.4943 85.0408 37.335 84.7564 37.1246C84.0922 36.4456 83.6066 35.613 83.3426 34.7011L81.6602 29.1105C81.6602 29.054 81.6084 29.0023 81.6084 28.9458C81.6084 28.8893 81.5518 28.8376 81.5518 28.7858V28.7058H80.303V28.8093C80.3563 29.1008 80.4287 29.3885 80.5198 29.6705L82.0419 34.847C82.5839 36.7293 83.7809 38.2352 85.19 38.7717L82.6593 43.7646H83.96L91.7264 28.7058H90.3692L85.8074 37.647Z"
          fill="#25303E"
        />
        <path
          d="M151.747 29.3083H150.499V40.1319H151.747V29.3083Z"
          fill="#25303E"
        />
        <path
          d="M163.675 30.9789C163.482 30.5057 163.184 30.0825 162.803 29.7413C162.438 29.3891 162.018 29.0983 161.559 28.8801C161.094 28.6869 160.593 28.5954 160.089 28.6119H157.648C156.615 28.6276 155.626 29.0308 154.877 29.7413C154.514 30.0976 154.227 30.5225 154.031 30.9912C153.835 31.4599 153.735 31.9629 153.736 32.4707V36.2354C153.754 37.2614 154.161 38.2423 154.877 38.9789C155.237 39.3461 155.669 39.6357 156.145 39.83C156.622 40.0242 157.133 40.1189 157.648 40.1083H160.089C161.122 40.0935 162.112 39.6902 162.86 38.9789C163.576 38.2423 163.983 37.2614 164.001 36.2354V32.4707C164.001 31.9559 163.89 31.4471 163.675 30.9789ZM162.643 32.3907V36.4801C162.63 37.1206 162.378 37.7332 161.936 38.1977C161.485 38.6368 160.884 38.8876 160.254 38.8989H157.539C157.227 38.8982 156.917 38.8359 156.628 38.7156C156.34 38.5953 156.078 38.4193 155.857 38.1977C155.407 37.7388 155.153 37.1227 155.15 36.4801V32.3766C155.164 31.7348 155.416 31.1209 155.857 30.6542C156.31 30.2185 156.911 29.9697 157.539 29.9577H160.254C160.885 29.96 161.489 30.2102 161.936 30.6542C162.378 31.1209 162.63 31.7348 162.643 32.3766V32.3907Z"
          fill="#25303E"
        />
        <path
          d="M76.4152 28.7058H73.0457C72.2864 28.683 71.5473 28.9525 70.9816 29.4588C70.405 29.95 70.0363 30.6411 69.9495 31.3929V36.2352C69.9469 36.7902 70.0593 37.3397 70.2794 37.8493C70.4865 38.3364 70.7858 38.7789 71.1607 39.1529C71.5273 39.5197 71.9742 39.7968 72.4661 39.9623C72.8202 40.0653 73.183 40.1362 73.55 40.174H75.7743C76.4868 40.1707 77.1804 39.9453 77.7584 39.5293C77.9509 39.3867 78.1321 39.2294 78.3003 39.0588C78.4445 38.8926 78.5723 38.7129 78.682 38.5223C78.7929 38.335 78.8829 38.1361 78.9507 37.9293L79.0072 37.8211L77.8243 37.647L77.7725 37.7035C77.6414 37.9878 77.4556 38.2436 77.2258 38.4564C76.851 38.7865 76.3728 38.9762 75.8733 38.9929H73.3756C73.1731 38.9895 72.9725 38.9529 72.7818 38.8846C72.3123 38.7352 71.9071 38.4317 71.6319 38.0235C71.4688 37.8209 71.3572 37.582 71.3067 37.327C71.2502 37.2188 71.2502 37.054 71.1984 36.9505V34.9599H76.4105C77.1952 34.9796 77.9558 34.6886 78.5265 34.1505C79.116 33.6067 79.4666 32.8524 79.502 32.0517V31.6752C79.4773 30.8789 79.1385 30.1245 78.5595 29.5764C78.2803 29.2931 77.9461 29.0697 77.5774 28.92C77.2087 28.7703 76.8132 28.6974 76.4152 28.7058ZM77.6075 33.4399C77.3228 33.6385 76.9784 33.7332 76.632 33.7082H71.2031V31.407C71.2475 31.0909 71.3779 30.7931 71.5801 30.5458C71.743 30.3474 71.9472 30.1868 72.1786 30.0752C72.4169 29.9676 72.6758 29.913 72.9373 29.9152H76.632C76.8754 29.913 77.1159 29.9678 77.3342 30.0752C77.5553 30.1872 77.7573 30.3333 77.9327 30.5082C78.1383 30.814 78.269 31.1638 78.3144 31.5293C78.3607 31.8921 78.3236 32.2606 78.2061 32.607C78.1024 32.946 77.8927 33.2429 77.6075 33.454V33.4399Z"
          fill="#25303E"
        />
        <path
          d="M148.298 38.6119C148.297 38.9133 148.206 39.2076 148.038 39.4577C147.87 39.7078 147.631 39.9025 147.352 40.0172C147.073 40.1319 146.766 40.1614 146.47 40.1021C146.174 40.0427 145.903 39.8971 145.689 39.6837C145.476 39.4703 145.331 39.1986 145.273 38.903C145.214 38.6073 145.245 38.301 145.361 38.0227C145.476 37.7443 145.672 37.5065 145.923 37.3392C146.174 37.1718 146.469 37.0825 146.771 37.0825C146.972 37.0825 147.171 37.1221 147.356 37.199C147.542 37.2759 147.71 37.3887 147.852 37.5308C147.994 37.6728 148.106 37.8415 148.183 38.027C148.259 38.2125 148.298 38.4113 148.298 38.6119Z"
          fill="#25303E"
        />
        <path
          d="M26.0654 32.7012L30.4387 40.3482L26.0654 48H17.3706L13.002 40.3482L17.3706 32.7012H26.0654Z"
          fill="#FFB000"
        />
        <path
          d="M26.0654 14.24L30.4387 21.8871L26.0654 29.5388H17.3706L13.002 21.8871L17.3706 14.24H26.0654Z"
          fill="#64BA00"
        />
        <path
          d="M10.6741 24.7906L14.2369 31.1154L10.6741 37.4495H3.56276L0 31.1154L3.56276 24.7906H10.6741Z"
          fill="#F96E10"
        />
        <path
          d="M44.9918 0L50.647 10.0141L44.9918 20.0424H33.6249L27.9697 10.0141L33.6249 0H44.9918Z"
          fill="#0298EC"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="164" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const HoneycombLogoSquare: React.FC<React.ComponentProps<"svg">> = (
  props,
) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 107.51 102"
      {...props}
    >
      <defs>
        {/* <style>.cls-1{fill:#25303e;}.cls-2{fill:#ffb000;}.cls-3{fill:#64ba00;}.cls-4{fill:#f96e10;}.cls-5{fill:#0298ec;}</style> */}
      </defs>
      <path
        // class="cls-1"
        fill="#25303e"
        d="M87,61.37H80.45a6.45,6.45,0,0,0-2.88.57,6.15,6.15,0,0,0-2.19,1.26V52.79H72.73V85H75.5V70.17a6.52,6.52,0,0,1,1.84-4.46,6.34,6.34,0,0,1,4.38-1.83h3.92A6.14,6.14,0,0,1,90,65.71a6.3,6.3,0,0,1,1.84,4.46V84.92h2.77v-16a7.21,7.21,0,0,0-2.31-5.37A6.81,6.81,0,0,0,87,61.37Z"
      />
      <path
        // class="cls-2"
        fill="#ffb000"
        d="M55.31,69.49l9.28,16.25L55.31,102H36.86L27.59,85.74l9.27-16.25Z"
      />
      <path
        // class="cls-3"
        fill="#64ba00"
        d="M55.31,30.26l9.28,16.25L55.31,62.77H36.86L27.59,46.51l9.27-16.25Z"
      />
      <path
        // class="cls-4"
        fill="#f96e10"
        d="M22.65,52.68l7.56,13.44L22.65,79.58H7.56L0,66.12,7.56,52.68Z"
      />
      <path
        // class="cls-5"
        fill="#0298ec"
        d="M95.47,0l12,21.28-12,21.31H71.35l-12-21.31L71.35,0Z"
      />
    </svg>
  );
};
