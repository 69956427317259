import { useSnackbar } from "notistack";
import { memo, useMemo } from "react";
import { ShowPageConfig } from ".";
import {
  DialogResource,
  NewResourceDialog,
} from "../../../components/ResourceDialog";
import {
  DestinationType,
  Kind,
  ProcessorType,
  SourceType,
  useDestinationsAndTypesQuery,
} from "../../../graphql/generated";
import { UpdateStatus } from "../../../types/resources";
import { BPConfiguration, BPDestination } from "../../../utils/classes";
import { existingResourcesNotInConfiguration } from "../../../utils/resources";
import { applyResources } from "../../../utils/rest/apply-resources";

type ResourceType = SourceType | DestinationType | ProcessorType;

interface AddDestinationsComponentProps {
  configuration: NonNullable<ShowPageConfig>;
  refetchConfiguration: () => void;
  setAddDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  addDialogOpen: boolean;
}

const AddDestinationsComponent: React.FC<AddDestinationsComponentProps> = ({
  configuration,
  refetchConfiguration,
  setAddDialogOpen,
  addDialogOpen,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, refetch: refetchDestinationsAndTypes } =
    useDestinationsAndTypesQuery({
      fetchPolicy: "network-only",
      onError() {
        enqueueSnackbar(
          "There was an error retrieving Destinations and DestinationTypes.",
          {
            variant: "error",
          },
        );
      },
    });

  async function onNewDestinationSave(
    values: { [key: string]: any },
    destinationType: ResourceType,
  ) {
    if (configuration == null) {
      console.error(
        "cannot save destination, current configuration is null or undefined.",
      );
      return;
    }

    const destination = new BPDestination({
      metadata: {
        name: values.name,
        id: values.name,
        version: 0,
      },
      spec: {
        parameters: [],
        type: destinationType.metadata.name,
        disabled: false,
      },
    });

    destination.setParamsFromMap(values);

    const updatedConfiguration = new BPConfiguration(configuration);
    updatedConfiguration.addDestination({
      name: destination.name(),
      disabled: destination.spec.disabled,
    });

    try {
      const { updates } = await applyResources([
        destination,
        updatedConfiguration,
      ]);

      const destinationUpdate = updates.find(
        (u) =>
          u.resource.metadata.id === destination.metadata.name &&
          u.resource.kind === Kind.Destination &&
          u.resource.metadata.name === destination.name(),
      );

      if (destinationUpdate == null) {
        throw new Error(
          `failed to create destination, no update returned with name ${values.name}`,
        );
      }

      if (destinationUpdate.status !== UpdateStatus.CREATED) {
        throw new Error(
          `failed to create destination, got update status ${destinationUpdate.status}`,
        );
      }

      const configurationUpdate = updates.find(
        (u) =>
          u.resource.metadata.id === updatedConfiguration.metadata.id &&
          u.resource.kind === Kind.Configuration &&
          u.resource.metadata.name === updatedConfiguration.name(),
      );

      if (configurationUpdate == null) {
        throw new Error(
          `failed to update configuration, no update returned with name ${values.name}`,
        );
      }

      if (configurationUpdate.status !== UpdateStatus.CONFIGURED) {
        throw new Error(
          `failed to update configuration, got update status ${configurationUpdate.status}`,
        );
      }

      setAddDialogOpen(false);
      enqueueSnackbar(`Created destination ${destination.name()}!`, {
        variant: "success",
      });
      refetchConfiguration();
      refetchDestinationsAndTypes();
    } catch (err) {
      enqueueSnackbar("Failed to create destination.", { variant: "error" });
      console.error(err);
    }
  }

  async function addExistingDestination(existingDestination: DialogResource) {
    const config = new BPConfiguration(configuration);
    config.addDestination({
      name: existingDestination.metadata.name,
      disabled: existingDestination.spec.disabled ?? false,
    });

    try {
      const update = await config.apply();
      if (update.status === UpdateStatus.INVALID) {
        console.error(update);
        throw new Error(
          `failed to update resource, got status ${update.status}`,
        );
      }

      setAddDialogOpen(false);
      refetchConfiguration();
    } catch (err) {
      enqueueSnackbar("Failed to add destination.", { variant: "error" });
    }
  }
  const unusedExistingDestinations = useMemo(() => {
    return existingResourcesNotInConfiguration(
      data?.destinations ?? [],
      configuration.spec.destinations ?? [],
    );
  }, [data?.destinations, configuration.spec.destinations]);

  return (
    <NewResourceDialog
      platform={configuration.metadata.labels?.platform ?? "unknown"}
      kind={Kind.Destination}
      resources={unusedExistingDestinations}
      existingResourceNames={
        data?.destinations.map((d) => d.metadata.name) ?? []
      }
      resourceTypes={data?.destinationTypes ?? []}
      open={addDialogOpen}
      onSaveNew={onNewDestinationSave}
      onSaveExisting={addExistingDestination}
      onClose={() => setAddDialogOpen(false)}
    />
  );
};

export const AddDestinationsSection = memo(AddDestinationsComponent);
