import {
  Card,
  CardActionArea,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { Handle, Position } from "reactflow";
import { Role } from "../../graphql/generated";
import { useRole } from "../../hooks/useRole";
import colors from "../../styles/colors";
import { hasPermission } from "../../utils/has-permission";
import { classes } from "../../utils/styles";
import { PlusCircleIcon } from "../Icons";
import { usePipelineGraph } from "../PipelineGraph/PipelineGraphContext";
import styles from "./cards.module.scss";

interface AddResourceCardProps {
  onClick: () => void;
  buttonText: string;
  dataTestId?: string;
}

export const AddResourceCard: React.FC<AddResourceCardProps> = ({
  onClick,
  buttonText,
  dataTestId,
}) => {
  const { readOnlyGraph } = usePipelineGraph();
  const role = useRole();

  const canEdit = hasPermission(Role.User, role) && !readOnlyGraph;

  const classNames = classes([
    canEdit ? styles["resource-card"] : styles["resource-card-no-hover"],
  ]);

  return (
    <Card className={classNames} onClick={canEdit ? onClick : () => {}}>
      <CardActionArea
        className={classes([styles.action])}
        style={{
          cursor: canEdit ? "pointer" : "default",
        }}
        disabled={!canEdit}
      >
        <CardContent data-testid={dataTestId}>
          <Handle type="source" position={Position.Right} />
          <Handle type="target" position={Position.Left} />
          <Stack
            spacing={0}
            width="100%"
            height="100%"
            justifyContent={"center"}
            alignItems={"center"}
            paddingTop={1}
          >
            <PlusCircleIcon
              stroke={colors.pixelPointBlue}
              width={30}
              height={30}
            />
            <Typography
              paddingTop={1}
              className={classes([styles["add-title"]])}
            >
              {buttonText}
            </Typography>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
