import { gql } from "@apollo/client";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Popover,
  PopoverProps,
  Stack,
} from "@mui/material";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import {
  LabelAgentsInput,
  Suggestion,
  useGetLabelSuggestionsQuery,
  usePatchLabelsMutation,
} from "../../graphql/generated";
import { AGENT_LABEL_INSTALL_ID } from "../../pages/agents/InstallPage/InstallWizard/utils";
import { SearchBar } from "../SearchBar";
import { isCustomLabelKey } from "../Tables/utils";
import styles from "./label-editor.module.scss";

gql`
  query getLabelSuggestions($query: String!) {
    labelSuggestions(query: $query) {
      query
      suggestions {
        label
        query
      }
    }
  }

  mutation PatchLabels($input: LabelAgentsInput!) {
    labelAgents(input: $input)
  }
`;

export type LabelTuple = [string, string];

interface LabelEditorProps {
  // The agent IDs on which we're editing.
  agentIds: string[];
  existingLabels: LabelTuple[];
  // popoverProps are the props for the MUI Popover
  popoverProps: PopoverProps;
  onClose: () => void;
  onSave: () => void;
}

export const LabelEditor: React.FC<LabelEditorProps> = ({
  agentIds,
  popoverProps,
  existingLabels,
  onSave,
  onClose,
}) => {
  const [labels, setLabels] = useState<LabelTuple[] | null>(null);
  const [addLabels, setAddLabels] = useState<LabelTuple[]>([]);
  const [removeLabels, setRemoveLabels] = useState<LabelTuple[]>([]);

  const [suggestionsQuery, setSuggestionsQuery] = useState<string>("");
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);

  useEffect(() => {
    if (labels === null && popoverProps.open) {
      setLabels(existingLabels);
    }
  }, [existingLabels, labels, popoverProps.open]);

  const { refetch } = useGetLabelSuggestionsQuery({
    variables: { query: suggestionsQuery },
    onCompleted: (data) => {
      setSuggestions(data.labelSuggestions.suggestions);
      setSuggestionsQuery(data.labelSuggestions.query);
    },
  });

  const [patchLabels] = usePatchLabelsMutation();

  function handleSearchBarChange(query: string) {
    refetch({ query });
  }

  function handleValueSelect(v: string) {
    const [key, value] = v.split(":");
    // Don't set the configuration label
    if (key === "configuration") return;

    // Filter out any existing label with the same key
    let newLabels = [...(labels ?? [])];
    newLabels = newLabels.filter(([k, _]) => k !== key);
    newLabels.push([key, value]);
    setLabels(newLabels);

    // Remove any add label with the same key
    let newAddLabels = [...addLabels];
    newAddLabels = newAddLabels.filter(([k, _]) => k !== key);
    newAddLabels.push([key, value]);
    setAddLabels(newAddLabels);

    // Remove any remove label with the same key:value
    let newRemoveLabels = [...removeLabels];
    newRemoveLabels = newRemoveLabels.filter(
      ([k, v]) => `${k}:${v}` !== `${key}:${value}`,
    );
    setRemoveLabels(newRemoveLabels);
  }

  function handleDeleteLabel(index: number) {
    if (labels == null) return;

    const [key, value] = labels[index];

    // Remove the label from the addLabels array if present
    let newAddLabels = [...addLabels];
    newAddLabels = newAddLabels.filter(
      ([k, v]) => `${k}:${v}` !== `${key}:${value}`,
    );
    setAddLabels(newAddLabels);

    // If the remove label was in the existing labels, we need to
    // add it to the removeLabels.  If not we do not need to.
    if (existingLabels.some(([k, v]) => k === key && v === value)) {
      let newRemoveLabels = [...removeLabels, [key, value] as LabelTuple];
      setRemoveLabels(newRemoveLabels);
    }

    const newLabels = [...labels];
    newLabels.splice(index, 1);
    setLabels(newLabels);
  }

  function handleClose() {
    onClose();
  }

  async function handleSave() {
    if (labels === null) return;

    const addLabelsArray = addLabels.map(([k, v]) => ({ key: k, value: v }));
    const removeLabelsArray = removeLabels.map(([k, v]) => ({
      key: k,
      value: v,
    }));

    const input: LabelAgentsInput = {
      agentIDs: agentIds,
      addLabels: addLabelsArray,
      removeLabels: removeLabelsArray,
    };

    await patchLabels({ variables: { input } });
    onSave();
    onClose();
  }

  function clearState() {
    setLabels(null);
    setAddLabels([]);
    setRemoveLabels([]);
    setSuggestionsQuery("");
    setSuggestions([]);
  }

  return (
    <Popover
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      TransitionProps={{ onExited: clearState }}
      onClose={onClose}
      {...popoverProps}
    >
      <Card className={styles.card}>
        <CardContent>
          <Stack spacing={2}>
            {!isEmpty(labels) && (
              <div>
                {labels?.map(([k, v], ix) => {
                  if (!isCustomLabelKey(k)) return null;
                  if (k === AGENT_LABEL_INSTALL_ID) return null;
                  if (v === "") return null;

                  const deleteFn =
                    k === "configuration"
                      ? undefined
                      : () => handleDeleteLabel(ix);

                  const formattedLabel = `${k}: ${v}`;
                  return (
                    <Chip
                      key={formattedLabel}
                      data-testid={`label-chip-${k}:${v}`}
                      size="small"
                      label={formattedLabel}
                      style={{ margin: "0 4px 4px 0" }}
                      onDelete={deleteFn}
                    />
                  );
                })}
              </div>
            )}
            <SearchBar
              suggestions={suggestions.filter(filterSuggestionsFn)}
              suggestionQuery={suggestionsQuery}
              placeholder="Add a label"
              onQueryChange={handleSearchBarChange}
              onValueSelect={handleValueSelect}
              clearOnValueSelect
              limitOneToken
              validateAsLabel
              autofocus
            />
          </Stack>
        </CardContent>
        <CardActions>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            width="100%"
            spacing={2}
          >
            <Button variant="outlined" color="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleSave}
              disabled={addLabels.length + removeLabels.length === 0}
            >
              Save
            </Button>
          </Stack>
        </CardActions>
      </Card>
    </Popover>
  );
};

export function filterSuggestionsFn(suggestion: Suggestion) {
  if (!isCustomLabelKey(suggestion.label)) {
    return false;
  }

  if (suggestion.label === "configuration:") {
    return false;
  }

  if (suggestion.label === "install_id:") {
    return false;
  }

  return true;
}
