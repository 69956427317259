import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import React from "react";
import { Kind } from "../../../graphql/generated";
import { deleteResources } from "../../../utils/rest/delete-resources";

interface DeleteModalProps {
  open: boolean;
  selected: GridRowSelectionModel;
  onClose: () => void;
}

export const DeleteDialog: React.FC<DeleteModalProps> = ({
  open,
  selected,
  onClose,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  async function onDelete() {
    const resources = selected.map((name) => ({
      kind: Kind.Configuration,
      metadata: {
        // GridRowId can be string | number, in this case string
        name: name as string,
      },
    }));
    try {
      await deleteResources(resources);
      onClose();
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed delete configurations", {
        variant: "error",
      });
    }
  }
  return (
    <Dialog open={open} onClose={onClose} data-testid="delete-dialog">
      <DialogTitle>
        Delete {selected.length} Configuration{selected.length > 1 && "s"}?
      </DialogTitle>

      <DialogContent>
        <Typography>
          Deleting a configuration will remove it from bindplane, however any
          agents currently using it will continue to do so.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="error" onClick={onDelete}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
