import { Alert } from "@mui/material";
import { useMemo, useRef } from "react";
import { classes } from "../../utils/styles";
import styles from "./EditingControlBar.module.scss";

interface EditingControlBarProps {
  editingState: EditingState;
  setEditingState: (state: EditingState) => void;
  bottomMargin?: boolean;
}

export type EditingState =
  | "draft"
  | "live"
  | "discarding"
  | "pastCompleted"
  | "justCompleted";

/**
 * EditingControlBar will quickly slide in when displayed, rendering any children as it grows
 * @param editingState Used to manage render state for this component
 * @param setEditingState Used to manage render state for this component
 * @param bottomMargin Used to provide spacing on Otel configs
 */
export const EditingControlBar: React.FC<EditingControlBarProps> = ({
  children,
  editingState,
  setEditingState,
  bottomMargin,
}) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  function cancelTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }

  let boxStyles = useMemo(() => {
    function startHideCountdown(ms: number) {
      timeoutRef.current = setTimeout(() => {
        setEditingState("pastCompleted");
      }, ms);
    }
    const classes = [styles["editing-control-bar"]];
    if (bottomMargin) {
      classes.push(styles["bottom-margin"]);
    }

    cancelTimeout();

    switch (editingState) {
      case "draft": {
        classes.push(styles["ease-in"]);
        break;
      }

      case "live": {
        classes.push(styles["ease-out"]);
        startHideCountdown(1000);
        break;
      }

      case "discarding": {
        classes.push(styles["ease-out"]);
        startHideCountdown(1000);
        break;
      }

      case "justCompleted": {
        classes.push(styles["ease-out-slowly"]);
        startHideCountdown(4000);
        break;
      }

      case "pastCompleted": {
        classes.push(styles.hidden);
        break;
      }
    }

    return classes;
  }, [bottomMargin, editingState, setEditingState]);

  return (
    <Alert
      className={classes(boxStyles)}
      icon={false}
      sx={{
        "& .MuiAlert-message": {
          width: "100%",
          padding: "3px 0",
        },
      }}
    >
      {children}
    </Alert>
  );
};
