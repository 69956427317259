import { Button, Chip } from "@mui/material";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { isFunction } from "lodash";
import { ComponentProps } from "react";
import { AgentStatus } from "../../types/agents";
import { formatMetric } from "../../utils/graph/utils";
import { trimVersion, resourceVersion } from "../../utils/version-helpers";
import { LabelsWithTooltip } from "../LabelsWithTooltip/LabelsWithTooltip";
import {
  TELEMETRY_TYPES,
  TELEMETRY_SIZE_METRICS,
  DEFAULT_AGENTS_TABLE_QUERY_PERIOD,
} from "../MeasurementControlBar/MeasurementControlBar";
import { SearchLink } from "../SearchLink";
import { TableAgent, AgentMetrics, AgentsTableField } from "./types";

function renderConfigurationCell(cellParams: GridCellParams<any, string>) {
  const configName = cellParams.value;
  if (configName == null) {
    return <></>;
  }
  return (
    <SearchLink
      path={`/configurations/${configName}`}
      displayName={configName}
    />
  );
}

function renderNameDataCell(
  cellParams: GridCellParams<{ name: string; id: string }, TableAgent>,
): JSX.Element {
  return (
    <SearchLink
      path={`/agents/${cellParams.row.id}`}
      displayName={cellParams.row.name}
    />
  );
}

function renderLabelDataCell(
  cellParams: GridCellParams<any, Record<string, string>>,
): JSX.Element {
  return renderAgentLabels(cellParams.value);
}

function renderStatusDataCell(
  cellParams: GridCellParams<any, AgentStatus>,
): JSX.Element {
  return renderAgentStatus(cellParams.value);
}

function createMetricRateColumn(
  field: string,
  telemetryType: string,
  width: number,
  agentMetrics?: AgentMetrics,
): GridColDef<TableAgent>[][0] {
  return {
    field,
    width: width,
    headerName: TELEMETRY_TYPES[telemetryType],
    valueGetter: (_, row) => {
      if (agentMetrics == null) {
        return 0;
      }
      // should probably have a lookup table here rather than interpolate in two places
      const metricName = TELEMETRY_SIZE_METRICS[telemetryType];
      const agentName = row.id;

      // get all metrics for this agent that match the pattern /^destination\/\w+$/
      // those are metrics for data received by a destination, ignoring values before the processors
      const metrics = agentMetrics.filter(
        (m) =>
          m.name === metricName &&
          m.agentID! === agentName &&
          m.nodeID.startsWith("destination/") &&
          !m.nodeID.endsWith("/processors"),
      );
      if (metrics == null) {
        return 0;
      }
      // to make this sortable, we use the raw value and provide a valueFormatter implementation to show units
      return metrics.reduce((a, b) => a + b.value, 0);
    },
    valueFormatter: (value: number, row): string => {
      if (value === 0) {
        return "";
      }

      const metricName = TELEMETRY_SIZE_METRICS[telemetryType];
      const agentName = row.id;

      const metrics = agentMetrics?.find(
        (m) => m.name === metricName && m.agentID! === agentName,
      );
      return formatMetric(
        { value: value, unit: metrics?.unit || "B/s" },
        DEFAULT_AGENTS_TABLE_QUERY_PERIOD,
      );
    },
  };
}

function renderAgentLabels(
  labels: Record<string, string> | undefined,
): JSX.Element {
  return <LabelsWithTooltip labels={labels} />;
}

function renderAgentStatus(status: AgentStatus | undefined): JSX.Element {
  let statusText: string;
  let color: ComponentProps<typeof Chip>["color"] = "default";

  switch (status) {
    case AgentStatus.DISCONNECTED:
      statusText = "Disconnected";
      break;
    case AgentStatus.CONNECTED:
      statusText = "Connected";
      color = "success";
      break;
    case AgentStatus.ERROR:
      statusText = "Errored";
      color = "error";
      break;
    // Component failed is deprecated.
    case AgentStatus.COMPONENT_FAILED:
      statusText = "Component Failed";
      break;
    case AgentStatus.DELETED:
      statusText = "Deleted";
      break;
    case AgentStatus.CONFIGURING:
      statusText = "Configuring";
      break;
    case AgentStatus.UPGRADING:
      statusText = "Upgrading";
      color = "warning";
      break;
    default:
      statusText = "";
      break;
  }

  return <Chip size="small" color={color} label={statusText} />;
}

type nameFieldRenderer = (
  params: GridCellParams<{ name: string; id: string }, TableAgent>,
) => JSX.Element;

export function makeAgentsTableColumns(
  fields: AgentsTableField[],
  agentMetrics: AgentMetrics,
  onAgentClick?: (agentID: string, agentName: string) => void,
): GridColDef<TableAgent>[] {
  // If onAgentClick is provided, render the name cell with a button that calls onAgentClick
  var renderNameDataCellFunc: nameFieldRenderer;

  if (isFunction(onAgentClick)) {
    renderNameDataCellFunc = (
      cellParams: GridCellParams<{ name: string; id: string }, TableAgent>,
    ) => {
      return (
        <Button
          onClick={() => onAgentClick(cellParams.row.id, cellParams.row.name)}
        >
          {cellParams.row.name}
        </Button>
      );
    };
  } else {
    renderNameDataCellFunc = renderNameDataCell;
  }

  return fields.map((field) => {
    switch (field) {
      case AgentsTableField.STATUS:
        return {
          field: AgentsTableField.STATUS,
          headerName: "Status",
          width: 150,
          renderCell: renderStatusDataCell,
        };
      case AgentsTableField.VERSION:
        return {
          field: AgentsTableField.VERSION,
          headerName: "Version",
          width: 100,
        };
      case AgentsTableField.CONFIGURATION:
        return {
          field: AgentsTableField.CONFIGURATION,
          headerName: "Configuration",
          width: 200,
          renderCell: renderConfigurationCell,
          valueGetter: (_, row) => {
            const configuration = row.configurationStatus.current;
            return trimVersion(configuration);
          },
        };
      case AgentsTableField.OPERATING_SYSTEM:
        return {
          field: AgentsTableField.OPERATING_SYSTEM,
          headerName: "Operating System",
          width: 200,
        };
      case AgentsTableField.LABELS:
        return {
          field: AgentsTableField.LABELS,
          headerName: "Labels",
          flex: 1,
          renderCell: renderLabelDataCell,
        };
      case AgentsTableField.LOGS:
        return createMetricRateColumn(field, "logs", 100, agentMetrics);
      case AgentsTableField.METRICS:
        return createMetricRateColumn(field, "metrics", 100, agentMetrics);
      case AgentsTableField.TRACES:
        return createMetricRateColumn(field, "traces", 100, agentMetrics);
      case AgentsTableField.CONFIGURATION_VERSION:
        return {
          sortable: true,
          field: AgentsTableField.CONFIGURATION_VERSION,
          headerName: "Configuration Version",
          width: 200,
          valueGetter: (_, row) => {
            const configurationCurrent = row.configurationStatus.current;
            const configurationVersion = resourceVersion(configurationCurrent);
            if (configurationVersion === "") {
              return "-";
            }

            const configurationName = trimVersion(configurationCurrent);
            const configurationLabel = row.labels?.configuration;

            const matches = configurationName === configurationLabel;
            return matches ? configurationVersion : "-";
          },
        };
      default:
        return {
          field: AgentsTableField.NAME,
          headerName: "Name",
          renderCell: renderNameDataCellFunc,
          width: 240,
        };
    }
  });
}
