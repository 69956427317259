import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { cloneDeep, escapeRegExp, uniq } from "lodash";
import { memo, useState } from "react";
import { ParameterDefinition, ParameterType } from "../../../graphql/generated";
import colors from "../../../styles/colors";
import { ChevronUp, EditIcon, TrashIcon } from "../../Icons";
import { LinkButton } from "../../LinkButton";
import { useResourceFormValues } from "../ResourceFormContext";
import { useValidationContext } from "../ValidationContext";
import {
  validateConditionField,
  validateStringField,
} from "../validation-functions";
import { OTTLFieldInput, getFieldValueOptions } from "./OTTLFieldInput";
import { ParamInputProps } from "./ParameterInput";
import { parameterErrors, parameterHelperText } from "./utils";
import styles from "../../EEProcessorDialog/ee-processor-dialog.module.scss";

// TODO: Implement variants
// TODO: Add Metrics, Logs, and Traces contexts to match metric.name, metric.unit, etc. fields
// TODO: Padding on the center column floats the scrollbar inside the padding
// TODO: NOT expressions using submenu
// TODO: No trash cans if readOnly

export interface ConditionInputValue {
  ottl: string;

  // The UI ConditionStatement will have an operator of "AND" or "OR" or "" (implied AND)
  // and a list of statements. match, key, and value fields will be set on the leaf nodes.
  ui?: ConditionStatement;
}

export interface ConditionStatement {
  operator: string;
  match?: string;
  key?: string;
  value?: string;
  statements?: ConditionStatement[];
}

export enum ConditionMatch {
  BODY = "body",
  ATTRIBUTES = "attributes",
  RESOURCE = "resource",
  CUSTOM = "custom",

  // telemetry specific fields
  DATAPOINT = "datapoint",
  METRIC = "metric",
  LOG = "log",
  SPAN = "span",
}

// The variant of the condition input, button by default
enum ConditionVariant {
  EXPANDED = "expanded",
  COLLAPSED = "collapsed",
  BUTTON = "button",
}
const defaultVariant = ConditionVariant.BUTTON;

export function defaultStatement(): ConditionStatement {
  return {
    match: ConditionMatch.ATTRIBUTES,
    key: "",
    operator: "Equals",
    value: "",
  };
}

function defaultRaw(ottl: string): ConditionInputValue {
  return { ottl };
}

function defaultValue(): ConditionInputValue {
  return {
    ottl: "",
    ui: {
      operator: "",
      statements: [defaultStatement()],
    },
  };
}

const ConditionInputComponent: React.FC<
  ParamInputProps<ConditionInputValue | string>
> = ({ definition, value, readOnly, onValueChange }) => {
  const { errors, setError, touched, touch } = useValidationContext();

  const condition = new Condition(value);
  let variant = definition.options.variant ?? defaultVariant;
  if (variant === "") {
    variant = defaultVariant;
  }

  const [expanded, setExpanded] = useState<boolean>(
    variant === ConditionVariant.EXPANDED,
  );
  const [showAddButton, setShowAddButton] = useState<boolean>(
    condition.isEmpty() && !expanded,
  );

  function setValue(value: ConditionInputValue | string) {
    setError(definition.name, validateConditionField(definition, value));
    onValueChange && onValueChange(value);
  }

  // handles changes to the raw OTTL expression when the builder is not used
  function handleRawOttlChange(newValue: string) {
    if (!touched[definition.name]) {
      touch(definition.name);
    }

    setError(
      definition.name,
      validateStringField(newValue, definition.required),
    );

    setValue({ ottl: newValue });
  }

  // generic change handler for a statement
  function handleChangeStatement(
    path: ConditionPath,
    updater: (statement: ConditionStatement) => {},
  ) {
    if (condition.updateStatement(path, updater)) {
      setValue(condition.value());
    }
  }

  // handles the trash icon
  function handleDeleteStatement(path: ConditionPath) {
    if (condition.deleteStatement(path)) {
      if (condition.isEmpty() && variant === ConditionVariant.BUTTON) {
        setShowAddButton(true);
        setExpanded(false);
      }
      setValue(condition.value());
    }
  }

  // handles the "Add Condition" link
  function handleAddGroup(path: ConditionPath) {
    if (condition.addGroup(path)) {
      setValue(condition.value());
    }
  }

  // handles the "Add Statement" link
  function handleAddStatement(path: ConditionPath) {
    if (condition.addStatement(path)) {
      setValue(condition.value());
    }
  }

  /**
   * Recursively renders the UI for a statement and its children.
   */
  function renderStatement(
    statement: ConditionStatement,
    path: ConditionPath,
  ): React.ReactElement {
    // OR and AND operators
    if (hasBinaryOperator(statement)) {
      return (
        <StatementBinary
          definitionName={definition.name}
          statement={statement}
          path={path}
          readOnly={!!readOnly}
          renderStatement={renderStatement}
          onOperatorChange={(newValue) => {
            return handleChangeStatement(path, (s) => (s.operator = newValue));
          }}
        />
      );
    }

    // a list of statements
    if (statement.statements) {
      return (
        <StatementList
          definitionName={definition.name}
          statement={statement}
          path={path}
          renderStatement={renderStatement}
          onAddGroup={
            path.length() < 3 ? () => handleAddGroup(path) : undefined
          }
          onAddStatement={() => handleAddStatement(path)}
        />
      );
    }

    // a single statement
    return (
      <StatementLeaf
        definitionName={definition.name}
        statement={statement}
        path={path}
        condition={condition}
        readOnly={!!readOnly}
        onMatchChange={(newValue) =>
          handleChangeStatement(path, (s) => (s.match = newValue))
        }
        onFieldChange={(newValue) =>
          handleChangeStatement(path, (s) => (s.key = newValue))
        }
        onOperatorChange={(newValue) =>
          handleChangeStatement(path, (s) => (s.operator = newValue))
        }
        onValueChange={(newValue) =>
          handleChangeStatement(path, (s) => (s.value = newValue))
        }
        onDelete={() => handleDeleteStatement(path)}
      />
    );
  }

  if (condition.hasBuilderUI()) {
    return (
      <>
        <Typography>{definition.label}</Typography>
        <Typography
          fontSize="0.75rem"
          marginBottom="8px"
          color={readOnly ? colors.disabled : undefined}
        >
          {definition.description}
        </Typography>
        <Stack
          className={styles["condition-input"]}
          key={`${definition.name}-container`}
          direction="column"
        >
          {showAddButton ? (
            <>
              <AddButton
                onClick={() => {
                  setShowAddButton(false);
                  setExpanded(true);
                  setValue(defaultValue());
                }}
              />
              {parameterErrors(definition, errors, touched)}
            </>
          ) : (
            <Accordion
              sx={{
                border: 0,
              }}
              expanded={expanded}
              disableGutters
            >
              <AccordionSummary
                sx={{
                  padding: 0,
                }}
              >
                <Stack
                  direction="row"
                  justifyContent={"center"}
                  alignItems={"center"}
                  spacing={0.5}
                  width={"100%"}
                >
                  <Box flexGrow={1}>
                    <ConditionOttlExpression
                      required={definition.required}
                      value={condition.ottl()}
                      readOnly={true}
                      helperText={parameterErrors(definition, errors, touched)}
                    />
                  </Box>
                  <IconButton
                    size={"small"}
                    onClick={() => setExpanded(!expanded)}
                    sx={{
                      width: "24px",
                      height: "24px",
                    }}
                  >
                    {expanded ? <ChevronUp /> : <EditIcon />}
                  </IconButton>
                </Stack>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  padding: 0,
                }}
              >
                {renderStatement(condition.root(), new ConditionPath())}
              </AccordionDetails>
            </Accordion>
          )}
        </Stack>
      </>
    );
  }
  return (
    <Stack key={`${definition.name}-container`} direction="column" spacing={2}>
      <Typography>{definition.label}</Typography>
      <ConditionOttlExpression
        required={definition.required}
        value={condition.ottl()}
        readOnly={!!readOnly}
        onChange={handleRawOttlChange}
        helperText={parameterHelperText(definition, errors, touched)}
      />
    </Stack>
  );
};

export const ConditionInput = memo(ConditionInputComponent);

// ----------------------------------------------------------------------
// statement components

interface StatementRenderer {
  (statement: ConditionStatement, path: ConditionPath): React.ReactElement;
}

// A statement with a binary operator (AND or OR) and 2 child statements.
const StatementBinary: React.FC<{
  definitionName: string;
  statement: ConditionStatement;
  path: ConditionPath;
  readOnly: boolean;
  renderStatement: StatementRenderer;
  onOperatorChange: (newOperator: string) => void;
}> = ({
  definitionName,
  statement,
  path,
  readOnly,
  renderStatement,
  onOperatorChange,
}) => {
  // Renders a child statement of a binary operator, recursively rendering its children.
  function childStatement(childIndex: number): React.ReactElement {
    const childPath = path.childPath(childIndex);
    return (
      <Stack
        key={childPath.key(definitionName)}
        direction="column"
        spacing={1}
        className={styles["condition-statement"]}
      >
        {renderStatement(statement.statements![childIndex], childPath)}
      </Stack>
    );
  }

  // should have 2 statements representing either part of the conjunction
  return (
    <Stack
      className={styles["condition-group"]}
      direction="column"
      spacing={1}
      key={path.key(definitionName, "group")}
    >
      {childStatement(0)}
      <Stack
        direction="row"
        spacing={1}
        justifyContent={"flex-start"}
        className={styles["condition-group-operator-container"]}
        width={"40%"}
      >
        <ConditionBinaryOperator
          value={statement.operator}
          readOnly={!!readOnly}
          onChange={onOperatorChange}
        />
      </Stack>
      {childStatement(1)}
    </Stack>
  );
};

// a list of statements typically joined with implied AND operator
const StatementList: React.FC<{
  definitionName: string;
  statement: ConditionStatement;
  path: ConditionPath;
  renderStatement: StatementRenderer;
  onAddGroup?: () => void;
  onAddStatement: () => void;
}> = ({
  definitionName,
  statement,
  path,
  renderStatement,
  onAddGroup,
  onAddStatement,
}) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      key={path.key(definitionName, "statements")}
    >
      <Stack direction="column" spacing={0} alignItems={"stretch"} flexGrow={1}>
        <Stack direction="column" spacing={2} alignItems={"stretch"}>
          {statement.statements!.map((s, i) => {
            const childPath = path.childPath(i);
            return (
              // box added to avoid the key warning, but doesn't seem necessary since
              // renderStatement elements have their own keys.
              <Box key={childPath.key(definitionName, `statement`)}>
                {renderStatement(s, childPath)}
              </Box>
            );
          })}
        </Stack>
        <ConditionButtons
          onAddGroup={onAddGroup}
          onAddStatement={onAddStatement}
        />
      </Stack>
    </Stack>
  );
};

// A single statement, with a Match selector and typically with Field, Operator, and
// Value. Some operators do not have a Value. Custom statements are only a Value.
const StatementLeaf: React.FC<{
  definitionName: string;
  statement: ConditionStatement;
  path: ConditionPath;
  condition: Condition;
  readOnly: boolean;
  onMatchChange: (newMatch: string) => void;
  onFieldChange: (newField: string) => void;
  onOperatorChange: (newOperator: string) => void;
  onValueChange: (newValue: string) => void;
  onDelete: () => void;
}> = ({
  definitionName,
  statement,
  path,
  condition,
  readOnly,
  onMatchChange,
  onFieldChange,
  onOperatorChange,
  onValueChange,
  onDelete,
}) => {
  return (
    <Stack
      key={path.key(definitionName, "statement")}
      direction="row"
      spacing={0.5}
      alignItems="start"
      justifyContent="flex-start"
    >
      <ConditionMatchSelect
        value={statement.match!}
        condition={condition}
        readOnly={readOnly}
        onChange={onMatchChange}
      />
      <StatementControls
        definitionName={definitionName}
        statement={statement}
        path={path}
        readOnly={readOnly}
        onFieldChange={onFieldChange}
        onOperatorChange={onOperatorChange}
        onValueChange={onValueChange}
      />
      <Box>
        <IconButton
          size={"small"}
          disabled={readOnly}
          onClick={onDelete}
          data-testid={path.key(definitionName, "remove-button")}
          sx={{ marginTop: "6px" }}
        >
          <TrashIcon width={18} height={18} />
        </IconButton>
      </Box>
    </Stack>
  );
};

const StatementControls: React.FC<{
  definitionName: string;
  statement: ConditionStatement;
  path: ConditionPath;
  readOnly: boolean;
  onFieldChange: (newField: string) => void;
  onOperatorChange: (newOperator: string) => void;
  onValueChange: (newValue: string) => void;
}> = ({
  definitionName,
  statement,
  path,
  readOnly,
  onFieldChange,
  onOperatorChange,
  onValueChange,
}) => {
  const op = getOperator(statement.operator);

  // custom OTTL statement
  if (statement.match === ConditionMatch.CUSTOM) {
    return (
      <ConditionValue
        label="OTTL Boolean Expression"
        value={statement.value!}
        readOnly={readOnly}
        onChange={onValueChange}
      />
    );
  }

  const field = (
    <ConditionField
      key={path.key(definitionName, "field")}
      label="Field"
      value={statement.key!}
      readOnly={readOnly}
      ottlContext={getOttlContext(statement)}
      onChange={onFieldChange}
    />
  );

  const operator = (
    <ConditionOperatorSelect
      value={statement.operator}
      readOnly={readOnly}
      onChange={onOperatorChange}
    />
  );

  // some operators do not have a value
  if (!op?.hasValue) {
    return (
      <>
        {field}
        {operator}
      </>
    );
  }

  const value = (
    <ConditionValue
      fieldKey={statement.key ?? ""}
      label={op.valueLabel ?? "Value"}
      value={statement.value!}
      readOnly={!!readOnly}
      ottlContext={getOttlContext(statement)}
      onChange={onValueChange}
    />
  );

  // some operators need more space for the value
  if (op?.twoLines) {
    return (
      <Stack direction="column" spacing={1} flexGrow={1}>
        <Stack
          direction="row"
          spacing={0.5}
          alignItems="flex-start"
          flexGrow={1}
        >
          {field}
          {operator}
        </Stack>
        <Box flexGrow={1}>{value}</Box>
      </Stack>
    );
  }

  // single line with normal field, operator, and value controls
  return (
    <Stack direction={"row"} flexGrow={1} alignItems="flex-start" spacing={0.5}>
      <Box flexGrow={2}>{field}</Box>
      <Box>{operator}</Box>
      <Box flexGrow={1}>{value}</Box>
    </Stack>
  );
};

// ----------------------------------------------------------------------
// sub-components

// AND/OR select control that appears between two statement groups
const ConditionBinaryOperator: React.FC<{
  value: string;
  readOnly: boolean;
  onChange: (newOperator: string) => void;
}> = ({ value, readOnly, onChange }) => {
  // default to AND if no value
  if (value === "") {
    value = "AND";
  }
  const binaryOperators = ["OR", "AND"];
  return (
    <TextField
      className={styles["condition-group-operator"]}
      value={value}
      size="small"
      onChange={(e) => onChange(e.target.value)}
      disabled={readOnly}
      select
      SelectProps={{ native: true }}
    >
      {binaryOperators.map((op) => (
        <option key={op} value={op}>
          {op}
        </option>
      ))}
    </TextField>
  );
};

const ConditionMatchSelect: React.FC<{
  key?: string;
  value?: string;
  condition: Condition;
  readOnly: boolean;
  onChange: (newValue: string) => void;
}> = ({ key, value, condition, readOnly, onChange }) => {
  const { formValues } = useResourceFormValues();
  const telemetry = formValues["telemetry_types"];
  const isTelemetryOnly = (value: string) => {
    return telemetry?.length === 1 && telemetry[0] === value;
  };

  const matches = {
    [ConditionMatch.BODY]: "Body",
    [ConditionMatch.ATTRIBUTES]: "Attributes",
    [ConditionMatch.RESOURCE]: "Resource",
    [ConditionMatch.CUSTOM]: "Custom",
    [ConditionMatch.DATAPOINT]: "Data Point",
    [ConditionMatch.METRIC]: "Metric",
    [ConditionMatch.LOG]: "Log",
    [ConditionMatch.SPAN]: "Span",
  };

  const enabled: { [key: string]: boolean } = {
    [ConditionMatch.BODY]: true,
    [ConditionMatch.ATTRIBUTES]: true,
    [ConditionMatch.RESOURCE]: true,
    [ConditionMatch.CUSTOM]: true,
    [ConditionMatch.DATAPOINT]: isTelemetryOnly("Metrics"),
    [ConditionMatch.METRIC]: isTelemetryOnly("Metrics"),
    [ConditionMatch.LOG]: isTelemetryOnly("Logs"),
    [ConditionMatch.SPAN]: isTelemetryOnly("Traces"),
  };

  return (
    <TextField
      label="Match"
      key={key}
      value={value ?? ConditionMatch.ATTRIBUTES}
      size="small"
      onChange={(e) => onChange(e.target.value)}
      disabled={readOnly}
      sx={{ minWidth: 120 }}
      select
      SelectProps={{ native: true }}
    >
      {Object.entries(matches).map(([value, label], i) => (
        <option key={`${key}-${i}`} value={value} disabled={!enabled[value]}>
          {label}
        </option>
      ))}
    </TextField>
  );
};

const ConditionField: React.FC<{
  label: string;
  value: string;
  readOnly: boolean;
  ottlContext: string;
  onChange: (newValue: string) => void;
}> = ({ label, value, readOnly, ottlContext, onChange }) => {
  // create a pseudo-ParameterDefinition to use with OTTLFieldInput
  const definition: ParameterDefinition = {
    name: "key",
    label,
    description: "",
    options: {
      ottlContext,
    },
    type: ParameterType.OttlField,
    required: false,
  };
  return (
    <OTTLFieldInput
      definition={definition}
      readOnly={readOnly}
      value={value}
      onValueChange={onChange}
    />
  );
};

/**
 * Renders ["key"] or ["key1"]["key2"] as appropriate for a field key. If the key already
 * has brackets and quotes, none are added.
 */
function getFieldKey(ui: ConditionStatement): string {
  if (!ui.key || ui.key === "") {
    return "";
  }

  // check if brackets already exist, e.g. ["key"]
  if (ui.key.match(/^\[.*\]$/)) {
    return ui.key;
  }

  // check if partial brackets exist, e.g. key1["key2"]
  const matches = ui.key.match(/^([^\\[]+)(\[.*\]$)/);
  if (matches) {
    return `["${matches[1]}"]${matches[2]}`;
  }

  // add brackets
  return `["${ui.key}"]`;
}

function getFieldExpr(ui: ConditionStatement): string {
  switch (ui.match) {
    case ConditionMatch.ATTRIBUTES:
      return `attributes${getFieldKey(ui)}`;

    case ConditionMatch.RESOURCE:
      return `resource.attributes${getFieldKey(ui)}`;

    case ConditionMatch.BODY:
      return `body${getFieldKey(ui)}`;

    case ConditionMatch.METRIC:
      return ui.key ? `metric.${ui.key}` : "";

    // these are all raw fields without a prefix
    case ConditionMatch.LOG:
    case ConditionMatch.DATAPOINT:
    case ConditionMatch.SPAN:
    default:
      return ui.key ?? "";
  }
}

function escapeBackslash(input?: string): string {
  if (input === undefined) {
    return "";
  }
  return input.replace(/\\/g, "\\\\");
}

function getOttlContext(ui: ConditionStatement): string {
  return ui.match ?? "attributes";
}

type ottlGenerator = (statement: ConditionStatement) => string;

const binaryOperator: ottlGenerator = (statement) => {
  return `${getFieldExpr(statement)} ${statement.operator} ${statement.value}`;
};

const fieldFunction: ottlGenerator = (statement) => {
  return `${statement.operator}(${getFieldExpr(statement)})`;
};

interface ConditionOperator {
  operator: string;
  label?: string;
  hasValue: boolean;
  requiresValue?: boolean;
  valueLabel?: string;
  twoLines?: boolean;
  raw: ottlGenerator;
}

// The operator fields in the operator are used to generate the OTTL expression. If we
// decide to remove or rename operators, we should leave the existing ones in place for
// backward compatibility. Otherwise the generated OTTL will change for existing
// conditions. If necessary, we could a hidden: true field to remove an operator from the
// UI to prevent it from being used in the future.
const operators: { [heading: string]: ConditionOperator[] } = {
  String: [
    {
      operator: "Equals",
      hasValue: true,
      valueLabel: "String",
      raw: (s) => `${getFieldExpr(s)} == "${escapeBackslash(s.value)}"`,
    },
    {
      operator: "NotEquals",
      label: "Not Equals",
      hasValue: true,
      valueLabel: "String",
      raw: (s) => `${getFieldExpr(s)} != "${escapeBackslash(s.value)}"`,
    },
    {
      // escapeRegExp escapes the same characters as regexp.QuoteMeta in go
      operator: "StartsWith",
      hasValue: true,
      requiresValue: true,
      valueLabel: "String",
      raw: (s) => `IsMatch(${getFieldExpr(s)}, "^${escapeRegExp(s.value)}")`,
    },
    {
      // escapeRegExp escapes the same characters as regexp.QuoteMeta in go
      operator: "EndsWith",
      hasValue: true,
      requiresValue: true,
      valueLabel: "String",
      raw: (s) => `IsMatch(${getFieldExpr(s)}, "${escapeRegExp(s.value)}$")`,
    },
    {
      // escapeRegExp escapes the same characters as regexp.QuoteMeta in go
      operator: "Contains",
      hasValue: true,
      requiresValue: true,
      valueLabel: "String",
      raw: (s) => `IsMatch(${getFieldExpr(s)}, "${escapeRegExp(s.value)}")`,
    },
    {
      // no escaping needed when the value is a regex
      operator: "Matches",
      hasValue: true,
      requiresValue: true,
      valueLabel: "Regex",
      twoLines: true,
      raw: (s) => `IsMatch(${getFieldExpr(s)}, "${escapeBackslash(s.value)}")`,
    },
  ],
  Compare: [
    { operator: "==", hasValue: true, raw: binaryOperator },
    { operator: "!=", hasValue: true, raw: binaryOperator },
    { operator: ">", hasValue: true, raw: binaryOperator },
    { operator: "<", hasValue: true, raw: binaryOperator },
    { operator: ">=", hasValue: true, raw: binaryOperator },
    { operator: "<=", hasValue: true, raw: binaryOperator },
  ],
  Type: [
    { operator: "IsBool", hasValue: false, raw: fieldFunction },
    { operator: "IsDouble", hasValue: false, raw: fieldFunction },
    { operator: "IsInt", hasValue: false, raw: fieldFunction },
    { operator: "IsMap", hasValue: false, raw: fieldFunction },
    { operator: "IsString", hasValue: false, raw: fieldFunction },
  ],
  Other: [
    {
      operator: "Exists",
      label: "Exists",
      hasValue: false,
      raw: (s) => `${getFieldExpr(s)} != nil`,
    },
    {
      operator: "DoesNotExist",
      label: "Does Not Exist",
      hasValue: false,
      raw: (s) => `${getFieldExpr(s)} == nil`,
    },
  ],
};

function getOperator(operator: string): ConditionOperator | undefined {
  for (const heading in operators) {
    for (const op of operators[heading]) {
      if (op.operator === operator) {
        return op;
      }
    }
  }
  return undefined;
}

const AddButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <Stack direction="row" spacing={2} justifyContent="flex-start">
      <Button variant="outlined" onClick={onClick}>
        Add Condition
      </Button>
    </Stack>
  );
};

const ConditionOperatorSelect: React.FC<{
  key?: string;
  value: string;
  readOnly: boolean;
  onChange: (newOperator: string) => void;
}> = ({ key, value, readOnly, onChange }) => {
  return (
    <Select
      key={key}
      value={value}
      size="small"
      onChange={(e) => onChange(e.target.value)}
      disabled={readOnly}
      className={styles["condition-operator"]}
      MenuProps={{ className: styles["snapshot-menu"] }}
    >
      {["String", "Compare", "Type", "Other"].map((heading, i) => {
        const sep = (
          <MenuItem
            key={`${key}-h-${i}`}
            className={styles["snapshot-menu-heading"]}
            disabled
          >
            {heading}
          </MenuItem>
        );

        return [
          sep,
          ...operators[heading].map((op, j) => (
            <MenuItem key={`${key}-${i}-${j}`} value={op.operator}>
              {op.label ?? op.operator}
            </MenuItem>
          )),
        ];
      })}
    </Select>
  );
};

const ConditionValue: React.FC<{
  key?: string;
  fieldKey?: string; // used for completions
  label: string;
  value: string;
  readOnly: boolean;
  ottlContext?: string;
  onChange: (newValue: string) => void;
}> = ({ key, fieldKey, label, value, readOnly, ottlContext, onChange }) => {
  if (fieldKey == null) {
    return (
      <TextField
        key={key}
        multiline={true}
        type={"text"}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        disabled={readOnly}
        fullWidth
        size="small"
        label={label}
        required={false}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        maxRows="4"
      />
    );
  }
  // create a pseudo-ParameterDefinition to use with OTTLFieldInput
  const definition: ParameterDefinition = {
    name: "value",
    label,
    description: "",
    options: {
      ottlContext,
    },
    type: ParameterType.OttlField,
    required: false,
  };
  return (
    <OTTLFieldInput
      key={key}
      definition={definition}
      readOnly={readOnly}
      value={value}
      onValueChange={onChange}
      getOptions={getFieldValueOptions(fieldKey)}
    />
  );
};

const ConditionButtons: React.FC<{
  onAddGroup?: () => void;
  onAddStatement: () => void;
}> = ({ onAddGroup, onAddStatement }) => {
  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      spacing={0.5}
      className={styles["condition-buttons"]}
    >
      <Stack
        direction="row"
        spacing={1}
        justifyContent={"center"}
        alignItems={"center"}
        divider={<Divider orientation="vertical" sx={{ height: "16px" }} />}
      >
        {onAddGroup && (
          <LinkButton onClick={onAddGroup}>Add Condition</LinkButton>
        )}
        <LinkButton onClick={onAddStatement}>Add Statement</LinkButton>
      </Stack>

      {/* Spacer to align the buttons to the right */}
      <IconButton size={"small"} sx={{ visibility: "hidden" }}>
        <TrashIcon width={18} />
      </IconButton>
    </Stack>
  );
};

const ConditionOttlExpression: React.FC<{
  key?: string;
  required: boolean;
  value: string;
  readOnly: boolean;
  helperText?: React.ReactNode;
  onChange?: (newValue: string) => void;
}> = ({ key, value, required, readOnly, helperText, onChange }) => {
  return (
    <TextField
      key={key}
      multiline={true}
      type={"text"}
      value={value}
      onChange={(e) => onChange && onChange(e.target.value)}
      disabled={readOnly}
      fullWidth
      size="small"
      label={"OTTL Expression"}
      required={required}
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="off"
      spellCheck="false"
      data-testid={key}
      helperText={helperText}
    />
  );
};

// ----------------------------------------------------------------------
// utils

/**
 * ConditionPath represents a path to a specific statement in a Condition statement tree.
 * Each index in the path corresponds to the index of a statement in the list of
 * statements. A path will uniquely identify a statement in the tree and can be used to
 * identify the statement to update or delete.
 */
export class ConditionPath {
  public path: number[];

  constructor(path: number[] = []) {
    this.path = path;
  }

  isRoot(): boolean {
    return this.path.length === 0;
  }

  childPath(childIndex: number): ConditionPath {
    return new ConditionPath(this.path.concat(childIndex));
  }

  parentPath(): ConditionPath | undefined {
    if (this.path.length === 0) {
      return undefined;
    }
    return new ConditionPath(this.path.slice(0, -1));
  }

  key(base: string, suffix?: string): string {
    return [base, ...this.path, suffix].join("-");
  }

  length(): number {
    return this.path.length;
  }

  // returns the index of path at the specified depth
  index(depth: number): number | undefined {
    return this.path[depth];
  }

  lastIndex(): number | undefined {
    return this.index(this.path.length - 1);
  }

  toString(): string {
    return this.path.join(",");
  }
}

/**
 * Condition wraps the ConditionInputValue and provides methods to manipulate the
 * condition.
 */
export class Condition {
  private condition: ConditionInputValue;

  constructor(value?: ConditionInputValue | string) {
    // for compatibility with string conditions, this may be a string
    if (typeof value === "string") {
      // migrate the default "true" setting to the new default value
      switch (value.trim()) {
        case "":
        case "true":
          value = defaultValue();
          break;
        default:
          value = defaultRaw(value);
          break;
      }
    }

    // we clone because we want to be able to modify it directly and then call
    // onValueChange with the modified value
    if (value?.ui == null && value?.ottl === "") {
      value = defaultValue();
    }
    this.condition = cloneDeep(value ?? defaultValue());
  }

  /**
   * @returns the root statement of the condition
   */
  root(): ConditionStatement {
    return this.condition.ui!;
  }

  /**
   * Updates a statement at the specified path using the specified updater function.
   *
   * @return false if there was an error
   */
  updateStatement(
    path: ConditionPath,
    updater: (statement: ConditionStatement) => void,
  ): boolean {
    const statement = this.getStatementWithPath(path);
    if (!statement) {
      return false;
    }
    updater(statement);
    return true;
  }

  /**
   * Deletes a statement at the specified path.
   *
   * @return false if there was an error
   */
  deleteStatement(path: ConditionPath): boolean {
    const parentPath = path.parentPath();
    if (!parentPath) {
      return false;
    }
    const parent = this.getStatementWithPath(parentPath);
    if (!parent) {
      return false;
    }

    // there must be a lastIndex if there is a parentPath
    parent.statements!.splice(path.lastIndex()!, 1);

    const isRoot = parentPath.isRoot();
    if (isRoot && parent.statements!.length === 0) {
      parent.statements!.push(defaultStatement());
    }

    return true;
  }

  /**
   * Adds a new empty statement at the specified path.
   *
   * @return false if there was an error
   */
  addStatement(path: ConditionPath): boolean {
    const statement = this.getStatementWithPath(path);
    if (!statement) {
      return false;
    }
    statement.statements!.push(defaultStatement());
    return true;
  }

  /**
   * Adds a new OR group to the specified path, replacing the current node with an OR
   * between the existing node at the path and a new empty group.
   *
   * @return false if there was an error
   */
  addGroup(path: ConditionPath): boolean {
    const statement = this.getStatementWithPath(path);
    if (!statement) {
      return false;
    }

    // replace the current node with a new OR node with 2 children, the current node and a
    // new empty node
    const original = cloneDeep(statement);
    statement.operator = "OR";
    statement.key = undefined;
    statement.match = undefined;
    statement.value = undefined;
    statement.statements = [
      original,
      {
        operator: "",
        statements: [defaultStatement()],
      },
    ];
    return true;
  }

  /**
   * @returns true if the condition is empty (ottl evaluates to "")
   */
  isEmpty(): boolean {
    return this.ottl() === "";
  }

  /**
   * @returns true if the condition has a UI tree that can be used to build the condition
   */
  hasBuilderUI(): boolean {
    return this.condition.ui !== undefined;
  }

  /**
   * @returns the unique list of match values used in the condition
   */
  matchValues(): string[] {
    return getMatchValues(this.condition.ui);
  }

  /**
   * Returns the condition value after cleaning up the UI tree and generating the OTTL.
   *
   * @returns the condition value
   */
  value(): ConditionInputValue {
    this.condition.ui = cleanupConditionUI(this.condition.ui!);
    this.condition.ottl = this.ottl();
    return this.condition;
  }

  /**
   * @returns the OTTL expression for the condition
   */
  ottl(): string {
    if (this.condition.ui) {
      return getOttlExpression(this.condition.ui);
    }
    return this.condition.ottl ?? "";
  }

  /**
   * returns the statement at the given path, or undefined if the path is invalid for the
   * given statement.
   *
   * The path is an array of indices, where each index is the index of the statement in the
   * list of statements associated with the specified statement. An empty path corresponds
   * to the specified statement itself. This allows us to navigate the tree of statements to
   * a depth equal to the length of the array.
   */
  private getStatementWithPath(
    path: ConditionPath,
  ): ConditionStatement | undefined {
    let statement = this.condition.ui!;
    for (let i = 0; i < path.length(); i++) {
      const index = path.index(i)!;
      if (statement.statements && index < statement.statements.length) {
        statement = statement.statements[index];
      } else {
        return undefined;
      }
    }
    return statement;
  }
}

function getMatchValues(ui?: ConditionStatement): string[] {
  if (!ui) {
    return [];
  }
  return uniq([
    ui.match,
    ...(ui.statements?.flatMap((s) => getMatchValues(s)) ?? []),
  ]).filter((s) => s !== undefined) as string[];
}

function getOttlExpression(ui?: ConditionStatement): string {
  if (!ui) {
    return "";
  }
  // custom expressions are just the value
  if (ui.match === ConditionMatch.CUSTOM) {
    return ui.value ? `(${ui.value})` : "";
  }
  // Implied AND if no operator and multiple statements
  const hasStatements = ui.statements && ui.statements.length > 0;
  const operator = ui.operator === "" && hasStatements ? "AND" : ui.operator;
  switch (operator) {
    case "AND":
    case "OR":
      const childExpression = stripParens(
        ui.statements
          ?.map((s) => getOttlExpression(s))
          .filter((s) => s !== "" && s !== "()")
          .join(` ${operator.toLowerCase()} `),
      );
      return childExpression !== "" ? `(${childExpression})` : "";
    default:
      // operator always required
      if (ui.operator === "") {
        return "";
      }

      // operator must be valid
      const op = getOperator(ui.operator);
      if (!op) {
        return "";
      }

      // value required for operators that require a value
      if (op?.requiresValue && ui.value === "") {
        return "";
      }

      // key required unless matching body
      if (ui.match !== ConditionMatch.BODY && ui.key === "") {
        return "";
      }

      return op ? op.raw(ui) : "";
  }
}

// strips the outermost parens from an expression. we use this to clean up the OTTL when
// multiple nested statements add parens.
function stripParens(expr: string | undefined): string | undefined {
  if (expr && expr.startsWith("(") && expr.endsWith(")")) {
    return expr.slice(1, -1);
  }
  return expr;
}

// return true if the statement has a binary operator AND or OR.
function hasBinaryOperator(statement: ConditionStatement): boolean {
  return statement.operator === "AND" || statement.operator === "OR";
}

// returns true if the statement has a binary operator or no operator meaning implied AND.
// It is expected to have multiple children statements.
function isGroupStatement(statement: ConditionStatement): boolean {
  return hasBinaryOperator(statement) || statement.operator === "";
}

// returns true if the statement is a group statement with no children
function isEmptyGroupStatement(statement: ConditionStatement): boolean {
  return (
    isGroupStatement(statement) && (statement.statements?.length ?? 0) === 0
  );
}

// removes any empty statements and expands any ORs with > 2 children
export function cleanupConditionUI(
  ui: ConditionStatement,
): ConditionStatement | undefined {
  return cleanupConditionTree(ui) ?? ui;
}
function cleanupConditionTree(
  ui: ConditionStatement,
): ConditionStatement | undefined {
  // depth-first traversal, removing deleted statements
  if (ui.statements) {
    ui.statements = ui.statements
      .map((s) => cleanupConditionTree(s))
      .filter((s) => s != null) as ConditionStatement[];
  }

  // prune empty group statements which can result from deleting the last statement
  if (isEmptyGroupStatement(ui)) {
    return undefined;
  }

  // binary statements should have exactly 2 children statements. otherwise the UI looks
  // weird.
  if (hasBinaryOperator(ui)) {
    switch (ui.statements?.length ?? 0) {
      case 1:
        // remove the binary operator and promote the single child
        return ui.statements![0];

      case 2:
        // perfect
        break;

      default:
        // more than 2 children, create a binary tree starting with the last 2 elements
        // TODO: test this
        let replacement = {
          operator: ui.operator,
          statements: [
            ui.statements![ui.statements!.length - 2],
            ui.statements![ui.statements!.length - 1],
          ],
        };
        for (let i = ui.statements!.length - 3; i >= 0; i--) {
          replacement = {
            operator: ui.operator,
            statements: [ui.statements![i], replacement],
          };
        }
        return replacement;
    }
  }

  return ui;
}
