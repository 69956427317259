import { Box, Button, Fade, Stack, TextField } from "@mui/material";
import { ArrowRightIcon } from "@mui/x-date-pickers";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth0Card } from "../../../components/Auth0Imitation/Auth0Card";
import { Auth0CardBody } from "../../../components/Auth0Imitation/Auth0CardBody";
import { Auth0CardHeader } from "../../../components/Auth0Imitation/Auth0CardHeader";
import { edition } from "../../../components/BindplaneVersion/utils";
import { EULAField } from "../../../components/OnboardingSteps/EULAField";
import { withRequireLogin } from "../../../contexts/RequireLogin";
import { createNewOrganization } from "../../../utils/rest/new-organization";
import { validateOrganizationName } from "../../../utils/rest/verify-organization";

export const SetupOrganizationPage: React.FC = withRequireLogin(() => {
  const [valid, setValid] = useState(false);
  const [eulaAccepted, setEulaAccepted] = useState(false);
  const [orgFieldValue, setOrgFieldValue] = useState<string>("");
  const [orgFieldError, setOrgFieldError] = useState<string | null>(null);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setValid(!orgFieldError && eulaAccepted);
  }, [eulaAccepted, orgFieldError]);

  const validateOrgName = useCallback(
    async (orgName: string) => {
      const error = await validateOrganizationName(orgName);
      setOrgFieldError(error);
    },
    [setOrgFieldError],
  );

  const debouncedValidate = useMemo(
    () => debounce(validateOrgName, 200),
    [validateOrgName],
  );

  async function onOrgFieldChange(e: React.ChangeEvent<HTMLInputElement>) {
    setOrgFieldValue(e.target.value);
    debouncedValidate(e.target.value);
  }

  async function handleGetStarted() {
    const orgFieldError = await validateOrganizationName(orgFieldValue);
    if (orgFieldError) {
      setOrgFieldError(orgFieldError);
      return;
    }

    try {
      const resp = await createNewOrganization({
        organizationName: orgFieldValue,
        accountName: "My First Project",
        eulaAccepted: eulaAccepted,
      });
      if (!resp.ok) {
        throw new Error(
          `Failed to create organization, got status ${resp.status}`,
        );
      }
      navigate("/agents");
    } catch (e) {
      enqueueSnackbar((e as Error).message, {
        variant: "error",
      });
      console.error(e);
    }
  }

  function handleEnter(e: React.KeyboardEvent) {
    if (e.key === "Enter" && valid) {
      handleGetStarted();
    }
  }

  const header =
    edition() === "Honeycomb"
      ? "Create your Organization in Honeycomb Telemetry Pipeline Management"
      : "Create your Organization in BindPlane OP";

  return (
    <Auth0Card>
      <Auth0CardHeader description={header} />
      <Auth0CardBody>
        <Box
          component="form"
          onSubmit={(e) => e.preventDefault()}
          onKeyDown={handleEnter}
          marginBottom="32px"
          width="100%"
        >
          <TextField
            value={orgFieldValue}
            onChange={onOrgFieldChange}
            fullWidth
            error={Boolean(orgFieldError)}
            helperText={orgFieldError ?? " "}
            label="Organization Name"
            placeholder="My Organization*"
          />
          <EULAField
            onChange={(e) => setEulaAccepted(e.target.checked)}
            checked={eulaAccepted}
          />
        </Box>
        <Stack width="100%" height="40px" alignItems="center">
          <Fade in={valid}>
            <Button onClick={handleGetStarted} endIcon={<ArrowRightIcon />}>
              Get started
            </Button>
          </Fade>
        </Stack>
      </Auth0CardBody>
    </Auth0Card>
  );
});
