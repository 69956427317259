import { memo } from "react";
import { hasPipelineTypeFlag } from "../../../types/configuration";
import { getRCFromSpec } from "../../../utils/classes/configuration";
import { CardMeasurementContent } from "../../CardMeasurementContent/CardMeasurementContent";
import { InlineSourceCard } from "../../Cards/InlineSourceCard";
import { ResourceSourceCard } from "../../Cards/ResourceSourceCard";
import { useV2PipelineGraph } from "../PipelineGraphV2Context";
import { RoutingNodeWrapper } from "../RoutingContext/RoutingNodeWrapper";
import { AttributeName } from "../types";

function SourceNodeV2({
  data,
}: {
  data: {
    id: string;
    metric: string;
    attributes: Record<string, any>;
    connectedNodesAndEdges: string[];
  };
}) {
  const {
    configuration,
    refetchConfiguration,
    selectedTelemetryType,
    hoveredSet,
    setHoveredSet,
    readOnlyGraph,
  } = useV2PipelineGraph();

  const { id, metric, attributes } = data;

  var sourceIndex = attributes["sourceIndex"];
  if (typeof sourceIndex !== "number") {
    throw new Error("sourceIndex is not a number");
  }

  const resourceConfig = getRCFromSpec(
    configuration!.spec,
    "source",
    sourceIndex,
  );

  // This can happen during an update of the configuration
  if (resourceConfig == null) {
    return null;
  }

  const isInactive = !hasPipelineTypeFlag(
    selectedTelemetryType,
    attributes[AttributeName.ActiveTypeFlags],
  );
  const notInHoveredSet = hoveredSet.length > 0 && !hoveredSet.includes(id);

  return (
    <RoutingNodeWrapper
      index={sourceIndex}
      nodeType="source"
      componentPath={attributes["componentPath"]}
    >
      <div
        onMouseEnter={() => {
          setHoveredSet(data.connectedNodesAndEdges);
        }}
        onMouseLeave={() => setHoveredSet([])}
      >
        {resourceConfig.isInline() ? (
          <InlineSourceCard
            id={id.replace("source/", "")}
            disabled={isInactive || notInHoveredSet}
            configuration={configuration}
            refetchConfiguration={refetchConfiguration}
            readOnly={!!readOnlyGraph}
          />
        ) : (
          <ResourceSourceCard
            name={resourceConfig.name!}
            disabled={isInactive || notInHoveredSet}
            sourceId={id}
            configuration={configuration}
            refetchConfiguration={refetchConfiguration}
            readOnly={!!readOnlyGraph}
          />
        )}
        <CardMeasurementContent>{metric}</CardMeasurementContent>
      </div>
    </RoutingNodeWrapper>
  );
}

export default memo(SourceNodeV2);
