import { Stack, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { StepOneCommon } from "../StepOneCommon";

export const StepOne: React.FC = () => {
  function renderCopy() {
    return (
      <Stack spacing={2} marginBottom={2}>
        <Typography variant="h6" fontWeight={600}>
          Let's get started building your configuration.
        </Typography>

        <Typography>
          The BindPlane configuration builder makes it easy to assemble a valid
          OpenTelemetry configuration.
        </Typography>

        <Typography>
          Already have an OpenTelemetry configuration? Use our{" "}
          <Link to="/configurations/new-raw">raw configuration wizard</Link>.
        </Typography>

        <Typography>
          We&apos;ll walk you through configuring the Sources you want to ingest
          telemetry from and the Destination you want to send the data to.
        </Typography>

        <Typography>
          Let&apos;s get started building your configuration.
        </Typography>
      </Stack>
    );
  }
  return (
    <>
      <StepOneCommon renderCopy={renderCopy} />
    </>
  );
};
