import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { Graph, ResourceConfiguration } from "../../../graphql/generated";
import { hasPipelineTypeFlag } from "../../../types/configuration";
import { BPConfiguration } from "../../../utils/classes";
import { getNextComponentPaths } from "../../../utils/classes/resource-configuration";
import { RoutingNodeMenu } from "../../RoutingNodeMenu/RoutingNodeMenu";
import { useV2PipelineGraph } from "../PipelineGraphV2Context";
import { AttributeName, V2Config } from "../types";

interface RoutingContextProps {
  readOnly: boolean;
  configuration: V2Config;
}

type ComponentInfo = {
  nodeType: "source" | "destination" | "processor";
  index: number;
};

interface RoutingContextValue {
  onRouteMenuOpen: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    nodeType: "source",
    index: number,
  ) => void;
  // a list of component paths that can be connected to the currently
  // selected component.
  connectableComponentPaths: string[];
  // a callback used to connect the a component to the currently selected
  onConnect: (componentPath: string) => Promise<void>;
  // a flag to indicate if the graph is in read-only mode
  readOnly: boolean;
}

const defaultValue: RoutingContextValue = {
  onRouteMenuOpen: () => {},
  onConnect: async () => {},
  connectableComponentPaths: [],
  readOnly: true,
};

export const routingContext = createContext<RoutingContextValue>(defaultValue);

/**
 * RoutingContext provides the callbacks needed for routing in the PipelineGraph
 */
export const RoutingContextProvider: React.FC<RoutingContextProps> = ({
  children,
  readOnly,
  configuration,
}) => {
  const [connectingComponent, setConnectingComponent] =
    useState<ComponentInfo | null>(null);
  const [connectableComponentPaths, setConnectableComponentPaths] = useState<
    string[]
  >([]);
  const [routeMenuAnchor, setRouteMenuAnchor] = useState<HTMLElement | null>(
    null,
  );

  const { selectedTelemetryType } = useV2PipelineGraph();

  // Add event listener to exit the 'connecting' mode.
  useEffect(() => {
    function handleEscape(e: KeyboardEvent) {
      if (e.key === "Escape" && connectableComponentPaths.length > 0) {
        setConnectableComponentPaths([]);
        setConnectingComponent(null);
      }
    }
    document.addEventListener("keydown", handleEscape);

    return () => document.removeEventListener("keydown", handleEscape);
  }, [connectableComponentPaths.length]);

  // Clear the connecting component when telemetry type changes
  useEffect(() => {
    setRouteMenuAnchor(null);
    setConnectingComponent(null);
    setConnectableComponentPaths([]);
  }, [selectedTelemetryType]);

  function handleMenuClose() {
    setRouteMenuAnchor(null);
    setConnectingComponent(null);
  }

  function handleHighlightConnectable(_nodeType: string, index: number) {
    const connectingRC = configuration?.spec?.sources![index];
    if (!connectingRC) return;

    const destinations = configuration?.graph?.targets;
    if (!destinations) return;

    setConnectingComponent({ nodeType: "source", index });
    setRouteMenuAnchor(null);

    const connectable: string[] = [];
    for (const d of destinations) {
      if (isConnectable(connectingRC, d, selectedTelemetryType)) {
        connectable.push(d.attributes[AttributeName.ComponentPath]);
      }
    }

    setConnectableComponentPaths(connectable);
  }

  function onRouteMenuClick(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    nodeType: "source" | "destination" | "processor",
    index: number,
  ) {
    setConnectingComponent({ nodeType, index });
    setRouteMenuAnchor(e.currentTarget);
    setConnectableComponentPaths([]);
  }

  async function removeComponentRoute(
    nodeType: "source" | "destination" | "processor",
    index: number,
    componentPath: string,
  ) {
    const cfg = new BPConfiguration(configuration);
    cfg.removeComponentPathFromRC(
      nodeType,
      index,
      componentPath,
      selectedTelemetryType,
    );
    await cfg.apply();
  }

  async function connectComponentRoute(componentPath: string) {
    if (connectingComponent == null) return;

    const cfg = new BPConfiguration(configuration);
    cfg.addComponentRoute(
      connectingComponent.nodeType,
      connectingComponent.index,
      componentPath,
      selectedTelemetryType,
    );
    await cfg.apply();

    setConnectingComponent(null);
    setConnectableComponentPaths([]);
  }

  const hasAvailableConnections = useMemo(
    () =>
      componentHasAvailableConnections(
        connectingComponent,
        selectedTelemetryType,
        configuration,
      ),
    [connectingComponent, selectedTelemetryType, configuration],
  );

  return (
    <routingContext.Provider
      value={{
        onRouteMenuOpen: onRouteMenuClick,
        onConnect: connectComponentRoute,
        connectableComponentPaths,
        readOnly,
      }}
    >
      {children}
      <RoutingNodeMenu
        hasAvailableConnections={hasAvailableConnections}
        onClose={handleMenuClose}
        onConnectClick={handleHighlightConnectable}
        onRemoveComponentPath={removeComponentRoute}
        open={!!routeMenuAnchor}
        anchorEl={routeMenuAnchor}
        nodeType={connectingComponent?.nodeType}
        index={connectingComponent?.index}
      />
    </routingContext.Provider>
  );
};

/**
 * isConnectable determines if a source resource configuration can connect to
 * a target on the graph.  It must support the telemetry type and not already
 * be connected.
 *
 * @param source ResourceConfiguration you want to test connection
 * @param target target node on the graph
 * @param telemetryType telemetry type to test
 * @returns
 */
function isConnectable(
  source: ResourceConfiguration,
  target: Graph["targets"][number],
  telemetryType: string,
) {
  const nextComponents = getNextComponentPaths(source, telemetryType);

  const supportsType = hasPipelineTypeFlag(
    telemetryType,
    target.attributes[AttributeName.SupportedTypeFlags],
  );

  const isAlreadyConnected = nextComponents.includes(
    target.attributes[AttributeName.ComponentPath],
  );

  return supportsType && !isAlreadyConnected;
}

function componentHasAvailableConnections(
  componentInfo: ComponentInfo | null,
  selectedTelemetryType: string,
  configuration: V2Config,
) {
  if (!componentInfo) return false;

  const rc = configuration?.spec?.sources![componentInfo.index];
  if (!rc) return false;

  for (const d of configuration.graph?.targets ?? []) {
    if (isConnectable(rc, d, selectedTelemetryType)) {
      return true;
    }
  }
  return false;
}

export function useRouting(): RoutingContextValue {
  return useContext(routingContext);
}
