import { gql, useApolloClient } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { capitalize } from "lodash";
import { useEffect, useState } from "react";
import {
  GetAccountPageInfoDocument,
  Role,
  useEditUserRoleMutation,
} from "../../graphql/generated";
import { RoleInfo } from "../RoleInfo";

gql`
  mutation EditUserRole($input: EditUserRoleInput!) {
    editRole(input: $input) {
      metadata {
        name
        id
        version
        displayName
      }
      spec {
        activeAccount
        email
      }
      role
    }
  }
`;

interface EditUserRoleDialogProps extends DialogProps {
  editingUser: EditUserInfo | null;
  closeDialog: () => void;
}

export interface EditUserInfo {
  id: string;
  displayName: string;
  currentRole: Role;
}

export const EditUserRoleDialog: React.FC<EditUserRoleDialogProps> = ({
  editingUser,
  closeDialog,
  ...dialogProps
}) => {
  const [role, setRole] = useState<string | null>(
    editingUser?.currentRole ?? null,
  );
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();
  const [editRole] = useEditUserRoleMutation({
    onCompleted: (data) => {
      client.cache.updateQuery(
        {
          query: GetAccountPageInfoDocument,
        },
        (prev) => {
          if (!prev) return prev;
          return {
            ...prev,
            users: data.editRole,
          };
        },
      );
    },
    onError: () => {
      setLoading(false);
    },
  });

  useEffect(() => {
    if (editingUser != null) {
      setRole(editingUser.currentRole);
    }
  }, [editingUser]);

  if (editingUser == null) {
    return null;
  }

  const transitionProps: TransitionProps = {
    onExited: (node) => {
      setRole(null);
      dialogProps.TransitionProps?.onExited &&
        dialogProps.TransitionProps.onExited(node);
    },
  };

  async function handleSave() {
    if (role == null || editingUser == null) {
      return;
    }

    if (role === editingUser.currentRole) {
      closeDialog();
      return;
    }

    setLoading(true);
    await editRole({
      variables: {
        input: {
          userId: editingUser.id,
          role: role as Role,
        },
      },
    });

    setLoading(false);
    closeDialog();
  }

  return (
    <Dialog
      {...dialogProps}
      maxWidth="sm"
      fullWidth
      TransitionProps={transitionProps}
    >
      <DialogTitle>Editing Role for {editingUser.displayName}</DialogTitle>
      <DialogContent>
        <RoleInfo />

        <Stack width="100%" marginTop={5}>
          <Box width={150}>
            <FormControl fullWidth>
              <InputLabel id="select-role-label">Select a role</InputLabel>
              <Select
                labelId="select-role-label"
                value={role}
                size="small"
                onChange={(e) => setRole(e.target.value)}
                label="Select a role"
                inputProps={{ "data-testid": "select-role" }}
              >
                {Object.values(Role).map((role) => (
                  <MenuItem key={role} value={role}>
                    {capitalize(role)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="outlined" onClick={closeDialog}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={handleSave}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
