import { gql } from "@apollo/client";
import { Alert, AlertTitle, Paper, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { CPUIcon, MemoryIcon } from "../../components/Icons";
import { useGetAgentHealthQuery } from "../../graphql/generated";
import colors from "../../styles/colors";
import { classes } from "../../utils/styles";
import { AgentDataPoint, AgentHealthWidget } from "./AgentHealthWidget";
import mixins from "../../styles/mixins.module.scss";

gql`
  query GetAgentHealth($id: ID!, $interval: String!) {
    agentHealthMetrics(id: $id, interval: $interval) {
      name
      timestamp
      value
      unit
      type
      attributes
      resource
    }
  }
`;
export const AgentHealthIntervalValues = ["1h", "12h", "24h", "48h"] as const;
export type AgentHealthInterval = (typeof AgentHealthIntervalValues)[number];
export interface AgentHealthTabProps {
  errorMessage: string | null | undefined;
  id: string;
}

export const AgentHealthTab: React.FC<AgentHealthTabProps> = ({
  errorMessage,
  id,
}) => {
  const [cpuData, setCpuData] = useState<Array<AgentDataPoint>>([]);
  const [memoryData, setMemoryData] = useState<Array<AgentDataPoint>>([]);

  const [measurementInterval, onMeasurementIntervalChange] =
    useState<AgentHealthInterval>("1h");

  useGetAgentHealthQuery({
    fetchPolicy: "network-only",
    variables: { id: id, interval: measurementInterval },
    pollInterval: 60000,
    onCompleted(data) {
      // This metric is a percentage expressed as a value between 0 and 1, see:
      // https://github.com/open-telemetry/opentelemetry-collector-contrib/blob/0d1f5628228ac22b1ceb76d9c2a52be96410ad2b/receiver/hostmetricsreceiver/internal/scraper/processscraper/internal/metadata/generated_metrics.go#L238
      const cpu = data.agentHealthMetrics
        .filter((metric) => metric?.name?.includes("process.cpu.utilization"))
        .sort((a, b) => a?.timestamp?.localeCompare(b?.timestamp))
        .map((metric) => {
          return {
            value: metric?.value * 100, // convert to percentage
            timestamp: new Date(metric?.timestamp),
          };
        });
      setCpuData(cpu);

      // The memory metric is a percentage of virtual memory used, see:
      // https://github.com/open-telemetry/opentelemetry-collector-contrib/blob/0d1f5628228ac22b1ceb76d9c2a52be96410ad2b/receiver/hostmetricsreceiver/internal/scraper/processscraper/internal/metadata/generated_metrics.go#L497
      // The value is a between 0 and 100, see:
      // https://github.com/open-telemetry/opentelemetry-collector-contrib/blob/0d1f5628228ac22b1ceb76d9c2a52be96410ad2b/receiver/hostmetricsreceiver/internal/scraper/processscraper/process_scraper.go#L311
      //    and
      // https://github.com/shirou/gopsutil/blob/8aeaf163abb6216e52f56bbba4919f6dbc4fd894/process/process.go#L342
      const memory = data.agentHealthMetrics
        .filter((metric) =>
          metric?.name?.includes("process.memory.utilization"),
        )
        .sort((a, b) => a?.timestamp?.localeCompare(b?.timestamp))
        .map((metric) => {
          return {
            value: metric?.value, // already percentage
            timestamp: new Date(metric?.timestamp),
          };
        });
      setMemoryData(memory);
    },
  });

  return (
    <>
      {errorMessage && (
        <Alert
          severity="error"
          className={classes([mixins["mt-5"], mixins["mb-0"]])}
        >
          <AlertTitle>Error</AlertTitle>
          {errorMessage}
        </Alert>
      )}
      <Stack paddingTop="1rem">
        <Stack
          minHeight={"100%"}
          direction={"row"}
          spacing={2}
          alignItems={"center"}
        >
          <Paper sx={{ width: "50%" }}>
            <AgentHealthWidget
              measurementInterval={measurementInterval}
              onMeasurementIntervalChange={onMeasurementIntervalChange}
              dataset={cpuData}
              label={
                <Stack direction="row" spacing={1} alignItems="center">
                  <CPUIcon stroke={colors.pixelPointBlue} />
                  <Typography variant="h6" fontWeight="bold">
                    CPU Usage
                  </Typography>
                </Stack>
              }
            />
          </Paper>
          <Paper sx={{ width: "50%" }}>
            <AgentHealthWidget
              measurementInterval={measurementInterval}
              onMeasurementIntervalChange={onMeasurementIntervalChange}
              dataset={memoryData}
              label={
                <Stack direction="row" spacing={1} alignItems="center">
                  <MemoryIcon stroke={colors.pixelPointBlue} />
                  <Typography variant="h6" fontWeight="bold">
                    Memory Usage
                  </Typography>
                </Stack>
              }
            />
          </Paper>
        </Stack>
      </Stack>
    </>
  );
};
