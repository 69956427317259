import { Box, Dialog, DialogContent, DialogProps, Stack } from "@mui/material";
import { isFunction } from "lodash";
import { useState } from "react";
import { DialogContainer } from "../DialogComponents/DialogContainer";
import { ProcessorTelemetrySwitcher } from "../ProcessorTelemetrySwitcher/ProcessorTelemetrySwitcher";
import { SnapshotConsole } from "../SnapShotConsole/SnapShotConsole";
import { SnapShotControls } from "../SnapShotConsole/SnapShotControls";
import { useSnapshot } from "../SnapShotConsole/SnapshotContext";

interface RecentTelemetryDialogProps extends DialogProps {
  agentID: string;
}

export const RecentTelemetryDialog: React.FC<RecentTelemetryDialogProps> = ({
  agentID,
  ...dialogProps
}) => {
  function handleClose() {
    isFunction(dialogProps.onClose) && dialogProps.onClose({}, "backdropClick");
  }
  const [workingQuery, setWorkingQuery] = useState("");
  return (
    <Dialog
      fullWidth
      maxWidth={"xl"}
      {...dialogProps}
      PaperProps={{
        style: {
          height: "90vh",
          minHeight: "550px",
        },
      }}
    >
      <DialogContent
        style={{
          height: "90vh",
          minHeight: "500px",
        }}
      >
        <Stack
          flexGrow={1}
          height="calc(90vh - 48px)"
          minHeight="500px"
          display="flex"
        >
          <RecentTelemetryBody
            handleClose={handleClose}
            workingQuery={workingQuery}
            setWorkingQuery={setWorkingQuery}
          />
          <Box paddingLeft={3} paddingRight={3}>
            <ProcessorTelemetrySwitcher setWorkingQuery={setWorkingQuery} />
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

const RecentTelemetryBody: React.FC<{
  handleClose: () => void;
  setWorkingQuery: (query: string) => void;
  workingQuery?: string;
}> = ({ handleClose, setWorkingQuery, workingQuery }) => {
  const { logs, metrics, traces, footer } = useSnapshot();
  return (
    <DialogContainer
      title="Recent Telemetry"
      description="Showing a snapshot of recent telemetry taken before it is sent to a destination"
      onClose={handleClose}
    >
      <Stack spacing={1} height={"100%"}>
        <SnapShotControls
          workingQuery={workingQuery}
          setWorkingQuery={setWorkingQuery}
        />
        <SnapshotConsole
          logs={logs}
          metrics={metrics}
          traces={traces}
          footer={footer}
          readOnly={true}
        />
      </Stack>
    </DialogContainer>
  );
};
