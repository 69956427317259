import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { forwardRef } from "react";
import {
  Kind,
  ProcessorRecommendation,
  ResourceConfiguration,
  useGetResourceTypeQuery,
} from "../../graphql/generated";
import colors from "../../styles/colors";
import { BPResourceConfiguration } from "../../utils/classes";
import { HelpCircleIcon, ProcessorIcon, WandIcon, XIcon } from "../Icons";
import { useSnapshot } from "../SnapShotConsole/SnapshotContext";
import styles from "./resource-configuration-label-card.module.scss";

interface ProcessorRecCardProps {
  item: ProcessorRecommendation;
  onView: (rc: ResourceConfiguration) => void;
}

export const ProcessorRecCard: React.FC<ProcessorRecCardProps> = ({
  item,
  onView,
}) => {
  const { dismissRecommendation } = useSnapshot();

  function handleView() {
    const rc = new BPResourceConfiguration(item.resourceConfiguration);
    rc.displayName = item.displayName;
    rc.id = item.recommendationTypeId;
    onView(rc);
  }

  function handleDismiss() {
    dismissRecommendation(item.recommendationTypeId);
  }

  const { data } = useGetResourceTypeQuery({
    variables: {
      kind: Kind.ProcessorType,
      name: item.resourceConfiguration.type!,
    },
  });
  return (
    <Box>
      <Card variant="outlined" className={styles.recommendationCard}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ backgroundColor: "white", padding: "4px", borderRadius: "4px" }}
        >
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            paddingLeft="2px"
            marginRight={"8px"}
          >
            <WandIcon stroke={colors.pixelPointBlue} strokeWidth={0.5} />

            {data?.resourceType?.metadata.icon ? (
              <span
                className={styles.icon}
                style={{
                  backgroundImage: `url(${data?.resourceType?.metadata.icon})`,
                }}
              />
            ) : (
              <span className={styles.icon}>
                <ProcessorIcon />
              </span>
            )}
            <Typography>{item.displayName}</Typography>
          </Stack>

          <Stack
            direction="row"
            height="100%"
            alignItems="center"
            justifyContent="flex-end"
            spacing={1}
          >
            {item.acceptanceCriteria === "reduced" && (
              <Typography color={colors.malachite} fontWeight={600}>
                {(item.percentChange * 100).toFixed(1)}%
              </Typography>
            )}

            <Tooltip
              classes={{ tooltip: styles.transparentTooltip }}
              title={
                <Card>
                  <CardContent>
                    <Stack spacing={2}>
                      <Typography fontWeight={600}>
                        Why did we recommend this Processor?
                      </Typography>
                      <Typography variant="body2">
                        {item.description}
                      </Typography>
                    </Stack>
                  </CardContent>
                </Card>
              }
            >
              <HelpIcon />
            </Tooltip>

            <Button
              size="small"
              variant="contained"
              onClick={handleView}
              data-testid={`${item.recommendationTypeId}-view-btn`}
            >
              View
            </Button>

            <IconButton
              onClick={handleDismiss}
              sx={{ height: 32 }}
              data-testid={`${item.recommendationTypeId}-dismiss-btn`}
            >
              <XIcon width="18px" />
            </IconButton>
          </Stack>
        </Stack>
      </Card>
    </Box>
  );
};

const HelpIcon = forwardRef(function HelpIcon(
  props,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  return (
    <div ref={ref} {...props}>
      <HelpCircleIcon color={colors.pixelPointBlue} height="20px" />
    </div>
  );
});
