import { gql } from "@apollo/client";
import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useGetConfigurationMonitoringSummaryQuery } from "../../graphql/generated";
import { CONFIGURATIONS_PAGE_QUERY_PARAM } from "../../pages/configurations";
import colors from "../../styles/colors";
import { NumberChip } from "../NumberChip";

gql`
  query GetConfigurationMonitoringSummary {
    configurationMonitoring {
      total
      errored
      pending
    }
  }
`;

export const ConfigurationMonitoring: React.FC = () => {
  const navigate = useNavigate();

  const { data, loading } = useGetConfigurationMonitoringSummaryQuery({
    onError(err) {
      console.error(err);
    },
    fetchPolicy: "network-only",
    pollInterval: 5000,
  });

  function onTotalClick() {
    navigate("/configurations");
  }

  function onPendingClick() {
    const params = new URLSearchParams();
    params.set(CONFIGURATIONS_PAGE_QUERY_PARAM, "rollout-status:Pending");
    navigate(`/configurations?${params.toString()}`);
  }

  function onErroredClick() {
    const params = new URLSearchParams();
    params.set(CONFIGURATIONS_PAGE_QUERY_PARAM, "rollout-status:Error");
    navigate(`/configurations?${params.toString()}`);
  }

  return (
    <div>
      <Typography variant="h6" fontSize={18} marginBottom={"8px"}>
        Configurations
      </Typography>

      <Stack direction="row" spacing={3}>
        <NumberChip
          loading={loading}
          label="Total"
          value={data?.configurationMonitoring.total}
          onClick={onTotalClick}
        />
        <NumberChip
          loading={loading}
          label="Pending Rollout"
          value={data?.configurationMonitoring.pending}
          color={colors.neonCarrot}
          onClick={onPendingClick}
        />
        <NumberChip
          loading={loading}
          label="Errored Rollout"
          value={data?.configurationMonitoring.errored}
          color={"error"}
          onClick={onErroredClick}
        />
      </Stack>

      <Stack direction="row" flexWrap="wrap"></Stack>
    </div>
  );
};
