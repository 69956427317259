import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { trim } from "lodash";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { createNewOrganization } from "../../utils/rest/new-organization";
import { useWizard } from "../Wizard/WizardContext";
import { OnboardingWizardValues } from "./types";
import styles from "./onboarding-steps.module.scss";

/**
 * NewProjectStep is the last step in either onboarding flow.  It creates an organization
 * and project.
 */
export const NewProjectStep: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { setValues, setTouched, goToStep, step, formValues, formErrors } =
    useWizard<OnboardingWizardValues>();
  const navigate = useNavigate();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newAccName = e.target.value;
    setValues({ projectName: newAccName });
  };

  async function handleDoneClick() {
    if (formErrors.projectName || formErrors.organizationName) {
      return;
    }

    const sanitizedAcc = trim(formValues.projectName);

    try {
      const resp = await createNewOrganization({
        organizationName: formValues.organizationName,
        accountName: sanitizedAcc,
        eulaAccepted: formValues.acceptEula,
      });
      if (!resp.ok) {
        throw new Error(
          `Failed to create organization, got status ${resp.status}`,
        );
      }
      navigate("/agents");
    } catch (e) {
      enqueueSnackbar("Oops, something went wrong. Please try again.", {
        variant: "error",
      });
      console.error(e);
    }
  }

  const doneDisabled = formValues.projectName === "";

  return (
    <>
      <div className={styles.stepContainer}>
        <Typography variant="h6" fontWeight={600}>
          Start your first project in BindPlane
        </Typography>

        <Typography marginBottom={4}>
          Your Organization in BindPlane is made up of <strong>Projects</strong>
          . Each Project in BindPlane manages a set of Agents and Resources.
        </Typography>

        <Stack justifyContent="start" alignItems="start">
          <Stack width="100%">
            <Typography variant="h6" fontWeight={600}>
              Pick a name for your first Project
            </Typography>
            <Typography marginBottom={2}>
              This could be the name of a team or a specific project you're
              working on.
            </Typography>
          </Stack>

          <Box width={400}>
            <TextField
              fullWidth
              size="small"
              label="Project Name"
              value={formValues.projectName}
              onChange={onChange}
              onKeyUp={() => setTouched({ projectName: true })}
              error={!!formErrors.projectName}
              helperText={formErrors.projectName}
            />
          </Box>
        </Stack>
      </div>

      <Stack direction="row" width="100%" justifyContent="flex-end" spacing={2}>
        <Button variant="outlined" onClick={() => goToStep(step - 1)}>
          Back
        </Button>
        <Button
          variant="contained"
          onClick={handleDoneClick}
          disabled={doneDisabled}
        >
          Let's Go
        </Button>
      </Stack>
    </>
  );
};
