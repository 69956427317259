import { dismissedRecommendationStorage } from "../../../utils/dismissed-recommendations/dismissed-recommendations";
import { CardMeasurementContent } from "../../CardMeasurementContent/CardMeasurementContent";
import { ProcessorCard } from "../../Cards/ProcessorCard";
import { useV2PipelineGraph } from "../PipelineGraphV2Context";
import { AttributeName } from "../types";

export function ProcessorNodeV2({
  data,
}: {
  data: {
    id: string;
    metric: string;
    attributes: Record<string, any>;
  };
}) {
  const { id, metric, attributes } = data;
  const { configuration, editProcessors } = useV2PipelineGraph();

  const isSource = isSourceID(id);

  let processorCount = 0;
  let resourceIndex = -1;
  let name = "";

  if (isSource) {
    name = getSourceName(id);
    if (typeof attributes["sourceIndex"] === "number") {
      resourceIndex = attributes[AttributeName.SourceIndex];
    }
    const sources = configuration?.spec?.sources ?? [];
    processorCount = sources[resourceIndex]?.processors?.length ?? 0;
  } else {
    name = getDestinationName(id);
    if (typeof attributes["destinationIndex"] === "number") {
      resourceIndex = attributes[AttributeName.DestinationIndex];
    }

    const destinations = configuration?.spec?.destinations ?? [];
    processorCount = destinations[resourceIndex]?.processors?.length ?? 0;
  }

  let recommendation = false;
  if (typeof attributes["recommendations"] === "object") {
    // we have recommendations, check each one to see if it's dismissed
    recommendation = true;
    const dismissed = dismissedRecommendationStorage.getDismissed(name);
    const recIDs = attributes[AttributeName.Recommendations];
    for (const recID in recIDs) {
      if (dismissed.includes(recID)) {
        recommendation = false;
      } else {
        // at least one recommendation is not dismissed
        recommendation = true;
        break;
      }
    }
  }

  return (
    <>
      <ProcessorCard
        editProcessors={editProcessors}
        processorCount={processorCount}
        resourceType={isSource ? "source" : "destination"}
        resourceIndex={resourceIndex}
        recommendation={recommendation}
      />
      <CardMeasurementContent>{metric}</CardMeasurementContent>
    </>
  );
}

function isSourceID(id: string): boolean {
  return id.startsWith("source/");
}

function getDestinationName(id: string): string {
  // /destination/name/processors
  const REGEX = /^destination\/(?<name>.*)\/processors$/;
  const match = id.match(REGEX);
  return match?.groups?.["name"] ?? "";
}

function getSourceName(id: string): string {
  // /source/name/processors
  const REGEX = /^source\/(?<name>.*)\/processors$/;
  const match = id.match(REGEX);
  return match?.groups?.["name"] ?? "";
}
