import { Kind, Source } from "../../graphql/generated";
import { BPBaseResource } from "./base-resource";

type MinimumSource = Pick<Source, "spec" | "metadata">;

export class BPSource extends BPBaseResource implements Source {
  __typename?: Kind.Source | undefined;

  constructor(s: MinimumSource) {
    super();
    this.kind = Kind.Source;
    this.metadata = s.metadata;
    this.spec = s.spec;
  }
}
