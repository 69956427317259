import { cloneDeep } from "lodash";
import { MinimumRequiredConfig } from "../../components/PipelineGraph/PipelineGraph";
import { V2Config } from "../../components/PipelineGraphV2/types";
import {
  Configuration,
  ConfigurationSpec,
  Kind,
  Metadata,
  ResourceConfiguration,
} from "../../graphql/generated";
import { APIVersion, ResourceStatus } from "../../types/resources";
import { applyResources } from "../rest/apply-resources";
import { BPResourceConfiguration } from "./resource-configuration";

export class BPConfiguration
  implements Pick<Configuration, "apiVersion" | "kind" | "metadata" | "spec">
{
  apiVersion: string;
  kind: Kind;
  spec: ConfigurationSpec;
  metadata: Metadata;
  constructor(configuration: MinimumRequiredConfig) {
    this.apiVersion = configuration?.apiVersion ?? APIVersion.V1;
    this.kind = Kind.Configuration;
    this.spec = configuration?.spec ?? {
      measurementInterval: "",
      raw: "",
      sources: [],
      destinations: [],
      extensions: [],
      rollout: { disabled: false },
    };
    this.metadata = configuration?.metadata ?? {
      name: "",
      id: "",
      version: 1,
    };
  }

  name(): string {
    return this.metadata.name;
  }

  isRaw(): boolean {
    return this.spec.raw != null && this.spec.raw.length > 0;
  }

  isModular(): boolean {
    return !this.isRaw();
  }

  isV2(): boolean {
    return this.apiVersion === APIVersion.V2Alpha;
  }

  isV1(): boolean {
    return this.apiVersion === APIVersion.V1;
  }

  addSource(rc: ResourceConfiguration) {
    const newSources = this.spec.sources ? [...this.spec.sources] : [];
    newSources.push(rc);

    const newSpec = cloneDeep(this.spec);
    newSpec.sources = newSources;

    this.spec = newSpec;
  }

  /**
   * @param rc New source
   * @param id ID of the source to be replaced
   * @throws Error if the source with the given ID is not found
   */
  replaceSource(rc: ResourceConfiguration, id: string) {
    const newSources = this.spec.sources ? [...this.spec.sources] : [];
    const idx = this.spec.sources?.findIndex((s) => s.id === id);
    if (idx == null) {
      throw new Error(`failed to find source with id ${id}`);
    }
    newSources[idx] = rc;

    const newSpec = cloneDeep(this.spec);
    newSpec.sources = newSources;

    this.spec = newSpec;
  }

  /**
   * @param id ID of the source to be removed
   * @throws Error if the source with the given ID is not found
   */
  removeSource(id: string) {
    const newSources = this.spec.sources ? [...this.spec.sources] : [];

    const idx = this.spec.sources?.findIndex((s) => s.id === id);
    if (idx == null) {
      throw new Error(`failed to find source with id ${id}`);
    }
    newSources.splice(idx, 1);

    const newSpec = cloneDeep(this.spec);
    newSpec.sources = newSources;

    this.spec = newSpec;
  }

  addDestination(rc: ResourceConfiguration) {
    const newDestinations = this.spec.destinations
      ? [...this.spec.destinations]
      : [];
    newDestinations.push(rc);

    const newSpec = cloneDeep(this.spec);
    newSpec.destinations = newDestinations;

    this.spec = newSpec;
  }

  replaceDestination(rc: ResourceConfiguration, ix: number) {
    const newDestinations = this.spec.destinations
      ? [...this.spec.destinations]
      : [];
    newDestinations[ix] = rc;

    const newSpec = cloneDeep(this.spec);
    newSpec.destinations = newDestinations;

    this.spec = newSpec;
  }

  removeDestination(ix: number) {
    const destinationResourceConfig = this.spec.destinations?.[ix];
    if (destinationResourceConfig == null) {
      throw new Error(`failed to find destination at index ${ix}`);
    }

    if (this.isV2()) {
      const destinationRC = new BPResourceConfiguration(
        destinationResourceConfig,
      );

      this.removeComponentPathFromAllRoutes(
        destinationRC.componentPath("destinations"),
      );
    }

    const newDestinations = this.spec.destinations
      ? [...this.spec.destinations]
      : [];
    newDestinations.splice(ix, 1);

    const newSpec = cloneDeep(this.spec);
    newSpec.destinations = newDestinations;
    this.spec = newSpec;
  }

  updateMeasurementInterval(measurementInterval: string) {
    const newSpec = cloneDeep(this.spec);
    newSpec.measurementInterval = measurementInterval;
    this.spec = newSpec;
  }

  // Adds key value pairs to the selector match label field.
  // Will override any existing labels with that key.
  addMatchLabels(labels: Record<string, string>) {
    this.spec.selector = {
      matchLabels: {
        ...this.spec.selector?.matchLabels,
        ...labels,
      },
    };
  }

  setExtensions(extensions: ResourceConfiguration[]) {
    const newSpec = cloneDeep(this.spec);
    newSpec.extensions = extensions;
    this.spec = newSpec;
  }

  setRollout(rollout: ResourceConfiguration) {
    const newSpec = cloneDeep(this.spec);
    newSpec.rollout = rollout;
    this.spec = newSpec;
  }

  async apply(): Promise<ResourceStatus> {
    const { updates } = await applyResources([this]);
    const update = updates.find(
      (u) => u.resource.metadata.name === this.name(),
    );

    if (update == null) {
      throw new Error(
        `failed to apply updated configuration, no update with name ${this.name()} returned.`,
      );
    }

    return update;
  }

  /**
   * setRaw sets value on the spec.raw field.
   * @param value The raw configuration string to set.
   */
  setRaw(value: string) {
    const newSpec = cloneDeep(this.spec);
    newSpec.raw = value;
    this.spec = newSpec;
  }

  /**
   * getSourceProcessors returns the processors for the source with the given ID.
   * @param sourceId The ID of the source to get processors for.
   */
  getSourceProcessors(sourceId: string): ResourceConfiguration[] {
    const source = this.spec.sources?.find((s) => s.id === sourceId);
    return source?.processors ?? [];
  }
  // _________________________ Routing Methods _________________________ //

  /**
   * removeComponentPathFromAllRoutes removes the componentPath from the routes of
   * all the resourceConfigurations in the configuration.
   * Used when deleting a component from a configuration, e.g. removing a destination.
   */
  removeComponentPathFromAllRoutes(componentPath: string) {
    const newSources: BPResourceConfiguration[] = [];
    for (const source of this.spec.sources ?? []) {
      const newRC = new BPResourceConfiguration(source);
      newRC.removeComponentPathFromAllPipelines(componentPath);
      newSources.push(newRC);
    }

    const newSpec = cloneDeep(this.spec);
    newSpec.sources = newSources;
    this.spec = newSpec;
  }

  /**
   * removeComponentPathFromRC removes the componentPath from the routes of the component of type 'kind' at index.
   * Used when deleting one edge of a connection.
   *
   * @param kind "source" | "destination" | "processor"
   * @param index the index of the component
   * @param componentPath the component path to remove from the component of type kind at index
   * @param telemetryType the telemetry type to remove the component path from
   */
  removeComponentPathFromRC(
    kind: "source" | "destination" | "processor",
    index: number,
    componentPath: string,
    telemetryType: string,
  ) {
    const resourceConfig = getRCFromSpec(this.spec, kind, index);
    if (resourceConfig == null) {
      throw new Error(
        `failed to find resource configuration at index ${index}`,
      );
    }
    resourceConfig.removeComponentPathFromPipeline(
      telemetryType,
      componentPath,
    );

    const newSpec = cloneDeep(this.spec);
    switch (kind) {
      case "source":
        const newSources = [...(newSpec.sources ?? [])];
        newSources[index] = resourceConfig;
        newSpec.sources = newSources;
        this.spec = newSpec;
        break;
      default:
        throw new Error(`unsupported kind ${kind}`);
    }
  }

  addComponentRoute(
    kind: "source" | "destination" | "processor",
    index: number,
    componentPath: string,
    telemetryType: string,
  ) {
    const resourceConfig = getRCFromSpec(this.spec, kind, index);
    if (resourceConfig == null) {
      throw new Error(
        `failed to find resource configuration at index ${index}`,
      );
    }
    resourceConfig.addRoute(telemetryType, componentPath);

    const newSpec = cloneDeep(this.spec);
    switch (kind) {
      case "source":
        const newSources = [...(this.spec.sources ?? [])];
        newSources[index] = resourceConfig;
        newSpec.sources = newSources;
        this.spec = newSpec;
        break;
      default:
        throw new Error(`unsupported kind ${kind}`);
    }
  }
}

/**
 * getRCFromSpec returns the resource configuration at the given index and kind.
 */
export function getRCFromSpec(
  spec: ConfigurationSpec,
  kind: string,
  index: number,
) {
  let rc: ResourceConfiguration | null = null;
  switch (kind) {
    case "source":
      const sources = spec.sources ?? [];
      rc = sources[index];
      break;
    case "destination":
      const destinations = spec.destinations ?? [];
      rc = destinations[index];
      break;
    case "processor":
      const processors = spec.sources?.[index]?.processors ?? [];
      rc = processors[0];
      break;
    default:
      throw new Error(`unsupported kind ${kind}`);
  }

  if (rc == null) {
    return null;
  }

  return new BPResourceConfiguration(rc);
}

export function getResourceConfigByComponentPath(
  configuration: NonNullable<V2Config>,
  componentPath: string,
): BPResourceConfiguration | null {
  const [location, id] = componentPath.split("/");
  if (!["sources", "destinations", "processors"].includes(location)) {
    return null;
  }

  let resourceConfigs: ResourceConfiguration[] = [];
  switch (location) {
    case "sources":
      resourceConfigs = configuration.spec.sources ?? [];
      break;
    case "destinations":
      resourceConfigs = configuration.spec.destinations ?? [];
      break;
    case "processors":
      resourceConfigs = configuration.spec.processors ?? [];
      break;
    default:
      return null;
  }

  for (const rc of resourceConfigs) {
    if (rc.id === id) {
      return new BPResourceConfiguration(rc);
    }
  }
  return null;
}
