import { gql } from "@apollo/client";
import { Alert, Box, Button, Stack, Typography } from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import { isFunction } from "lodash";
import { useSnackbar } from "notistack";
import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CardContainer } from "../../components/CardContainer";
import { ConfirmDeleteResourceDialog } from "../../components/ConfirmDeleteResourceDialog";
import { EEAgentsTable } from "../../components/EEAgentsTable";
import { withEENavBar } from "../../components/EENavBar";
import { PlusCircleIcon } from "../../components/Icons";
import { LimitConnectedAgentsDialog } from "../../components/LimitConnectedAgentsDialog";
import { MultiAgentLabelEditor } from "../../components/MultiAgentLabelEditor";
import { RBACWrapper } from "../../components/RBACWrapper/RBACWrapper";
import { withRBAC } from "../../contexts/RBAC";
import { withRequireLogin } from "../../contexts/RequireLogin";
import { Role, useAgentsPageAgentLimitQuery } from "../../graphql/generated";
import { useAppBarHeight } from "../../hooks/useAppBarHeight";
import { useRole } from "../../hooks/useRole";
import { hasPermission } from "../../utils/has-permission";
import { deleteAgents } from "../../utils/rest/delete-agents";
import { upgradeAgents } from "../../utils/rest/upgrade-agent";
import { classes } from "../../utils/styles";
import mixins from "../../styles/mixins.module.scss";
import styles from "./agent-banner.module.scss";

gql`
  query AgentsPageAgentLimit {
    agentLimitInfo {
      agentLimit
      agentCount
    }

    organization {
      metadata {
        id
        name
        version
      }
      licenseType
    }
  }
`;

export const AGENTS_PAGE_QUERY_PARAM = "query";

export const AgentsPageContent: React.FC = () => {
  const [updatable, setUpdatable] = useState<GridRowSelectionModel>([]);
  const [deletable, setDeletable] = useState<GridRowSelectionModel>([]);
  const [labelable, setLabelable] = useState<GridRowSelectionModel>([]);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const [connectedAgentCount, setConnectedAgentCount] = useState(0);
  const [agentLimit, setAgentLimit] = useState(0);
  const [licenseType, setLicenseType] = useState("Free");
  const [limitedAgentsOpen, setLimitedAgentsOpen] = useState(false);
  const [selectedAgentLabels, setSelectedAgentLabels] = useState<
    Record<string, string>[]
  >([]);

  const clearSelectionModelFnRef = useRef<(() => void) | null>(null);

  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const role = useRole();
  const navigate = useNavigate();
  const appBarHeight = useAppBarHeight();

  useAgentsPageAgentLimitQuery({
    onCompleted(data) {
      setConnectedAgentCount(data.agentLimitInfo.agentCount);
      setAgentLimit(data.agentLimitInfo.agentLimit);
      setLicenseType(data.organization.licenseType);
    },
    fetchPolicy: "network-only",
    onError(err) {
      console.error(err);
    },
    pollInterval: 5000,
  });

  function handleSelectUpdatable(agentIds: GridRowSelectionModel) {
    setUpdatable(agentIds);
  }
  function handleSelectDeletable(agentIds: GridRowSelectionModel) {
    setDeletable(agentIds);
  }

  function handleSelectLabelable(
    agentIds: GridRowSelectionModel,
    agentLabels: Record<string, string>[],
  ) {
    setLabelable(agentIds);
    setSelectedAgentLabels(agentLabels);
  }

  async function handleInstallAgentClick() {
    if (agentLimit !== 0 && connectedAgentCount >= agentLimit) {
      setLimitedAgentsOpen(true);
      return;
    }
    navigate("/agents/install");
  }

  async function handleDeleteAgents() {
    try {
      await deleteAgents(deletable as string[]);
      clearSelected();
      setDeleteConfirmOpen(false);
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to delete agents.", { variant: "error" });
    }
  }

  async function handleUpgradeAgents() {
    try {
      const errors = await upgradeAgents(updatable as string[]);

      if (isFunction(clearSelectionModelFnRef.current)) {
        clearSelectionModelFnRef.current();
      }

      clearSelected();

      if (errors.length > 0) {
        console.error("Upgrade errors.", { errors });
      }
    } catch (err) {
      enqueueSnackbar("Failed to send upgrade request.", {
        variant: "error",
        key: "Failed to send upgrade request.",
      });
    }
  }

  function clearSelected() {
    setDeletable([]);
    setLabelable([]);
    setUpdatable([]);
    if (isFunction(clearSelectionModelFnRef.current)) {
      clearSelectionModelFnRef.current();
    }
  }

  const initQuery =
    new URLSearchParams(location.search).get(AGENTS_PAGE_QUERY_PARAM) ?? "";

  return (
    <>
      {/* --------------------- Delete Button and Confirmation --------------------- */}
      <ConfirmDeleteResourceDialog
        onDelete={handleDeleteAgents}
        onCancel={() => setDeleteConfirmOpen(false)}
        action={"delete"}
        open={deleteConfirmOpen}
        title={`Delete ${deletable.length} Disconnected Agent${
          deletable.length > 1 ? "s" : ""
        }?`}
      >
        <>
          <Typography>
            Agents will reappear in BindPlane OP if reconnected.
          </Typography>
        </>
      </ConfirmDeleteResourceDialog>
      {/* -------------------------- Agent Limit Dialogue -------------------------- */}
      <LimitConnectedAgentsDialog
        onClose={() => setLimitedAgentsOpen(false)}
        open={limitedAgentsOpen}
        agentLimit={agentLimit}
        licenseType={licenseType}
      />
      {/* --------------------------- Agent Limit Banner --------------------------- */}
      {agentLimit !== 0 && connectedAgentCount >= agentLimit && (
        <Alert
          severity="info"
          className={classes([styles.banner])}
          action={
            <Button
              color="inherit"
              variant="outlined"
              style={{ marginRight: 16, marginBottom: 4, width: 120 }}
              href="mailto:support@observiq.com"
            >
              Contact Us
            </Button>
          }
        >
          <Typography>
            You've reached the <strong>{agentLimit}-agent limit</strong> of your{" "}
            <strong>{licenseType}</strong> license. If you'd like to manage more
            agents, please reach out to our team and we can upgrade your
            license.
          </Typography>
        </Alert>
      )}
      <CardContainer>
        <Box style={{ height: `calc(100vh - ${appBarHeight + 108}px` }}>
          <Stack height="100%">
            {/* --------------------- Upgrade Button and Confirmation ---------------------  */}
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              height="48px"
              marginBottom="16px"
            >
              <Typography variant="h5">Agents</Typography>
              <Stack direction={"row"} spacing={2} justifyContent="flex-end">
                {labelable.length > 0 && (
                  <MultiAgentLabelEditor
                    agentIds={labelable as string[]}
                    selectedAgentLabels={selectedAgentLabels}
                    onSave={clearSelected}
                  />
                )}

                {updatable.length > 0 && (
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={handleUpgradeAgents}
                  >
                    Upgrade {updatable.length} Outdated Agent
                    {updatable.length > 1 && "s"}
                  </Button>
                )}
                {deletable.length > 0 ? (
                  <Button
                    size="small"
                    variant="contained"
                    color="error"
                    onClick={() => setDeleteConfirmOpen(true)}
                  >
                    Delete {deletable.length} Disconnected Agent
                    {deletable.length > 1 && "s"}
                  </Button>
                ) : (
                  <RBACWrapper requiredRole={Role.User}>
                    <Button
                      size="small"
                      variant={"contained"}
                      classes={{ root: mixins["float-right"] }}
                      onClick={() => handleInstallAgentClick()}
                      startIcon={<PlusCircleIcon height={20} width={20} />}
                      data-testid="install-agent-button"
                    >
                      Install Agent
                    </Button>
                  </RBACWrapper>
                )}
              </Stack>
            </Stack>
            <Stack height="calc(100% - 64px)">
              <EEAgentsTable
                allowSelection={hasPermission(Role.User, role)}
                onDeletableAgentsSelected={handleSelectDeletable}
                onUpdatableAgentsSelected={handleSelectUpdatable}
                onLabelableAgentsSelected={handleSelectLabelable}
                clearSelectionModelFnRef={clearSelectionModelFnRef}
                initQuery={initQuery}
                urlQuerySearchParam={AGENTS_PAGE_QUERY_PARAM}
                onboardingNoRowsOverlay
              />
            </Stack>
          </Stack>
        </Box>
      </CardContainer>
    </>
  );
};

export const AgentsPage = withRequireLogin(
  withRBAC(withEENavBar(AgentsPageContent)),
);
