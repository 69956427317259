import { gql } from "@apollo/client";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Dialog, DialogContent, DialogProps, Stack, Tab } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { useState } from "react";
import { Role } from "../../graphql/generated";
import { InviteByEmailForm, InviteByLinkForm } from "./forms";
import { Errors, Touched, Values } from "./types";
import { enableEmailInvitations } from "./utils";

gql`
  mutation newInvitation($input: NewInvitationInput) {
    newInvitation(input: $input) {
      id
      accountId
      createdAt
      redeemedAt
      redeemedBy
      link
    }
  }
`;

const DEFAULT_VALUES: Values = {
  email: "",
  note: "",
  role: Role.User,
};

const DEFAULT_ERRORS: Errors = {
  email: null,
  note: null,
  role: null,
};

const DEFAULT_TOUCHED: Touched = {
  email: false,
  note: false,
  role: false,
};

interface InviteUsersDialogProps extends DialogProps {
  onCancel: () => void;
}

export const InviteUserDialog: React.FC<InviteUsersDialogProps> = ({
  onClose,
  onCancel,
  ...rest
}) => {
  const allowEmail = enableEmailInvitations();
  const [tab, setTab] = useState("email");
  const [values, setValues] = useState<Values>(DEFAULT_VALUES);
  const [errors, setErrors] = useState<Errors>(DEFAULT_ERRORS);
  const [touched, setTouched] = useState<Touched>(DEFAULT_TOUCHED);

  function handleValueChange(fieldName: string, value: string) {
    setValues((prev) => ({ ...prev, [fieldName]: value }));
  }

  function handleErrorChange(fieldName: string, error: string | null) {
    setErrors((prev) => ({ ...prev, [fieldName]: error }));
  }

  function handleTouchedChange(fieldName: string, touched: boolean) {
    setTouched((prev) => ({ ...prev, [fieldName]: touched }));
  }

  function clearForm() {
    setValues(DEFAULT_VALUES);
    setErrors(DEFAULT_ERRORS);
    setTouched(DEFAULT_TOUCHED);
    setTab("email");
  }

  function handleClose(event: {}, reason: "backdropClick" | "escapeKeyDown") {
    onClose && onClose(event, reason);
  }

  function handleCancel() {
    onCancel();
  }

  const transitionProps: TransitionProps = {
    onExited: () => {
      clearForm();
    },
  };

  if (!allowEmail) {
    return (
      <Dialog
        {...rest}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        TransitionProps={transitionProps}
      >
        <DialogContent>
          <InviteByLinkForm
            values={values}
            touched={touched}
            errors={errors}
            onClose={handleClose}
            onCancel={handleCancel}
            onValueChange={handleValueChange}
            onErrorChange={handleErrorChange}
            onTouchedChange={handleTouchedChange}
          />
        </DialogContent>
      </Dialog>
    );
  }

  function handleTabChange(_: React.SyntheticEvent, newValue: string) {
    setTab(newValue);
  }

  return (
    <Dialog
      {...rest}
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      TransitionProps={transitionProps}
    >
      <TabContext value={tab}>
        <Stack alignItems="center" marginTop={2}>
          <TabList
            onChange={handleTabChange}
            aria-label="Select invitation method"
          >
            <Tab label="Invite by Email" value="email" />
            <Tab label="Invite by Link" value="link" />
          </TabList>
        </Stack>

        <TabPanel value="email">
          <InviteByEmailForm
            values={values}
            touched={touched}
            errors={errors}
            onClose={handleClose}
            onCancel={handleCancel}
            onValueChange={handleValueChange}
            onErrorChange={handleErrorChange}
            onTouchedChange={handleTouchedChange}
          />
        </TabPanel>
        <TabPanel value="link">
          <InviteByLinkForm
            values={values}
            touched={touched}
            errors={errors}
            onClose={handleClose}
            onCancel={handleCancel}
            onValueChange={handleValueChange}
            onErrorChange={handleErrorChange}
            onTouchedChange={handleTouchedChange}
          />
        </TabPanel>
      </TabContext>
    </Dialog>
  );
};
