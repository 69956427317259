import { gql } from "@apollo/client";
import { useSnackbar } from "notistack";
import { createContext } from "react";
import { useContext, useEffect } from "react";
import { Role, useGetUserRoleLazyQuery } from "../graphql/generated";

export type Roles = Role | "single-user";

interface RBACContextValue {
  role: Roles;
}

const defaultValue: RBACContextValue = {
  role: "single-user",
};

export const RBACContext = createContext(defaultValue);

/**
 * RBACContextProvider is a wrapper that provides the RBACContext.
 * It lazily calls getUserRole if the user is logged in and accounts are enabled.
 * It will render null until the query is complete.
 */
export const RBACContextProvider: React.FC = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [getUserRole, { data }] = useGetUserRoleLazyQuery({
    onError: (err) => {
      console.error(err);
      // Don't toast an error for network errors.  It's common
      // that this could be called after a user gets kicked to the login
      // page after a 401 response from a query.
      if (err.networkError != null) {
        return;
      }

      enqueueSnackbar("Error getting user role", {
        variant: "error",
        key: "get-user-role-error",
      });
    },
  });

  useEffect(() => {
    if (localStorage.getItem("user") == null) {
      return;
    }

    getUserRole({
      fetchPolicy: "cache-and-network",
    });
  }, [getUserRole]);

  if (data == null) {
    return null;
  }

  return (
    <RBACContext.Provider value={{ role: data.user.role }}>
      <>{children}</>
    </RBACContext.Provider>
  );
};

/**
 * useRole returns the role value of the RBACContext.
 */
export function useRole(): Roles {
  const value = useContext(RBACContext);
  return value.role;
}

/**
 * hasPermission returns true if the user has the required role or greater.
 * @param requiredRole
 * @param testRole
 * @returns boolean
 */
export function hasPermission(requiredRole: Role, testRole: Roles): boolean {
  if (testRole === "single-user") {
    return true;
  }

  if (testRole === Role.Admin) {
    return true;
  }

  if (testRole === Role.User) {
    return requiredRole === Role.User || requiredRole === Role.Viewer;
  }

  return false;
}

interface RBACWrapperProps {
  requiredRole: Role;
}

/**
 * RBACWrapper is a wrapper component that will only render its children if the
 * user has the required role.
 */
export const RBACWrapper: React.FC<RBACWrapperProps> = ({
  children,
  requiredRole,
}) => {
  const role = useRole();

  if (hasPermission(requiredRole, role)) {
    return <>{children}</>;
  }

  return null;
};

gql`
  query GetUserRole {
    user {
      metadata {
        name
        id
        version
      }
      role
    }
  }
`;

/**
 * withRBAC wraps the functional component in a RBACContextProvider.
 */
export function withRBAC(FC: React.FC): React.FC {
  return () => (
    <RBACContextProvider>
      <FC />
    </RBACContextProvider>
  );
}
