import { Box, Button, Paper, Popover, Typography } from "@mui/material";
import { isFunction } from "lodash";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { classes } from "../../utils/styles";
import mixins from "../../styles/mixins.module.scss";
import styles from "./code-block.module.scss";

interface CodeBlockProps {
  value: string;
  // optional class passed to the Paper component
  paperClass?: string;
  // optional callback called when the copy button is clicked
  onCopyClick?: () => void;
  // if present, the download button will be shown
  onDownloadClick?: () => void;
}

/**
 * CodeBlock displays a code block with a copy button and an optional download button.
 * The copy button is always displayed and will call the onCopyClick callback when clicked.
 * The download button is optional and will call the onDownloadClick callback when clicked.
 * @param props - CodeBlockProps
 */
export const CodeBlock: React.FC<CodeBlockProps> = ({
  value,
  paperClass,
  onCopyClick,
  onDownloadClick,
}) => {
  const [copyAnchorEl, setCopyAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);

  function handleCopyClick(event: React.MouseEvent<HTMLButtonElement>) {
    setCopyAnchorEl(event.currentTarget);
    setTimeout(() => setCopyAnchorEl(null), 750);
    isFunction(onCopyClick) && onCopyClick();
  }

  const copyButton: JSX.Element = (
    <>
      <Popover
        anchorEl={copyAnchorEl}
        open={Boolean(copyAnchorEl)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Typography classes={{ root: mixins["m-2"] }}>Copied!</Typography>
      </Popover>

      <CopyToClipboard text={value}>
        <Button size="small" variant="text" onClick={handleCopyClick}>
          Copy
        </Button>
      </CopyToClipboard>
    </>
  );

  return (
    <Paper
      variant="outlined"
      classes={{ root: classes([styles.paper, paperClass]) }}
    >
      <Box component="div" className={styles["block-header"]}>
        {onDownloadClick && (
          <Button size="small" onClick={onDownloadClick}>
            Download
          </Button>
        )}
        {copyButton}
      </Box>
      <Box component="div" className={styles["block-content"]}>
        {value}
      </Box>
    </Paper>
  );
};
