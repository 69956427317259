import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { deleteOrganization } from "../../../utils/rest/delete-organization";

export interface DeleteOrganizationDialogProps extends DialogProps {
  organizationId: string;
  onDeleteSuccess: () => void;
  onClose: () => void;
}

enum View {
  Confirmation,
  Challenge,
}

export const DeleteOrganizationDialog: React.FC<
  DeleteOrganizationDialogProps
> = ({ organizationId, onClose, onDeleteSuccess, ...dialogProps }) => {
  const [view, setView] = useState<View>(View.Confirmation);
  const [challengeValue, setChallengeValue] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  function clearForm() {
    setView(View.Confirmation);
    setChallengeValue("");
  }

  // This function should be unused with above html removed.
  async function handleDeleteOrganization() {
    try {
      const response = await deleteOrganization(organizationId);
      if (!response.ok) {
        throw new Error(
          `Failed to delete organization, got status: ${response.status}`,
        );
      }
      onDeleteSuccess();
    } catch (error) {
      enqueueSnackbar(error, { variant: "error" });
    }
  }
  return (
    <Dialog
      {...dialogProps}
      onClose={onClose}
      TransitionProps={{ onExited: clearForm }}
    >
      <DialogTitle>
        Are you sure you want to delete this organization?
      </DialogTitle>
      {view === View.Confirmation && (
        <>
          <DialogContent style={{ width: 600 }}>
            <Typography>
              Deleting this organization will delete all projects inside the
              organization. Any agents that are currently managed by these
              projects will enter an unmanaged state.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={() => setView(View.Challenge)}>
              Yes, continue to delete my organization
            </Button>
            <Button onClick={onClose}>
              No, I do not want to delete my organization
            </Button>
          </DialogActions>
        </>
      )}

      {view === View.Challenge && (
        <>
          <DialogContent style={{ width: 600 }}>
            <Box padding={"4px"}></Box>
            <TextField
              name="challenge"
              label="Type 'DELETE' to confirm"
              value={challengeValue}
              onChange={(event) => setChallengeValue(event.target.value)}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              onClick={handleDeleteOrganization}
              disabled={challengeValue !== "DELETE"}
            >
              Delete my organization
            </Button>
            <Button onClick={onClose}>
              No, I do not want to delete my organization
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
