import { ApolloError, gql } from "@apollo/client";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogProps,
  DialogTitle,
  Stack,
} from "@mui/material";
import { capitalize, isFunction } from "lodash";
import { useSnackbar } from "notistack";
import { memo, useEffect, useMemo, useState } from "react";
import {
  Parameter,
  ParameterType,
  RolloutType,
  useRolloutOptionsDialogQuery,
} from "../../../graphql/generated";
import { ParameterDefinition } from "../../../graphql/generated";
import { trimVersion } from "../../../utils/version-helpers";
import {
  FormValues,
  ValidationContextProvider,
  initFormValues,
  isValid,
  useValidationContext,
} from "../../ResourceConfigForm";
import { EnumParamInput } from "../../ResourceConfigForm/ParameterInput";
import { ParameterSection } from "../../ResourceConfigForm/ParameterSection";
import {
  FormValueContextProvider,
  useResourceFormValues,
} from "../../ResourceConfigForm/ResourceFormContext";
import { initFormErrors } from "../../ResourceConfigForm/init-form-values";
import { groupParameters } from "../../ResourceConfigForm/utils";

gql`
  query RolloutOptionsDialog($configName: String!) {
    configuration(name: $configName) {
      apiVersion
      kind
      metadata {
        id
        name
        version
      }
      spec {
        rollout {
          id
          name
          type
          parameters {
            name
            value
          }
          disabled
        }
      }
    }

    rolloutTypes {
      apiVersion
      kind
      metadata {
        id
        name
        displayName
        description
        version
      }
      spec {
        version
        parameters {
          name
          label
          description
          type
          required
          options {
            gridColumns
          }
        }
      }
    }
  }
`;

interface RolloutOptionsDialogProps extends DialogProps {
  configName: string;
  onCancel: () => void;
  onSave: (rt: string, formValues: FormValues) => void;
  readOnly?: boolean;
}

/**
 * RolloutOptionsDialog
 *
 * @param configName The name of the config to query rollout options from
 * @returns
 */
export const RolloutOptionsDialog: React.FC<RolloutOptionsDialogProps> = (
  props,
) => {
  const { configName } = props;

  const [rolloutTypes, setRolloutTypes] = useState<RolloutType[] | null>();
  const [rolloutType, setRolloutType] = useState<string | null>();
  const [parameterDefinitions, setParameterDefinitions] = useState<
    ParameterDefinition[] | null
  >();
  const [rolloutTypeDescription, setRolloutTypeDescription] = useState<
    string | null
  >();
  const [parameterValues, setParameterValues] = useState<Parameter[] | null>();

  const { enqueueSnackbar } = useSnackbar();

  useRolloutOptionsDialogQuery({
    variables: {
      configName: trimVersion(configName),
    },
    onCompleted(data) {
      setRolloutTypes(data?.rolloutTypes);
      setRolloutType(data?.configuration?.spec?.rollout?.type || "standard");
      setParameterValues(data?.configuration?.spec?.rollout?.parameters || []);
    },
    onError(e: ApolloError) {
      console.error(e);
      enqueueSnackbar("Failed to fetch Rollout Options", { variant: "error" });
    },
  });

  // When picking a different rolloutType, recompute the parameters to render for that type
  useEffect(() => {
    let definitions: ParameterDefinition[] = [];
    let description: string = "";
    rolloutTypes?.forEach((rt) => {
      if (rt.metadata.name === rolloutType) {
        definitions = rt.spec.parameters;
        description = rt.metadata.description || "";
      }
    });
    setParameterDefinitions(definitions);
    setRolloutTypeDescription(description);
  }, [rolloutType, rolloutTypes]);

  if (
    rolloutTypes == null ||
    rolloutType == null ||
    parameterDefinitions == null ||
    parameterValues == null
  ) {
    return null;
  }

  let initValues: FormValues = initFormValues(
    parameterDefinitions,
    parameterValues,
  );

  if (initValues == null) {
    return null;
  }

  return (
    <FormValueContextProvider initValues={initValues}>
      <ValidationContextProvider
        definitions={parameterDefinitions}
        initErrors={{}}
      >
        <MemoizedComponent
          parameterDefinitions={parameterDefinitions}
          rolloutTypes={rolloutTypes}
          rolloutType={rolloutType}
          rolloutTypeDescription={rolloutTypeDescription || ""}
          setRolloutType={setRolloutType}
          {...props}
        />
      </ValidationContextProvider>
    </FormValueContextProvider>
  );
};

interface ComponentProps extends RolloutOptionsDialogProps {
  parameterDefinitions: ParameterDefinition[];
  rolloutTypes: RolloutType[];
  rolloutType: string;
  rolloutTypeDescription: string;
  setRolloutType: (t: string) => void;
}

/**
 * RolloutOptionsDialogComponent
 *
 * @param parameterDefinitions
 * @param rolloutTypes The list of rollout types to render in the dialog
 * @param rolloutType The current rollout type for the configuration (might be null)
 * @param rolloutTypeDescription The description for the current rollout type
 * @param setRolloutType Set the rollout type to display
 * @returns
 */
export const RolloutOptionsDialogComponent: React.FC<ComponentProps> = ({
  parameterDefinitions,
  rolloutTypes,
  rolloutType,
  setRolloutType,
  rolloutTypeDescription,
  open,
  readOnly,
  onCancel,
  onSave,
}) => {
  const { formValues } = useResourceFormValues();
  const { setErrors, touchAll, clearTouched } = useValidationContext();

  function handleCancel() {
    setErrors({});
    clearTouched();
    onCancel();
  }

  function handleSave() {
    const errors = initFormErrors(parameterDefinitions, formValues);

    if (!isValid(errors)) {
      touchAll();
      setErrors(errors);
      return;
    }

    isFunction(onSave) && onSave(rolloutType, formValues);
  }

  const rolloutTypeEnumParameterDefinition = {
    name: "rolloutType",
    label: "Rollout Type",
    description: rolloutTypeDescription,
    type: ParameterType.Enum,
    validValues: rolloutTypes.map((t: RolloutType) => {
      return capitalize(t.metadata.name);
    }),
    options: { creatable: false },
    required: false,
  };

  const groups = useMemo(
    () => groupParameters(parameterDefinitions),
    [parameterDefinitions],
  );

  return (
    <Dialog open={open} data-testid="rollout-options-dialog" fullWidth>
      <DialogTitle>Rollout Options</DialogTitle>
      <DialogContent>
        <Stack spacing={1} marginTop={"8px"}>
          <EnumParamInput
            definition={rolloutTypeEnumParameterDefinition}
            value={capitalize(rolloutType)}
            onValueChange={(v: string) => setRolloutType(v.toLowerCase())}
            readOnly={readOnly}
            data-testid="rollout-options-dialog-rollout-type-enum"
          />
          {groups.map((g, ix) => (
            <ParameterSection
              key={`param-group-${ix}`}
              group={g}
              readOnly={readOnly}
            />
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCancel}
          data-testid="rollout-options-dialog-cancel"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          data-testid="rollout-options-dialog-save"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const MemoizedComponent = memo(RolloutOptionsDialogComponent);
