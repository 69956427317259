import { gql } from "@apollo/client";
import {
  Box,
  CircularProgress,
  FormControl,
  Grid2 as Grid,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import {
  useDestinationsQuery,
  useGetSummaryGraphQuery,
} from "../../graphql/generated";
import {
  ALL_DESTINATIONS_VALUE,
  SummaryInterval,
  SummaryPeriod,
  useSummaryPageContext,
} from "../../pages/overview/SummaryPage/SummaryPageContext";
import colors from "../../styles/colors";
import { formatBytes } from "../../utils/graph/utils";
import { DataSummaryGraph } from "../DataSummaryGraph";
import { DestinationsIcon } from "../Icons";
import { DestinationTypeCell } from "../Tables/DestinationsTable/cells";
import mixins from "../../styles/mixins.module.scss";

gql`
  query GetSummaryGraph(
    $period: String!
    $interval: String!
    $telemetryType: String!
    $destinationIDs: [ID!]
  ) {
    summaryGraphData(
      period: $period
      interval: $interval
      telemetryType: $telemetryType
      destinationIDs: $destinationIDs
    ) {
      totalIngest
      totalEgress
      dataset {
        timestamp
        s0Value
        d1Value
      }
    }
  }
  subscription SummaryGraphData(
    $period: String!
    $interval: String!
    $telemetryType: String!
    $destinationIDs: [ID!]
  ) {
    summaryGraphData(
      period: $period
      interval: $interval
      telemetryType: $telemetryType
      destinationIDs: $destinationIDs
    ) {
      totalIngest
      totalEgress
      dataset {
        timestamp
        s0Value
        d1Value
      }
    }
  }
`;

const intervalsToPeriods: Record<SummaryInterval, SummaryPeriod> = {
  [SummaryInterval.OneDay]: SummaryPeriod.OneHour,
  [SummaryInterval.ThirtyDays]: SummaryPeriod.OneDay,
  [SummaryInterval.NinetyDays]: SummaryPeriod.OneDay,
};

export const DataSummarySection: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    measurementInterval,
    onMeasurementIntervalChange,
    telemetryType,
    onTelemetryTypeChange,
    period,
    onPeriodChange,
    destination,
    onDestinationChange,
  } = useSummaryPageContext();

  const [reduction, setReduction] = useState(0);
  const { data: destinationsData } = useDestinationsQuery({
    variables: {
      filterUnused: true,
      filterGateways: true,
    },
    fetchPolicy: "network-only",
    onError(error) {
      console.error(error);
      enqueueSnackbar("Destinations unavailable.", { variant: "error" });
    },
  });

  const { data, loading } = useGetSummaryGraphQuery({
    variables: {
      period: period,
      interval: measurementInterval,
      telemetryType: telemetryType!,
      destinationIDs:
        destination === ALL_DESTINATIONS_VALUE ? [] : [destination!],
    },
    // Wait for destination to be set by the context before fetching.
    skip: destination == null || telemetryType == null,
    fetchPolicy: "network-only",
    onError(err) {
      enqueueSnackbar("Summary Graph unavailable.", { variant: "error" });
      console.error(err);
    },
    onCompleted(data) {
      const { totalIngest, totalEgress } = data.summaryGraphData;

      if (totalIngest === 0) {
        setReduction(0);
        return;
      }

      const rounded = Math.round((totalEgress / totalIngest) * 10000) / 100;
      setReduction(100 - rounded);
    },
  });

  return (
    <>
      <Stack
        classes={{ root: mixins["float-right"] }}
        direction="row-reverse"
        marginTop={-7}
        spacing={1}
      >
        <TextField
          value={measurementInterval}
          onChange={(e) => {
            onMeasurementIntervalChange(e.target.value as SummaryInterval);
            onPeriodChange(
              intervalsToPeriods[e.target.value as SummaryInterval],
            );
          }}
          style={{ minWidth: 150 }}
          size="small"
          label="Interval"
          name="interval"
          select
        >
          {Object.entries({
            "24 hours": SummaryInterval.OneDay,
            "30 days": SummaryInterval.ThirtyDays,
            "90 days": SummaryInterval.NinetyDays,
          }).map(([label, value]) => (
            <MenuItem key={label} value={value}>
              {label}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          value={telemetryType ?? "logs"}
          onChange={(e) => onTelemetryTypeChange(e.target.value)}
          style={{ minWidth: 150 }}
          size="small"
          label="Telemetry Type"
          name="telemetry"
          select
        >
          {Object.entries({
            Logs: "logs",
            Metrics: "metrics",
            Traces: "traces",
          }).map(([label, value]) => (
            <MenuItem key={label} value={value}>
              {label}
            </MenuItem>
          ))}
        </TextField>
      </Stack>

      {loading ? (
        <Stack height={400} alignItems={"center"} justifyContent={"center"}>
          <CircularProgress size={100} />
        </Stack>
      ) : (
        <DataSummaryGraph
          period={period}
          interval={measurementInterval}
          summaryGraphData={
            data?.summaryGraphData ?? {
              dataset: [],
              totalIngest: 0,
              totalEgress: 0,
            }
          }
        />
      )}
      <Grid container spacing={2} style={{ width: "100%" }}>
        <Grid size={{ xs: 4 }}>
          <Stack>
            <Box height={36}>
              {loading ? (
                <CircularProgress />
              ) : (
                <Typography fontSize={24}>
                  {/* TODO: Make the unit size a smaller font */}
                  {formatBytes(data?.summaryGraphData.totalIngest ?? 0)}
                </Typography>
              )}
            </Box>
            <Stack direction="row" alignItems="center">
              <Typography align="right" fontSize={18}>
                Data in
              </Typography>
              <Box
                width={20}
                height={20}
                bgcolor={colors.black}
                marginLeft={1}
              />
            </Stack>
          </Stack>
        </Grid>
        <Grid size={{ xs: 4 }}>
          <Stack justifyContent="center" alignContent="center">
            <Typography
              color={reduction > 0 ? "green" : "grey"}
              variant="h3"
              align="center"
            >
              {Math.abs(reduction).toFixed(2)}%
            </Typography>
            <Typography fontSize={18} align="center">
              {reduction >= 0 ? "Reduction" : "Increase"}
            </Typography>
          </Stack>
        </Grid>
        <Grid size={{ xs: 4 }}>
          <Stack width="100%" className="foo">
            <Box height={36}>
              {loading ? (
                <CircularProgress />
              ) : (
                <Typography align="right" fontSize={24}>
                  {formatBytes(data?.summaryGraphData.totalEgress ?? 0)}
                </Typography>
              )}
            </Box>

            <Stack
              direction="row-reverse"
              alignItems={"center"}
              marginBottom={"24px"}
            >
              <Box
                width={20}
                height={20}
                bgcolor={colors.pixelPointBlue}
                marginLeft={1}
              />
              <Typography align="right" fontSize={18}>
                Data out
              </Typography>
            </Stack>
            <FormControl style={{ marginLeft: "auto" }}>
              <InputLabel>Destination</InputLabel>

              {destination != null && destinationsData !== undefined && (
                <Select
                  value={destination ?? ALL_DESTINATIONS_VALUE}
                  onChange={(e) => onDestinationChange(e.target.value)}
                  size="small"
                  label="Destination"
                  name="destination"
                  sx={{ width: 200, height: 40 }}
                >
                  <MenuItem
                    key={ALL_DESTINATIONS_VALUE}
                    value={ALL_DESTINATIONS_VALUE}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      height={40}
                    >
                      <DestinationsIcon
                        width={"40px"}
                        height="20px"
                        stroke={colors.darkestGray}
                      />
                      <ListItemText primary="All destinations" />
                    </Stack>
                  </MenuItem>
                  {destinationsData.destinations.map((d) => (
                    <MenuItem key={d.metadata.name} value={d.metadata.name}>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <ListItemIcon>
                          <DestinationTypeCell type={d.spec.type} icon />
                        </ListItemIcon>
                        <ListItemText primary={d.metadata.name} />
                      </Stack>
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormControl>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
