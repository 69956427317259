import { pad } from "lodash";
import { Node } from "../../graphql/generated";
import { BPGraph, GraphPath, NodeId } from "./graph";

interface Position {
  x: number;
  y: number;
}
interface Size {
  width: number;
  height: number;
}

const NODE_SPACING = { width: 250, height: 200 };
const RESOURCE_PROCESSOR_ADJUSTMENT = -60;

function size(nodeType?: string): Size {
  switch (nodeType) {
    case "sourceNode":
    case "destinationNode":
      return { width: 120, height: 125 };
    case "processorNode":
    case "dummyProcessorNode":
      return { width: 62, height: 62 };
    case "uiControlNode":
      return { width: 42, height: 42 };
    case "connectorNode":
      return { width: 150, height: 40 };
    default:
      return { width: 120, height: 120 };
  }
}

function move(nodeType?: string): Size {
  switch (nodeType) {
    case "sourceNode":
    case "destinationNode":
      return { width: 0, height: 0 };
    case "processorNode":
    case "dummyProcessorNode":
      return { width: 0, height: 0 };
    case "uiControlNode":
      return { width: 0, height: -30 };
    default:
      return { width: 0, height: 0 };
  }
}

function center(position: Position, nodeType?: string): Position {
  const sz = size(nodeType);
  return {
    x: position.x - sz.width / 2,
    y: position.y - sz.height / 2,
  };
}

export class LayoutGridRow {
  public row: NodeId[];
  constructor(width: number) {
    this.row = new Array(width).fill("");
  }

  overlaps(other: LayoutGridRow): boolean {
    for (let i = 0; i < this.row.length; i++) {
      if (this.row[i] !== "" && other.row[i] !== "") {
        return true;
      }
    }
    return false;
  }

  merge(other: LayoutGridRow): boolean {
    if (this.overlaps(other)) {
      return false;
    }
    for (let i = 0; i < this.row.length; i++) {
      if (other.row[i] !== "") {
        this.row[i] = other.row[i];
      }
    }
    return true;
  }

  clear(): void {
    for (let i = 0; i < this.row.length; i++) {
      this.row[i] = "";
    }
  }

  empty(): boolean {
    return this.row.every((id) => id === "");
  }

  get first(): NodeId {
    return this.row[0];
  }
  set first(id: NodeId) {
    this.row[0] = id;
  }
  get last(): NodeId {
    return this.row[this.row.length - 1];
  }
  set last(id: NodeId) {
    this.row[this.row.length - 1] = id;
  }
}

export class LayoutGrid {
  // grid[y][x]
  private grid: LayoutGridRow[];
  private width: number = 0;

  constructor(public graph: BPGraph) {
    this.grid = this.naivelyPositionNodes();
    this.width = this.grid[0]?.row.length ?? 0;

    this.log("naivelyPositionNodes");

    this.insertBlankRows();
    this.log("insertBlankRows");

    this.removeDuplicateNodes();
    this.log("removeDuplicateNodes");

    this.slideDestinationRight();
    this.log("slideDestinationRight");

    this.slideResourcesUp();
    this.log("slideResourcesUp  ");

    this.deleteBlankRows();
    this.log("deleteBlankRows");

    this.addPlusButtons();
    this.log("addPlusButtons");

    this.stretchWidth();
    this.log("stretchWidth");
  }

  console(text: string) {
    // console.log(text);
  }

  log(name: string) {
    this.console(name);
    this.console(
      this.grid
        .map(
          (row, i) => i + ": " + row.row.map((id) => pad(id, 40)).join(" | "),
        )
        .join("\n"),
    );
  }

  logPaths(paths: GraphPath[]) {
    this.console(
      paths
        .map(
          (path, i) =>
            i + ": " + path.path.map((id) => pad(id, 40)).join(" > "),
        )
        .join("\n"),
    );
  }

  adjust(x: number, position: Position, nodeType?: string): Position {
    // special adjustment to pull resource processors closer to the resource
    let xAdjust = 0;
    if (x === this.width - 1) {
      xAdjust = RESOURCE_PROCESSOR_ADJUSTMENT * 2;
    } else if (x > 0) {
      xAdjust = RESOURCE_PROCESSOR_ADJUSTMENT;
    }

    const mv = move(nodeType);
    return center(
      {
        x: position.x + mv.width + xAdjust,
        y: position.y + mv.height,
      },
      nodeType,
    );
  }

  naivelyPositionNodes(): LayoutGridRow[] {
    const paths = this.graph.getPaths();
    // make sure we have at least 4 columns
    if (paths.length === 0) {
      paths.push(
        new GraphPath([
          "add-source",
          "add-source-proc",
          "add-destination-proc",
          "add-destination",
        ]),
      );
    } else if (this.graph.getSources().length === 0) {
      for (let i = 0; i < paths.length; i++) {
        paths[i] = paths[i].prepend("add-source", "add-source-proc");
      }
    } else if (this.graph.getDestinations().length === 0) {
      for (let i = 0; i < paths.length; i++) {
        paths[i] = paths[i].append("add-destination-proc", "add-destination");
      }
    }
    this.logPaths(paths);

    const width = paths.reduce(
      (max, path) => Math.max(max, path.path.length),
      0,
    );

    const height = paths.length;

    const grid = newGrid({ width, height });

    for (let i = 0; i < paths.length; i++) {
      const path = paths[i];
      for (let j = 0; j < path.path.length; j++) {
        const id = path.path[j];
        grid[i].row[j] = id;
      }
    }

    return grid;
  }

  // insert blank rows inserts a new blank row after each existing row. this allows nodes
  // to be moved a half step later.
  insertBlankRows(): void {
    const grid: LayoutGridRow[] = [];
    for (let y = 0; y < this.grid.length; y++) {
      grid.push(this.grid[y]);
      grid.push(new LayoutGridRow(this.width));
    }
    this.grid = grid;
  }

  removeDuplicateNodes(): void {
    // find ideal positions
    //
    // for each node id, could the number of times it occurs in the grid and average the
    // vertical position. if after removing duplicates this position is available, we will
    // move it there.
    const idealPositions = new IdealPositions(this.grid);

    const nodes = new Set<string>();
    for (let y = 0; y < this.grid.length; y++) {
      const row = this.grid[y].row;
      for (let x = row.length - 1; x >= 0; x--) {
        const id = row[x];
        if (id === "") {
          continue;
        }
        if (nodes.has(id)) {
          row[x] = "";
        } else {
          nodes.add(id);
        }
      }
    }

    // go through the grid again and try to put nodes in ideal positions
    for (let y = 0; y < this.grid.length; y++) {
      const row = this.grid[y].row;
      for (let x = row.length - 1; x >= 0; x--) {
        const nodeId = row[x];
        this.move({ x, y }, idealPositions.get(nodeId));
      }
    }
  }

  slideDestinationRight(): void {
    for (let y = 0; y < this.grid.length; y++) {
      const row = this.grid[y].row;
      let lastEmpty = row.length;
      for (let x = row.length - 1; x >= 0; x--) {
        if (row[x] !== "") {
          lastEmpty = x + 1;
          break;
        }
      }
      if (lastEmpty >= row.length) {
        continue;
      }

      if (this.graph.findNode(row[lastEmpty - 1])?.type !== "destinationNode") {
        continue;
      }

      this.move({ x: lastEmpty - 1, y }, { x: row.length - 1, y });
      this.move({ x: lastEmpty - 2, y }, { x: row.length - 2, y });
    }
  }

  slideResourcesUp(): void {
    for (let y = 0; y < this.grid.length - 1; y++) {
      const cur = this.grid[y];
      const next = this.grid[y + 1];
      if (cur.merge(next)) {
        this.grid.splice(y + 1, 1);
        y--;
      }
    }
  }

  deleteBlankRows(): void {
    this.grid = this.grid.filter((row) => !row.empty());
  }

  stretchWidth(): void {
    const minWidth = 6;
    if (this.width >= minWidth) {
      return;
    }

    // insert columns in the middle to stretch the width of the graph
    const insertX = Math.floor(this.width / 2);
    const insertCount = minWidth - this.width;

    for (let y = 0; y < this.grid.length; y++) {
      const row = this.grid[y].row;
      row.splice(insertX, 0, ...new Array(insertCount).fill(""));
    }
    this.width = this.grid[0].row.length;
  }

  addPlusButtons(): void {
    this.grid.push(new LayoutGridRow(this.width));
    // add plus buttons to first empty cells before the end in the first and last columns
    for (let y = this.grid.length - 2; y >= 0; y--) {
      if (this.grid[y].first !== "") {
        this.grid[y + 1].first = "add-source";
        break;
      }
    }
    for (let y = this.grid.length - 2; y >= 0; y--) {
      if (this.grid[y].last !== "") {
        this.grid[y + 1].last = "add-destination";
        break;
      }
    }
  }

  // ----------------------------------------------------------------------

  getPosition(id: NodeId, nodeType?: string): Position {
    for (let y = 0; y < this.grid.length; y++) {
      const row = this.grid[y].row;
      for (let x = 0; x < row.length; x++) {
        if (row[x] === id) {
          return this.adjust(
            x,
            {
              x: x * NODE_SPACING.width,
              y: y * NODE_SPACING.height,
            },
            nodeType ?? this.graph.findNode(id)?.type,
          );
        }
      }
    }
    return { x: 0, y: 0 };
  }

  setNodePosition(node: Node): void {
    const position = this.getPosition(node.id);
    if (node.attributes == null) {
      node.attributes = {};
    }
    node.attributes.position = position;
  }

  // ----------------------------------------------------------------------

  /**
   * Move a nodeId to a new position in the grid.
   * @returns false if the new position is occupied.
   */
  move(oldPosition: Position, newPosition: Position): boolean {
    // no movement is considered successful
    if (oldPosition.x === newPosition.x && oldPosition.y === newPosition.y) {
      return true;
    }
    // make sure the new space is not occupied.
    if (this.getNodeId(newPosition) !== "") {
      return false;
    }
    // move the nodeId
    this.setNodeId(newPosition, this.getNodeId(oldPosition));
    this.setNodeId(oldPosition, "");
    return true;
  }

  getNodeId(position: Position): NodeId {
    return this.grid[position.y].row[position.x];
  }

  setNodeId(position: Position, nodeId: NodeId) {
    this.grid[position.y].row[position.x] = nodeId;
  }
}

function newGrid(size: { width: number; height: number }): LayoutGridRow[] {
  const grid: LayoutGridRow[] = [];
  for (let i = 0; i < size.height; i++) {
    grid.push(new LayoutGridRow(size.width));
  }
  return grid;
}

interface IdealPosition {
  count: number;
  position: Position;
}

class IdealPositions {
  private nodes: { [nodeId: string]: IdealPosition } = {};

  constructor(grid: LayoutGridRow[]) {
    for (let y = 0; y < grid.length; y++) {
      for (let x = 0; x < grid[y].row.length; x++) {
        this.add(grid[y].row[x], { x, y });
      }
    }
  }

  get(nodeId: string): Position {
    const node = this.nodes[nodeId];
    return {
      x: node.position.x,
      y: Math.floor(node.position.y / node.count),
    };
  }

  add(nodeId: string, position: Position) {
    const node = this.nodes[nodeId];
    if (node == null) {
      // first encounter
      this.nodes[nodeId] = {
        position: position,
        count: 1,
      };
    } else {
      // add it
      node.position = {
        x: Math.max(node.position.x, position.x),
        y: node.position.y + position.y,
      };
      node.count++;
    }
  }
}
