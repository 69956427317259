import { gql } from "@apollo/client";
import { Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import {
  Kind,
  Role,
  useSourceTypeQuery,
  useSourcesQuery,
} from "../../graphql/generated";
import { useRole } from "../../hooks/useRole";
import { UpdateStatus } from "../../types/resources";
import {
  BPConfiguration,
  BPResourceConfiguration,
  BPSource,
} from "../../utils/classes";
import { hasPermission } from "../../utils/has-permission";
import { applyResources } from "../../utils/rest/apply-resources";
import { ConfirmDeleteResourceDialog } from "../ConfirmDeleteResourceDialog";
import { MinimumRequiredConfig } from "../PipelineGraph/PipelineGraph";
import { DialogResource } from "../ResourceDialog";
import { EditResourceDialog } from "../ResourceDialog/EditResourceDialog";
import { ResourceCard } from "./ResourceCard";

gql`
  query SourceType($name: String!) {
    sourceType(name: $name) {
      metadata {
        id
        name
        version
        displayName
        icon
        displayName
        description
        additionalInfo {
          message
          documentation {
            text
            url
          }
        }
        resourceDocLink
      }
      spec {
        parameters {
          label
          name
          description
          required
          type
          default
          documentation {
            text
            url
          }
          relevantIf {
            name
            operator
            value
          }
          advancedConfig
          validValues
          options {
            creatable
            trackUnchecked
            sectionHeader
            subHeader
            horizontalDivider
            gridColumns
            labels
            metricCategories {
              label
              column
              metrics {
                name
                description
                kpi
              }
            }
            password
            sensitive
            variant
          }
        }
      }
    }
  }
`;

interface InlineSourceCardProps {
  id: string;
  disabled?: boolean;
  configuration: MinimumRequiredConfig;
  refetchConfiguration: () => void;
  readOnly?: boolean;
  onDeleteSuccess?: () => void;
}

export const InlineSourceCard: React.FC<InlineSourceCardProps> = ({
  id,
  disabled,
  configuration,
  readOnly,
  refetchConfiguration,
  onDeleteSuccess,
}) => {
  const sourceId = id.replace(/source[0-9]+_/, "");
  const role = useRole();

  const source = configuration?.spec?.sources!.find((s) => s.id === sourceId);
  const name = source?.type || "";

  const { data, refetch: refetchSource } = useSourceTypeQuery({
    variables: { name },
  });

  const [editing, setEditing] = useState(false);
  const [confirmDeleteOpen, setDeleteOpen] = useState(false);

  const [librarySources, setLibrarySources] = useState<DialogResource[]>();

  useSourcesQuery({
    onCompleted: (data) => {
      setLibrarySources(
        data.sources.map((s) => {
          return {
            metadata: {
              name: s.metadata.name,
            },
            spec: s.spec,
          };
        }),
      );
    },
    onError: (e) => {
      console.error(e);
      enqueueSnackbar("Failed to fetch Library sources", {
        variant: "error",
      });
    },
  });

  const { enqueueSnackbar } = useSnackbar();

  const icon = data?.sourceType?.metadata.icon;
  const resourceTypeDisplayName = data?.sourceType?.metadata.displayName ?? "";
  const additionalInfo = data?.sourceType?.metadata?.additionalInfo;
  const resourceDocLink = data?.sourceType?.metadata?.resourceDocLink ?? "";

  function closeEditDialog() {
    setEditing(false);
  }

  function closeDeleteDialog() {
    setDeleteOpen(false);
  }

  if (data?.sourceType == null) {
    return null;
  }

  async function onSave(values: { [key: string]: any }) {
    const sourceConfig = new BPResourceConfiguration(source);
    sourceConfig.setParamsFromMap(values);

    const updatedConfig = new BPConfiguration(configuration);

    try {
      updatedConfig.replaceSource(sourceConfig, sourceId);
      const update = await updatedConfig.apply();
      if (update.status === UpdateStatus.INVALID) {
        console.error(update);
        throw new Error("failed to save source on configuration");
      }

      enqueueSnackbar("Successfully saved source!", {
        variant: "success",
        autoHideDuration: 3000,
      });
      closeEditDialog();
      refetchConfiguration();
    } catch (err) {
      enqueueSnackbar("Failed to save source.", {
        variant: "error",
        autoHideDuration: 5000,
      });
      console.error(err);
    }
  }

  async function onAddToLibrary(values: { [key: string]: any }, name: string) {
    const librarySource = new BPSource({
      metadata: {
        name: name,
        id: sourceId,
        version: 0,
        displayName: values.displayName,
      },
      spec: {
        parameters: [],
        type: data?.sourceType?.metadata.name!,
        disabled: source?.disabled!,
      },
    });

    librarySource.setParamsFromMap(values);

    try {
      await applyResources([librarySource]);
      enqueueSnackbar("Successfully added Source to Library!", {
        variant: "success",
        autoHideDuration: 3000,
      });
      closeEditDialog();
      refetchConfiguration();
    } catch (err) {
      enqueueSnackbar("Failed to add Source to Library.", {
        variant: "error",
        autoHideDuration: 5000,
      });
      console.error(err);
      return;
    }

    const updatedConfig = new BPConfiguration(configuration);

    const updatedSource = new BPResourceConfiguration({
      name: librarySource.metadata.name,
      id: sourceId,
      disabled: source?.disabled!,
      processors: source?.processors,
    });

    try {
      updatedConfig.replaceSource(updatedSource, sourceId);
      const update = await updatedConfig.apply();
      if (update.status === UpdateStatus.INVALID) {
        console.error(update);
        throw new Error("failed to save source on configuration");
      }
      refetchSource();
    } catch (err) {
      enqueueSnackbar(
        "Failed to update configuration with new Library Source",
        {
          variant: "error",
          autoHideDuration: 5000,
        },
      );
      console.error(err);
    }
  }

  /**
   * Toggle `disabled` on the source, replace it in the configuration, and save
   */
  async function onTogglePause() {
    const updatedConfig = new BPConfiguration(configuration);
    const updatedSource = new BPResourceConfiguration(source);
    updatedSource.disabled = !source?.disabled;

    const action = updatedSource.disabled ? "pause" : "resume";
    try {
      updatedConfig.replaceSource(updatedSource, sourceId);
      const { status, reason } = await updatedConfig.apply();
      if (status === UpdateStatus.INVALID) {
        throw new Error(
          `failed to ${action} source, configuration invalid, ${reason}`,
        );
      }

      enqueueSnackbar(`Successfully ${action}d source!`, {
        variant: "success",
        autoHideDuration: 3000,
      });
      closeEditDialog();
      refetchConfiguration();
    } catch (err) {
      enqueueSnackbar(`Failed to ${action} configuration.`, {
        variant: "error",
      });
      console.error(err);
    }
  }

  async function onDelete() {
    const updatedConfig = new BPConfiguration(configuration);

    try {
      updatedConfig.removeSource(sourceId);
      const { status, reason } = await updatedConfig.apply();
      if (status === UpdateStatus.INVALID) {
        throw new Error(
          `failed to update configuration, configuration invalid, ${reason}`,
        );
      }

      closeDeleteDialog();
      closeEditDialog();
      refetchConfiguration();
      onDeleteSuccess && onDeleteSuccess();
    } catch (err) {
      enqueueSnackbar("Failed to update configuration.", { variant: "error" });
      console.error(err);
    }
  }

  return (
    <>
      <ResourceCard
        dataTestID={`source-card-${id}`}
        name={resourceTypeDisplayName}
        displayName={source?.displayName ?? undefined}
        icon={icon}
        disabled={disabled || source?.disabled}
        paused={source?.disabled}
        onClick={() => setEditing(true)}
      />
      <EditResourceDialog
        resourceTypeDisplayName={resourceTypeDisplayName}
        description={data?.sourceType?.metadata.description ?? ""}
        additionalInfo={additionalInfo}
        resourceDocLink={resourceDocLink}
        displayName={source?.displayName ?? ""}
        kind={Kind.Source}
        parameters={source?.parameters ?? []}
        parameterDefinitions={data.sourceType.spec.parameters}
        open={editing}
        onClose={closeEditDialog}
        onCancel={closeEditDialog}
        onDelete={() => setDeleteOpen(true)}
        onSave={onSave}
        paused={source?.disabled}
        onTogglePause={onTogglePause}
        readOnly={readOnly || !hasPermission(Role.User, role)}
        showLibraryBookmark
        onAddToLibrary={onAddToLibrary}
        libraryResources={librarySources}
      />

      <ConfirmDeleteResourceDialog
        open={confirmDeleteOpen}
        onClose={closeDeleteDialog}
        onCancel={closeDeleteDialog}
        onDelete={onDelete}
        action={"remove"}
      >
        <Typography>Are you sure you want to remove this source?</Typography>
      </ConfirmDeleteResourceDialog>
    </>
  );
};
