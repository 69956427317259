import { isEmpty } from "lodash";
import {
  Maybe,
  Parameter,
  ResourceConfiguration,
  Routes,
} from "../../graphql/generated";
import { trimVersion } from "../version-helpers";

export class BPResourceConfiguration implements ResourceConfiguration {
  id?: Maybe<string> | undefined;
  name?: Maybe<string> | undefined;
  displayName: Maybe<string> | undefined;
  type?: Maybe<string> | undefined;
  parameters?: Maybe<Parameter[]> | undefined;
  processors?: Maybe<ResourceConfiguration[]> | undefined;
  disabled: boolean;
  recommendation?: Maybe<string> | undefined;
  routes?: Maybe<Routes> | undefined;
  constructor(rc?: ResourceConfiguration) {
    this.id = rc?.id;
    this.name = rc?.name;
    this.displayName = rc?.displayName;
    this.type = rc?.type;
    this.parameters = rc?.parameters;
    this.processors = rc?.processors;
    this.disabled = rc?.disabled ?? false;
    this.recommendation = rc?.recommendation;
    this.routes = rc?.routes;
  }

  isInline(): boolean {
    return isEmpty(this.name);
  }

  /**
   *
   * @returns the `id` field of the resource configuration and
   *  throws an error if its empty or null.
   */
  ID(): string {
    if (this.id == null || this.id === "") {
      throw new Error("ResourceConfiguration ID is null or empty");
    }
    return this.id;
  }

  /**
   * returns the component path of the resource configuration, i.e.
   * `sources/{id}`, `destinations/{id}`, or `processors/{id}`
   * @param componentType "sources", "destinations", or "processors"
   * @returns
   */
  componentPath(
    componentType: "sources" | "destinations" | "processors",
  ): string {
    return `${componentType}/${this.ID()}`;
  }

  // setParamsFromMap will set the parameters from Record<string, any>.
  // If the "name" key is specified it will set the name field of the ResourceConfiguration.
  // If the "processors" key is specified it will set the processors value.
  // It will not set undefined or null values to parameters.
  setParamsFromMap(map: Record<string, any>) {
    // Set name field if present
    if (map.name != null && map.name !== "") {
      this.name = map.name;
      delete map.name;
    }

    // Set displayName field if present
    if (map.displayName != null) {
      this.displayName = map.displayName;
      delete map.displayName;
    }

    // Set processors field if present
    if (map.processors != null) {
      this.processors = map.processors;
      delete map.processors;
    }

    // Set the recommendation field if present
    if (map.recommendation != null) {
      this.recommendation = map.recommendation;
      delete map.recommendation;
    }

    // Set the parameters only if their values are not nullish.
    const parameters = Object.entries(map).reduce<Parameter[]>(
      (params, [name, value]) => {
        if (value != null) {
          params.push({ name, value });
        }
        return params;
      },
      [],
    );

    this.parameters = parameters;
  }

  /**
   * removeComponentPathFromPipeline mutates the resource configuration to remove the componentPath from the pipeline of type telemetryType.
   */
  removeComponentPathFromPipeline(
    telemetryType: string,
    componentPath: string,
  ) {
    // We're going to 'normalize' the routes to just use the logs, metrics, and traces value.
    let nextLogs = getNextComponentPaths(this, "logs");
    let nextMetrics = getNextComponentPaths(this, "metrics");
    let nextTraces = getNextComponentPaths(this, "traces");

    // filter the necessary list based on the telemetry type
    switch (telemetryType) {
      case "logs":
        nextLogs = nextLogs.filter((c) => c !== componentPath);
        break;
      case "metrics":
        nextMetrics = nextMetrics.filter((c) => c !== componentPath);
        break;
      case "traces":
        nextTraces = nextTraces.filter((c) => c !== componentPath);
        break;
    }

    const newRoutes: Routes = {
      logs: [{ components: nextLogs }],
      metrics: [{ components: nextMetrics }],
      traces: [{ components: nextTraces }],
    };

    this.routes = newRoutes;
  }

  /**
   * removeComponentPathFromAllPipelines mutates the resource configuration to remove the componentPath from all pipeline types.
   */
  removeComponentPathFromAllPipelines(componentPath: string) {
    const logs = getNextComponentPaths(this, "logs").filter(
      (c) => c !== componentPath,
    );
    const metrics = getNextComponentPaths(this, "metrics").filter(
      (c) => c !== componentPath,
    );
    const traces = getNextComponentPaths(this, "traces").filter(
      (c) => c !== componentPath,
    );

    const newRoutes: Routes = {
      logs: logs.map((l) => ({ components: [l] })),
      metrics: metrics.map((l) => ({ components: [l] })),
      traces: traces.map((l) => ({ components: [l] })),
    };

    this.routes = newRoutes;
  }

  addRoute(telemetryType: string, componentPath: string) {
    let nextLogs = getNextComponentPaths(this, "logs");
    let nextMetrics = getNextComponentPaths(this, "metrics");
    let nextTraces = getNextComponentPaths(this, "traces");

    switch (telemetryType) {
      case "logs":
        nextLogs.push(componentPath);
        break;
      case "metrics":
        nextMetrics.push(componentPath);
        break;
      case "traces":
        nextTraces.push(componentPath);
        break;
    }

    const newRoutes: Routes = {
      logs: [{ components: nextLogs }],
      metrics: [{ components: nextMetrics }],
      traces: [{ components: nextTraces }],
    };

    this.routes = newRoutes;
  }
}

// isResourceType returns true if the specified ResourceConfiguration is of the given
// type, ignoring the version.
export function isResourceType(
  rc: ResourceConfiguration,
  type: string,
): boolean {
  return trimVersion(rc.type ?? "") === type;
}

// getParameterValue will return the value of the parameter with the given name or a
// defaultValue if it doesn't exist.
export function getParameterValue<T>(
  rc: ResourceConfiguration,
  name: string,
  defaultValue?: T,
): T | undefined {
  const parameter = rc.parameters?.find((p) => p.name === name);
  return parameter?.value ?? defaultValue;
}

// equalsTelemetryType will return true if the ResourceConfiguration has a single
// telemetry type parameter with the given value.
export function equalsTelemetryType(
  rc: ResourceConfiguration,
  telemetryType: string,
  name: string = "telemetry_types",
): boolean {
  const value = getParameterValue<string[]>(rc, name, []);
  return value?.length === 1 && value[0] === telemetryType;
}

// equalsParameterValue will return true if the parameter with the given name has the
// given value. null and undefined are treated as equal.
export function equalsParameterValue<T>(
  rc: ResourceConfiguration,
  name: string,
  value: T | undefined | null,
): boolean {
  if (value == null) {
    // handle value == null or value == undefined
    return getParameterValue(rc, name) == null;
  }
  return getParameterValue(rc, name) === value;
}

// editParameterValue will update the value of the parameter with the given name, adding a
// new parameter if it does not exist.
export function editParameterValue<T>(
  rc: ResourceConfiguration,
  name: string,
  updater: (value?: T) => T,
): T {
  const parameter = rc.parameters?.find((p) => p.name === name);
  if (parameter != null) {
    parameter.value = updater(parameter.value);
    return parameter.value;
  } else {
    if (rc.parameters == null) {
      rc.parameters = [];
    }
    const value = updater(undefined);
    rc.parameters.push({ name, value });
    return value;
  }
}

/**
 * getNextComponents returns all of the component paths that are in the routes of the resource configuration.
 *
 * @param rc a resource configuration
 * @param selectedTelemetry "logs", "metrics", or "traces"
 * @returns
 */
export function getNextComponentPaths(
  rc: ResourceConfiguration,
  selectedTelemetry: string,
) {
  const routes = getRoutesForResourceConfig(rc, selectedTelemetry);
  if (routes == null) return [];

  const componentPathMap: Record<string, any> = {};
  routes.forEach((r) => {
    for (const c of r.components) {
      componentPathMap[c] = null;
    }
  });

  return Object.keys(componentPathMap);
}

/**
 * getRoutesForResourceConfig returns the routes for the given telemetry type.
 */
export function getRoutesForResourceConfig(
  rc: ResourceConfiguration,
  selectedTelemetry: string,
) {
  switch (selectedTelemetry) {
    case "logs":
      return rc.routes?.logs ?? [];
    case "metrics":
      return rc.routes?.metrics ?? [];
    case "traces":
      return rc.routes?.traces ?? [];
    default:
      return [];
  }
}
