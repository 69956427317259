import { gql } from "@apollo/client";
import { Stack, Typography } from "@mui/material";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
} from "@mui/x-data-grid";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { useState } from "react";
import {
  GetInvitationsQuery,
  useDeleteInvitationMutation,
} from "../../graphql/generated";
import { ConfirmDeleteResourceDialog } from "../ConfirmDeleteResourceDialog";
import { InvitationOptions } from "./InvitationOptions";
import { StatusChip } from "./InvitationStatusChip";
import styles from "./invitations-table.module.scss";

gql`
  mutation deleteInvitation($input: DeleteInvitationInput!) {
    deleteInvitation(input: $input)
  }
`;

interface InvitationsTableProps {
  data?: GetInvitationsQuery;
  loading?: boolean;
}

export const InvitationsTable: React.FC<InvitationsTableProps> = ({
  data,
  loading,
}) => {
  const [deletingInvitationId, setDeletingInvitationId] = useState<
    null | string
  >(null);
  const { enqueueSnackbar } = useSnackbar();

  const [deleteInvitation] = useDeleteInvitationMutation({
    onCompleted() {
      enqueueSnackbar("Invitation deleted", {
        variant: "success",
        key: "delete-invitation",
      });
      setDeletingInvitationId(null);
    },
    onError(error) {
      console.error(error);
      enqueueSnackbar("Error deleting invitation", {
        variant: "error",
        key: "delete-invitation",
      });
      setDeletingInvitationId(null);
    },
  });

  async function handleDeleteInvitation() {
    if (deletingInvitationId === null) return;

    await deleteInvitation({
      variables: {
        input: {
          invitationId: deletingInvitationId,
        },
      },
    });
  }

  const columns: GridColDef[] = [
    {
      renderHeader,
      field: "note",
      flex: 1,
      headerName: "Note",
    },
    {
      renderHeader,
      field: "status",
      width: 200,
      headerName: "Status",
      renderCell: renderStatusCell,
      disableColumnMenu: true,
    },
    {
      field: "created",
      headerName: "Created",
      renderHeader,
      width: 125,
      renderCell: renderCreatedAt,
      valueGetter: (_, row) => row.createdAt,
      disableColumnMenu: true,
    },
    {
      renderHeader,
      field: "redeemedBy",
      flex: 1,
      headerName: "Redeemed By",
      disableColumnMenu: true,
    },
    {
      field: " ",
      disableColumnMenu: true,
      width: 50,
      renderCell: (params: GridCellParams) =>
        renderOptionsCell(params, () => setDeletingInvitationId(params.row.id)),
      sortable: false,
    },
  ];

  return (
    <>
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: "created", sort: "desc" }],
          },
        }}
        disableRowSelectionOnClick
        density="compact"
        columns={columns}
        autoHeight
        rows={data?.invitations ?? []}
        loading={loading}
        disableVirtualization={process.env.NODE_ENV === "test"}
        classes={{
          columnSeparator: styles.separator,
        }}
        sx={{
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
        }}
        slots={{
          noRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No Invitations
            </Stack>
          ),
        }}
        sortingOrder={["asc", "desc"]}
      />

      <ConfirmDeleteResourceDialog
        open={deletingInvitationId !== null}
        onDelete={handleDeleteInvitation}
        onCancel={() => setDeletingInvitationId(null)}
        action="delete"
        maxWidth="xs"
        fullWidth
      >
        <Typography variant={"body1"} marginBottom={0} textAlign={"center"}>
          Delete Invitation?
        </Typography>
      </ConfirmDeleteResourceDialog>
    </>
  );
};

function renderCreatedAt(params: GridCellParams) {
  return <>{format(new Date(params.row.createdAt ?? ""), "MM-dd-yy")}</>;
}

function renderStatusCell(
  params: GridCellParams<any, "outstanding" | "redeemed" | "expired">,
) {
  return <StatusChip status={params.value!} />;
}

function renderHeader(params: GridColumnHeaderParams) {
  return <Typography fontWeight={600}>{params.colDef.headerName}</Typography>;
}

function renderOptionsCell(params: GridCellParams, onDelete: () => void) {
  return (
    <InvitationOptions
      id={params.row.id}
      status={params.row.status}
      onDelete={onDelete}
      link={params.row.link}
    />
  );
}
