import { gql } from "@apollo/client";
import { Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { memo, useMemo, useState } from "react";
import {
  Kind,
  Role,
  useConfigurationCountQuery,
  useGetDestinationWithTypeQuery,
} from "../../graphql/generated";
import { useRole } from "../../hooks/useRole";
import { UpdateStatus } from "../../types/resources";
import { BPConfiguration, BPDestination } from "../../utils/classes";
import { hasPermission } from "../../utils/has-permission";
import { trimVersion } from "../../utils/version-helpers";
import { ConfirmDeleteResourceDialog } from "../ConfirmDeleteResourceDialog";
import { EditResourceInUseWarningDialog } from "../Dialogs/EditResourceInUseWarningDialog/EditResourceInUseWarningDialog";
import { MinimumRequiredConfig } from "../PipelineGraph/PipelineGraph";
import { FormValues } from "../ResourceConfigForm";
import { EditResourceDialog } from "../ResourceDialog/EditResourceDialog";
import { ResourceCard } from "./ResourceCard";
import { onDeleteFunc } from "./types";

gql`
  query getDestinationWithType($name: String!) {
    destinationWithType(name: $name) {
      destination {
        metadata {
          name
          version
          id
          labels
          version
        }
        spec {
          type
          parameters {
            name
            value
          }
          disabled
        }
      }
      destinationType {
        metadata {
          id
          name
          displayName
          version
          icon
          description
          stability
          additionalInfo {
            message
            documentation {
              text
              url
            }
          }
          resourceDocLink
        }
        spec {
          parameters {
            label
            name
            description
            required
            type
            default
            relevantIf {
              name
              operator
              value
            }
            documentation {
              text
              url
            }
            advancedConfig
            validValues
            options {
              multiline
              creatable
              trackUnchecked
              sectionHeader
              subHeader
              horizontalDivider
              gridColumns
              labels
              metricCategories {
                label
                column
                metrics {
                  name
                  description
                  kpi
                }
              }
              password
              sensitive
              variant
            }
          }
        }
      }
    }
  }
`;

interface ResourceDestinationCardProps {
  name: string;
  destinationIndex: number;
  configuration: MinimumRequiredConfig;
  refetchConfiguration: () => void;
  // disabled indicates that the card is not active and should be greyed out
  disabled?: boolean;
  // onClick will override the default behavior of opening the edit dialog
  onClick?: () => void;
  onDeleteSuccess?: () => void;
  readOnly?: boolean;
}

const ResourceDestinationCardComponent: React.FC<
  ResourceDestinationCardProps
> = ({
  name,
  destinationIndex,
  disabled,
  onClick,
  configuration,
  refetchConfiguration,
  onDeleteSuccess,
  readOnly,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [editing, setEditing] = useState(false);
  const [confirmDeleteOpen, setDeleteOpen] = useState(false);
  const role = useRole();

  const [
    editResourceInUseWarningDialogOpen,
    setEditResourceInUseWarningDialogOpen,
  ] = useState<{
    open: boolean;
    formValues: FormValues;
    function: "onSave" | "onToggle" | null;
  }>({
    open: false,
    formValues: {},
    function: null,
  });

  // Use the version name of the destination specified in the configuration
  const versionedName = useMemo(() => {
    const destination = configuration?.spec?.destinations?.find((d) => {
      return d.name && trimVersion(d.name) === name;
    });

    return destination?.name;
  }, [configuration?.spec?.destinations, name]);

  const { data, refetch: refetchDestination } = useGetDestinationWithTypeQuery({
    variables: { name: versionedName || name },
    fetchPolicy: "cache-and-network",
  });

  const { data: configurationCount } = useConfigurationCountQuery({
    variables: {
      query: `${Kind.Destination}:${trimVersion(name)}`,
    },
    fetchPolicy: "network-only",
    onError(error) {
      console.error(error);
      enqueueSnackbar(
        `Failed to get configuration count for ${
          Kind.Destination
        } ${trimVersion(name)}`,
        {
          variant: "error",
        },
      );
    },
  });

  function interceptOnSave(formValues: FormValues) {
    if (data?.destinationWithType?.destination == null) {
      enqueueSnackbar("Cannot save destination.", { variant: "error" });
      console.error(
        "no destination found when requesting destination and type",
      );
      return;
    }
    if (configurationCount?.configurationCount == null) {
      enqueueSnackbar("Cannot get configuration count.", { variant: "error" });
      console.error("Cannot get configuration count");
      return;
    }
    if (configurationCount.configurationCount > 1) {
      setEditResourceInUseWarningDialogOpen({
        open: true,
        formValues: formValues,
        function: "onSave",
      });
    } else {
      onSave(formValues);
    }
  }

  async function onSave(formValues: FormValues) {
    setEditResourceInUseWarningDialogOpen({
      open: false,
      formValues: {},
      function: null,
    });
    const updatedDestination = new BPDestination(
      data!.destinationWithType!.destination!,
    );

    updatedDestination.setParamsFromMap(formValues);

    try {
      const update = await updatedDestination.apply();
      if (update.status === UpdateStatus.INVALID) {
        console.error("Invalid Update: ", update);
        throw new Error(
          `failed to apply destination, got status ${update.status}`,
        );
      }

      enqueueSnackbar("Saved Destination!", {
        variant: "success",
      });
      setEditing(false);
      refetchConfiguration();
      refetchDestination();
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to update destination.", { variant: "error" });
    }
  }

  const onDelete: onDeleteFunc | undefined = useMemo(() => {
    if (destinationIndex == null) {
      return undefined;
    }
    return async function onDelete() {
      const updatedConfig = new BPConfiguration(configuration);
      updatedConfig.removeDestination(destinationIndex);

      try {
        const update = await updatedConfig.apply();
        if (update.status === UpdateStatus.INVALID) {
          console.error("Invalid Update: ", update);
          throw new Error(
            `failed to remove destination from configuration, configuration invalid`,
          );
        }

        setEditing(false);
        setDeleteOpen(false);
        refetchConfiguration();
        refetchDestination();
        onDeleteSuccess && onDeleteSuccess();
      } catch (err) {
        console.error(err);
        enqueueSnackbar("Failed to remove destination.", {
          variant: "error",
        });
      }
    };
  }, [
    configuration,
    destinationIndex,
    enqueueSnackbar,
    onDeleteSuccess,
    refetchConfiguration,
    refetchDestination,
  ]);

  function interceptOnTogglePause() {
    if (data?.destinationWithType?.destination == null) {
      enqueueSnackbar("Cannot save destination.", { variant: "error" });
      console.error(
        "no destination found when requesting destination and type",
      );
      return;
    }
    if (configurationCount?.configurationCount == null) {
      enqueueSnackbar("Cannot get configuration count.", { variant: "error" });
      console.error("Cannot get configuration count");
      return;
    }
    if (configurationCount.configurationCount > 1) {
      setEditResourceInUseWarningDialogOpen({
        open: true,
        formValues: {},
        function: "onToggle",
      });
    } else {
      onTogglePause();
    }
  }

  /**
   * Toggle `disabled` on the destination spec, replace it in the configuration, and save
   */
  async function onTogglePause() {
    setEditResourceInUseWarningDialogOpen({
      open: false,
      formValues: {},
      function: null,
    });
    if (data?.destinationWithType?.destination == null) {
      enqueueSnackbar("Oops! Something went wrong.", { variant: "error" });
      console.error(
        "could not toggle destination disabled, no destination returned in data",
      );
      return;
    }

    const updatedDestination = new BPDestination(
      data.destinationWithType.destination,
    );
    updatedDestination.toggleDisabled();

    const action = updatedDestination.spec.disabled ? "pause" : "resume";

    try {
      const { status, reason } = await updatedDestination.apply();
      if (status === UpdateStatus.INVALID) {
        throw new Error(
          `failed to update configuration, configuration invalid, ${reason}`,
        );
      }

      enqueueSnackbar(`Destination ${action}d! 🎉`, {
        variant: "success",
      });

      setEditing(false);
      refetchConfiguration();
      refetchDestination();
    } catch (err) {
      enqueueSnackbar(`Failed to ${action} destination.`, {
        variant: "error",
      });
      console.error(err);
    }
  }

  function handleWarnConfirm() {
    if (editResourceInUseWarningDialogOpen.function === "onSave") {
      onSave(editResourceInUseWarningDialogOpen.formValues);
    } else if (editResourceInUseWarningDialogOpen.function === "onToggle") {
      onTogglePause();
    } else {
      console.error("Provided function for warn dialog confirm is null");
    }
  }

  function handleCardClick() {
    onClick ? onClick() : setEditing(true);
  }

  // Loading
  if (data === undefined || configurationCount === undefined) {
    return null;
  }

  if (data.destinationWithType.destination == null) {
    enqueueSnackbar(`Could not retrieve destination ${name}.`, {
      variant: "error",
    });
    return null;
  }

  if (data.destinationWithType.destinationType == null) {
    enqueueSnackbar(
      `Could not retrieve destination type for destination ${name}.`,
      {
        variant: "error",
      },
    );
    return null;
  }

  return (
    <div>
      <ResourceCard
        name={name}
        resourceNameField={
          data?.destinationWithType?.destination?.metadata?.name
        }
        displayName={
          data?.destinationWithType?.destinationType?.metadata?.displayName ??
          ""
        }
        icon={data.destinationWithType?.destinationType?.metadata.icon}
        paused={data.destinationWithType.destination?.spec.disabled}
        disabled={disabled}
        onClick={handleCardClick}
        dataTestID={`resource-card-${name}`}
      />
      <EditResourceDialog
        additionalInfo={
          data.destinationWithType.destinationType.metadata.additionalInfo
        }
        resourceDocLink={
          data.destinationWithType.destinationType.metadata.resourceDocLink ??
          ""
        }
        kind={Kind.Destination}
        resourceNameField={
          data?.destinationWithType?.destination?.metadata?.name
        }
        resourceTypeDisplayName={
          data?.destinationWithType?.destinationType?.metadata?.displayName ??
          ""
        }
        description={
          data.destinationWithType.destinationType.metadata.description ?? ""
        }
        fullWidth
        maxWidth="sm"
        parameters={data.destinationWithType.destination.spec.parameters ?? []}
        parameterDefinitions={
          data.destinationWithType.destinationType.spec.parameters
        }
        open={editing}
        onClose={() => setEditing(false)}
        onCancel={() => setEditing(false)}
        onDelete={onDelete && (() => setDeleteOpen(true))}
        onSave={interceptOnSave}
        paused={data.destinationWithType.destination?.spec.disabled ?? false}
        onTogglePause={interceptOnTogglePause}
        readOnly={readOnly || !hasPermission(Role.User, role)}
      />
      <EditResourceInUseWarningDialog
        resourceName={data.destinationWithType.destination.metadata.name}
        resourceKind={Kind.Destination}
        configurationCount={configurationCount?.configurationCount!}
        open={editResourceInUseWarningDialogOpen.open}
        onCancel={() =>
          setEditResourceInUseWarningDialogOpen({
            open: false,
            formValues: {},
            function: null,
          })
        }
        onConfirm={() => handleWarnConfirm()}
      />

      {onDelete && (
        <ConfirmDeleteResourceDialog
          open={confirmDeleteOpen}
          onClose={() => setDeleteOpen(false)}
          onCancel={() => setDeleteOpen(false)}
          onDelete={onDelete}
          action={"remove"}
        >
          <Typography>
            Are you sure you want to remove this destination?
          </Typography>
        </ConfirmDeleteResourceDialog>
      )}
    </div>
  );
};

export const ResourceDestinationCard = memo(ResourceDestinationCardComponent);
