import { FormControl } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { formatDate } from "date-fns";
import { isFunction } from "lodash";
import { enqueueSnackbar } from "notistack";
import { memo } from "react";
import { useValidationContext } from "../ValidationContext";
import { validateDateTimeField } from "../validation-functions";
import { ParamInputProps } from "./ParameterInput";
import { parameterHelperText } from "./utils";

const DateTimeInputComponent: React.FC<ParamInputProps<string>> = ({
  definition,
  value,
  onValueChange,
  readOnly,
}) => {
  const { errors, setError, touched, touch } = useValidationContext();

  // Convert date for picker to use
  let pickerDateValue: Date | null = null;
  try {
    pickerDateValue = value ? new Date(value) : null;
  } catch (e) {
    console.error(e);
    enqueueSnackbar("Failed to convert Date", { variant: "error" });
  }

  function handleOnChange(v: any) {
    // Convert to format accepted by the receiver
    const stringDate = formatDate(v, "yyyy-MM-dd'T'HH:mm");
    if (isFunction(onValueChange)) {
      onValueChange(stringDate);
    }
    if (!touched[definition.name]) {
      touch(definition.name);
    }
    setError(definition.name, validateDateTimeField(definition, stringDate));
  }

  return (
    <FormControl fullWidth>
      <DateTimePicker
        label={definition.label}
        value={pickerDateValue}
        defaultValue={null}
        disabled={readOnly}
        onChange={handleOnChange}
        data-testid={`date-time-picker-input-${definition.name}`}
        timeSteps={{ minutes: 1 }}
        slotProps={{
          textField: {
            helperText: parameterHelperText(definition, errors, touched),
            required: definition.required,
            onBlur: () => touch(definition.name),
            error: false,
            InputProps: {
              slotProps: {
                input: {
                  disabled: true,
                },
              },
            },
          },
          openPickerButton: {
            color: "primary",
          },
        }}
      />
    </FormControl>
  );
};

export const DateTimeInput = memo(DateTimeInputComponent);
