import {
  Card,
  CircularProgress,
  Stack,
  Typography,
  TypographyProps,
} from "@mui/material";
import styles from "./number-chip.module.scss";

interface NumberChipProps {
  label: string;
  value?: number;
  loading?: boolean;
  color?: TypographyProps["color"];
  onClick?: () => void;
}

/**
 * NumberChip displays a label and a number in a box.
 * The color prop is only used if the number is greater than zero.
 */
export const NumberChip: React.FC<NumberChipProps> = ({
  label,
  value,
  loading,
  color,
  onClick,
}) => {
  return (
    <Stack
      width={100}
      marginRight="8px"
      marginBottom="8px"
      justifyContent="center"
      alignItems="center"
    >
      <Card classes={{ root: styles.card }} role="button" onClick={onClick}>
        {loading || value == null ? (
          <CircularProgress size={24} />
        ) : (
          <Typography
            fontSize={fontSize(value)}
            color={value > 0 ? color : "default"}
          >
            {value}
          </Typography>
        )}
      </Card>
      <Typography fontWeight={600} marginBottom="4px" fontSize={14} noWrap>
        {label}
      </Typography>
    </Stack>
  );
};

function fontSize(value: number): number {
  const length = Math.log10(value);
  if (length <= 4) {
    return 32;
  } else if (length <= 5) {
    return 28;
  } else {
    return 24;
  }
}
