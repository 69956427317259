import { GetConfigurationWithRoutesQuery } from "../../graphql/generated";

export type V2Config = GetConfigurationWithRoutesQuery["configuration"];

export enum AttributeName {
  // Nodes and Edges
  ActiveTypeFlags = "activeTypeFlags",
  SupportedTypeFlags = "supportedTypeFlags",

  // Nodes Only
  ComponentPath = "componentPath",
  DestinationIndex = "destinationIndex",
  SourceIndex = "sourceIndex",
  Recommendations = "recommendations",
}
