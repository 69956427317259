import { Typography } from "@mui/material";
import { useLogo } from "../../hooks/useLogo";
import styles from "./auth0-imitation.module.scss";

interface Auth0CardHeaderProps {
  title?: string;
  description?: string;
}

export const Auth0CardHeader: React.FC<Auth0CardHeaderProps> = ({
  title = "Welcome",
  description,
}) => {
  const logo = useLogo({ height: 52, width: 52 }, "square");
  return (
    <div className={styles.auth0Heading}>
      {logo}
      <Typography fontSize="24px" paddingY="4px" marginBottom="12px">
        {title}
      </Typography>
      {description && <Typography fontSize=".875rem">{description}</Typography>}
    </div>
  );
};
