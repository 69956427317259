import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { Kind, ResourceConfiguration } from "../../graphql/generated";
import { ResourceConfigurationLabelCard } from "./ResourceConfigurationLabelCard";

interface Props {
  resourceKind: Kind.Processor | Kind.Extension;
  index: number;
  item: ResourceConfiguration;
  onEdit: () => void;
  // Move processor should change the components order state
  moveItem: (dragIndex: number, dropIndex: number) => void;

  onDrop: () => void;

  viewOnly?: boolean;
}

type Item = {
  index: number;
};

export const ResourceConfigurationLabel: React.FC<Props> = ({
  index,
  resourceKind,
  item,
  onEdit,
  moveItem,
  onDrop,
}) => {
  const [, dragRef] = useDrag({
    type: "inline-processor",
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isHovered }, dropRef] = useDrop<
    Item,
    unknown,
    { isHovered: boolean }
  >({
    accept: "inline-processor",
    collect: (monitor) => ({
      isHovered: monitor.isOver(),
    }),
    hover: (item, monitor) => {
      if (ref.current == null) {
        return;
      }

      if (monitor == null) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      moveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },

    // Save the order on drop
    drop: onDrop,
  });

  // Join the 2 refs together into one (both draggable and can be dropped on)
  const ref = useRef<HTMLDivElement>(null);

  const dragDropRef = dragRef(dropRef(ref)) as any;

  return (
    <ResourceConfigurationLabelCard
      index={index}
      resourceKind={resourceKind}
      item={item}
      dragDropRef={dragDropRef}
      isHovered={isHovered}
      onEdit={onEdit}
    />
  );
};
