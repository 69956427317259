import { DialogResource } from "../components/ResourceDialog";
import {
  Configuration,
  ConfigurationSpec,
  Kind,
  ResourceConfiguration,
} from "../graphql/generated";
import { APIVersion } from "../types/resources";
import { trimVersion } from "./version-helpers";

export function newConfiguration({
  name,
  description,
  spec,
  labels,
}: {
  name: string;
  description: string;
  spec: ConfigurationSpec;
  labels?: { [key: string]: string };
}): Pick<Configuration, "apiVersion" | "kind" | "metadata" | "spec"> {
  return {
    apiVersion: APIVersion.V1,
    kind: Kind.Configuration,
    metadata: { name, description, labels, id: "", version: 1 },
    spec,
  };
}

/**
 * Returns a list of existing resources that are not in the configuration. It does not consider resource type.
 * This should be used to filter the list of available resources to only show those that are not already in the configuration.
 *
 * @param available        The list of available saved resources
 * @param inConfiguration The list of resources already in the configuration
 * @returns               The list of available saved resources that are not in the configuration
 */
export function existingResourcesNotInConfiguration(
  available: DialogResource[],
  inConfiguration: ResourceConfiguration[],
): DialogResource[] {
  return available.filter((a) => {
    return !inConfiguration?.some(
      (existing) =>
        existing.name != null &&
        existing.name !== "" &&
        trimVersion(existing.name) === a.metadata.name,
    );
  });
}
