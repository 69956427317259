import { Stack, Typography } from "@mui/material";
import { Component, ErrorInfo } from "react";
import { edition } from "../BindplaneVersion/utils";
import { SafeLink } from "../SafeLink";

export class PipelineGraphErrorBoundary extends Component<
  {},
  { hasError: boolean }
> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({ hasError: true });
    console.error(error, errorInfo);
  }

  render() {
    const supportEmail =
      edition() === "Honeycomb"
        ? "support@honeycomb.io"
        : "support.bindplaneop@observiq.com";
    if (this.state.hasError) {
      return (
        <Stack height="400px" width="100%" justifyContent="center">
          <Typography variant="h6" align="center">
            An error occurred while rendering the pipeline graph
          </Typography>
          <Typography align="center">
            Try refreshing the page, if the problem persists please{" "}
            <SafeLink href={`mailto:${supportEmail}`}>contact support</SafeLink>
          </Typography>
          .
        </Stack>
      );
    } else {
      return this.props.children;
    }
  }
}
