import { OnDataOptions, gql } from "@apollo/client";
import { escapeRegExp } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { v4 } from "uuid";
import { SnapshotContext } from "../components/SnapShotConsole/SnapshotContext";
import { FieldType } from "../components/SnapShotConsole/types";
import {
  findBindplaneID,
  getBindplaneID,
  hasFieldValue,
} from "../components/SnapShotConsole/utils";
import {
  DataPoint,
  Log,
  PipelineType,
  ProcessorRecommendation,
  ResourceConfiguration,
  SnapshotProcessed,
  SnapshotProcessedSubscription,
  Span,
  useResourceBasedRecommendationsQuery,
  useSnapshotProcessedSubscription,
  useSnapshotSearchSupportedQuery,
} from "../graphql/generated";
import { dismissedRecommendationStorage } from "../utils/dismissed-recommendations/dismissed-recommendations";

// while the query includes all three pipeline types, only the pipelineType specified will have results
gql`
  query resourceBasedRecommendations(
    $pipelineType: PipelineType!
    $configName: String!
    $position: String!
    $resourceIndex: Int!
  ) {
    resourceBasedRecommendations(
      pipelineType: $pipelineType
      configName: $configName
      position: $position
      resourceIndex: $resourceIndex
    ) {
      recommendationTypeId
      description
      displayName
      resourceConfiguration {
        id
        name
        type
        parameters {
          name
          value
        }
        disabled
        recommendation
      }
    }
  }

  subscription snapshotProcessed(
    $agentID: String!
    $pipelineType: PipelineType!
    $position: String
    $resourceName: String
    $processorsJSON: String
    $searchQuery: String
    $uuid: String!
    $configName: String!
    $resourceIndex: Int!
  ) {
    snapshotProcessed(
      agentID: $agentID
      pipelineType: $pipelineType
      position: $position
      resourceName: $resourceName
      processorsJSON: $processorsJSON
      searchQuery: $searchQuery
      uuid: $uuid
      configName: $configName
      resourceIndex: $resourceIndex
    ) {
      telemetry {
        metrics {
          name
          description
          timestamp
          startTimestamp
          unit
          type
          attributes
          resource
          valueType
          valueInt
          valueDouble
          quantileValues
          fields
        }
        logs {
          observedTimestamp
          timestamp
          traceID
          spanID
          body
          severityText
          severityNumber
          attributes
          resource
        }
        traces {
          name
          traceID
          spanID
          parentSpanID
          start
          end
          attributes
          resource
          fields
        }
        processedMetrics {
          name
          description
          timestamp
          startTimestamp
          unit
          type
          attributes
          resource
          valueType
          valueInt
          valueDouble
          quantileValues
          fields
        }
        processedLogs {
          observedTimestamp
          timestamp
          traceID
          spanID
          body
          severityText
          severityNumber
          attributes
          resource
        }
        processedTraces {
          name
          traceID
          spanID
          parentSpanID
          start
          end
          attributes
          resource
          fields
        }
      }
      error

      recommendations {
        recommendationTypeId
        description
        displayName
        percentChange
        acceptanceCriteria
        resourceConfiguration {
          id
          name
          type
          parameters {
            name
            value
          }
          disabled
        }
      }
    }
  }
`;

export interface SnapshotProviderProps {
  pipelineType: PipelineType;
  agentID?: string;
  showAgentSelector?: boolean;
  position?: "s0" | "d0";
  resourceName?: string;
  processors?: ResourceConfiguration[];
  configurationName: string;
  resourceIndex: number;
}

export const EESnapshotContextProvider: React.FC<SnapshotProviderProps> = ({
  children,
  pipelineType: initialPipelineType,
  agentID: initialAgentID,
  showAgentSelector,
  position,
  resourceName,
  processors,
  configurationName,
  resourceIndex,
}) => {
  const [pipelineType, setPipelineType] =
    useState<PipelineType>(initialPipelineType);

  const [logs, setLogs] = useState<Log[]>([]);
  const [metrics, setMetrics] = useState<DataPoint[]>([]);
  const [traces, setTraces] = useState<Span[]>([]);

  const [processedLogs, setProcessedLogs] = useState<Log[]>([]);
  const [processedMetrics, setProcessedMetrics] = useState<DataPoint[]>([]);
  const [processedTraces, setProcessedTraces] = useState<Span[]>([]);

  const [agentID, setAgentID] = useState<string | undefined>(initialAgentID);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [snapshotSearchSupported, setSnapshotSearchSupported] =
    useState<boolean>(false);
  const [searchRegex, setSearchRegex] = useState<RegExp | undefined>();

  const [footer, setFooter] = useState<string>("");
  const [processedFooter, setProcessedFooter] = useState<string>("");

  const [processorRecommendations, setProcessorRecommendations] = useState<
    ProcessorRecommendation[] | null
  >(null);
  const [dismissedRecommendations, setDismissedRecommendations] = useState<
    string[] | null
  >(null);

  const [acceptedRecommendations, setAcceptedRecommendations] = useState<
    string[] | null
  >(null);

  useEffect(() => {
    if (dismissedRecommendations === null && resourceName != null) {
      setDismissedRecommendations(
        dismissedRecommendationStorage.getDismissed(resourceName),
      );
    }
  }, [dismissedRecommendations, resourceName]);

  useSnapshotSearchSupportedQuery({
    skip: !agentID,
    variables: { agentID: agentID! },
    onCompleted: (data) => {
      setSnapshotSearchSupported(data.snapshotSearchSupported);
    },
  });
  const [error, setError] = useState<string>();

  const processorsJSON = useMemo(() => {
    if (processors == null) {
      return "[]";
    }
    return JSON.stringify(processors);
  }, [processors]);

  const [sessionId, setSessionId] = useState<string>(v4());

  function handleRefresh() {
    setSessionId(v4());
  }

  const dataHandlerFn = (
    options: OnDataOptions<SnapshotProcessedSubscription>,
  ) => {
    const data = options.data.data;
    if (data == null) {
      return;
    }
    const { telemetry } = data.snapshotProcessed;
    if (telemetry) {
      setLogs(telemetry.logs.slice().reverse());
      setMetrics(telemetry.metrics.slice().reverse());
      setTraces(telemetry.traces.slice().reverse());

      setProcessedLogs(telemetry.processedLogs.slice().reverse());
      setProcessedMetrics(telemetry.processedMetrics.slice().reverse());
      setProcessedTraces(telemetry.processedTraces.slice().reverse());

      updateFooterCounts(telemetry);
      setOpenRowIDs([]);
      if (searchQuery && searchQuery.length > 0) {
        setSearchRegex(new RegExp(`(${escapeRegExp(searchQuery)})`, "g"));
      } else {
        setSearchRegex(undefined);
      }
    }

    if (options.data.error) {
      setError(options.data.error.message);
    } else if (data.snapshotProcessed.error) {
      setError(data.snapshotProcessed.error);
    }

    const { recommendations } = data.snapshotProcessed;
    if (recommendations) {
      setProcessorRecommendations((old) => {
        const map = new Map<string, ProcessorRecommendation>();

        (old || []).concat(recommendations || []).forEach((item) => {
          map.set(item.recommendationTypeId, item); // This will overwrite any existing entry with the same id
        });

        return Array.from(map.values()); // Converts the Map's values back into an array
      });
    }
  };

  const { loading } = useSnapshotProcessedSubscription({
    variables: {
      agentID: agentID!,
      pipelineType,
      position,
      resourceName,
      processorsJSON,
      searchQuery,
      uuid: process.env.NODE_ENV === "test" ? "test" : sessionId,
      configName: configurationName,
      resourceIndex: resourceIndex ?? 0,
    },
    skip: !agentID,
    onData: dataHandlerFn,
    fetchPolicy: "network-only",
  });

  const { refetch } = useResourceBasedRecommendationsQuery({
    variables: {
      pipelineType,
      configName: configurationName ?? "",
      position: position === "d0" ? "destination" : "source",
      resourceIndex: resourceIndex ?? 0,
    },
    onCompleted: (data: any) => {
      setProcessorRecommendations((old) => {
        const map = new Map<string, ProcessorRecommendation>();

        (old || [])
          .concat(data.resourceBasedRecommendations || [])
          .forEach((item) => {
            map.set(item.recommendationTypeId, item); // This will overwrite any existing entry with the same id
          });

        return Array.from(map.values()); // Converts the Map's values back into an array
      });
    },
  });

  useEffect(() => {
    if (resourceName) {
      refetch();
    }
  }, [resourceName, refetch]);

  useEffect(() => {
    if (loading) {
      setFooter("Searching...");
      setProcessedFooter("Searching...");
      setProcessorRecommendations(null);
    }
  }, [loading]);

  function updateFooterCounts(data: SnapshotProcessed) {
    const {
      logs,
      metrics,
      traces,
      processedLogs,
      processedMetrics,
      processedTraces,
    } = data;

    var beforeCount, afterCount;
    switch (pipelineType) {
      case PipelineType.Logs:
        beforeCount = logs.length;
        afterCount = processedLogs.length;
        break;
      case PipelineType.Metrics:
        beforeCount = metrics.length;
        afterCount = processedMetrics.length;
        break;
      case PipelineType.Traces:
        beforeCount = traces.length;
        afterCount = processedTraces.length;
        break;
    }
    setFooter(`Showing ${beforeCount} recent ${pipelineType}`);
    setProcessedFooter(
      `Showing ${afterCount} ${pipelineType} after processing`,
    );
  }

  // track open snapshot rows
  const [openRowIDs, setOpenRowIDs] = useState<string[]>([]);
  const toggleRow = (rowID: string): void => {
    if (openRowIDs.includes(rowID)) {
      setOpenRowIDs(openRowIDs.filter((id) => id !== rowID));
    } else {
      setOpenRowIDs([...openRowIDs, rowID]);
      // opened, scroll both into view
      document.querySelectorAll(`[data-row-id="${rowID}"]`).forEach((el) => {
        el.scrollIntoView({ behavior: "smooth", block: "start" });
      });
    }
  };

  // track filtered ids for each pipeline type
  const [filteredIDs, setFilteredIDs] = useState<{
    [pipelineType: string]: string[];
  }>({});

  // recompute filtered IDs when logs, metrics, or traces change
  useEffect(() => {
    setFilteredIDs({
      [PipelineType.Logs]: getFilteredIDs(logs, processedLogs),
      [PipelineType.Metrics]: getFilteredIDs(metrics, processedMetrics),
      [PipelineType.Traces]: getFilteredIDs(traces, processedTraces),
    });
  }, [logs, metrics, traces, processedLogs, processedMetrics, processedTraces]);

  const isFiltered = (pipelineType: PipelineType, bindplaneID: string) => {
    return filteredIDs[pipelineType].includes(bindplaneID);
  };
  const isRemovedField = (
    pipelineType: PipelineType,
    bindplaneID: string,
    fieldType: FieldType,
    fieldKey: string,
    fieldValue: any,
  ) => {
    // check if the entire row if filtered
    if (isFiltered(pipelineType, bindplaneID)) {
      return false;
    }
    // check if the field is filtered
    switch (pipelineType) {
      case PipelineType.Logs:
        return !hasFieldValue(
          findBindplaneID(processedLogs, bindplaneID),
          fieldType,
          fieldKey,
          fieldValue,
        );
      case PipelineType.Metrics:
        return !hasFieldValue(
          findBindplaneID(processedMetrics, bindplaneID),
          fieldType,
          fieldKey,
          fieldValue,
        );
      case PipelineType.Traces:
        return !hasFieldValue(
          findBindplaneID(processedTraces, bindplaneID),
          fieldType,
          fieldKey,
          fieldValue,
        );
      default:
        return false;
    }
  };

  const isAddedField = (
    pipelineType: PipelineType,
    bindplaneID: string,
    fieldType: FieldType,
    fieldKey: string,
    fieldValue: any,
  ) => {
    switch (pipelineType) {
      case PipelineType.Logs:
        return !hasFieldValue(
          findBindplaneID(logs, bindplaneID),
          fieldType,
          fieldKey,
          fieldValue,
        );
      case PipelineType.Metrics:
        return !hasFieldValue(
          findBindplaneID(metrics, bindplaneID),
          fieldType,
          fieldKey,
          fieldValue,
        );
      case PipelineType.Traces:
        return !hasFieldValue(
          findBindplaneID(traces, bindplaneID),
          fieldType,
          fieldKey,
          fieldValue,
        );
      default:
        return false;
    }
  };

  function dismissRecommendation(recommendationID: string) {
    // Update state to include new recommendation ID
    setDismissedRecommendations([
      ...(dismissedRecommendations ?? []),
      recommendationID,
    ]);
    // Update local storage to include new recommendation ID
    dismissedRecommendationStorage.dismiss(resourceName!, recommendationID);
  }

  return (
    <SnapshotContext.Provider
      value={{
        logs,
        metrics,
        traces,

        setLogs,
        setMetrics,
        setTraces,

        processedLogs,
        processedMetrics,
        processedTraces,

        setProcessedLogs,
        setProcessedMetrics,
        setProcessedTraces,

        loading,
        showAgentSelector: showAgentSelector ?? false,

        footer,
        setFooter,

        processedFooter,
        setProcessedFooter,

        error,
        setError,

        agentID,
        setAgentID,

        searchQuery,
        setSearchQuery,
        snapshotSearchSupported,
        searchRegex,

        pipelineType,
        setPipelineType: (type: PipelineType) => {
          setPipelineType(type);
          setFooter("Searching...");
          setProcessedFooter("Searching...");
        },

        refresh: handleRefresh,

        openRowIDs,
        toggleRow,

        isFiltered,
        isAddedField,
        isRemovedField,

        processorRecommendations,
        dismissedRecommendations,
        dismissRecommendation,

        acceptedRecommendations,
        setAcceptedRecommendations,

        refetchResourceRecommendations: refetch,
      }}
    >
      {children}
    </SnapshotContext.Provider>
  );
};

function getFilteredIDs(
  before: (Log | DataPoint | Span)[],
  after: (Log | DataPoint | Span)[],
): string[] {
  const beforeIDs = before.map(getBindplaneID);
  const afterIDs = after.map(getBindplaneID);
  return beforeIDs.filter((id) => !afterIDs.includes(id));
}
