import { FormHelperText, Stack } from "@mui/material";
import { isEmpty } from "lodash";
import { ParameterDefinition } from "../../../graphql/generated";
import { SafeLink } from "../../SafeLink";

export function parameterHelperText(
  definition: ParameterDefinition,
  errors: Record<string, string | null>,
  touched: Record<string, boolean>,
): React.ReactNode | undefined {
  return (
    <>
      {parameterErrors(definition, errors, touched)}
      {parameterDescription(definition)}
      {parameterDocumentation(definition)}
    </>
  );
}

export function parameterErrors(
  definition: ParameterDefinition,
  errors: Record<string, string | null>,
  touched: Record<string, boolean>,
): React.ReactNode | undefined {
  return (
    errors[definition.name] &&
    touched[definition.name] && (
      <>
        <FormHelperText sx={{ marginLeft: 0 }} component="span" error>
          {errors[definition.name]}
        </FormHelperText>
        <br />
      </>
    )
  );
}

export function parameterDescription(
  definition: ParameterDefinition,
): React.ReactNode | undefined {
  return (
    !isEmpty(definition.description) && (
      <FormHelperText sx={{ marginLeft: 0 }} component="span">
        {definition.description}
      </FormHelperText>
    )
  );
}

export function parameterDocumentation(
  definition: ParameterDefinition,
): React.ReactNode | undefined {
  return (
    definition.documentation && (
      <Stack component={"span"}>
        {definition.documentation?.map((d) => (
          <SafeLink href={d.url} rel="noreferrer" target="_blank" key={d.url}>
            {d.text}
          </SafeLink>
        ))}
      </Stack>
    )
  );
}
