import { HelpMenu } from "../HelpMenu";
import { NavBar } from "../NavBar";
import { SubNav } from "../SubNav";
import { UnlicensedComponentBanner } from "../UnlicensedComponentBanner";

// withEENavBar renders the children with a NavBar and SubNav.
// It renders the children in a div with global class "content".
export function withEENavBar(FC: React.FC): React.FC {
  return () => (
    <>
      <div id="top-nav">
        <UnlicensedComponentBanner />
        <NavBar />
        <SubNav />
      </div>
      <div className="content">
        <FC />
      </div>
      <div className="help-menu">
        <HelpMenu />
      </div>
    </>
  );
}

// withNoStyleEENavBar renders the children with a NavBar and SubNav.
// It renders the children without a div with global class "content".
export function withNoStyleEENavBar(FC: React.FC): React.FC {
  return () => (
    <>
      <div id="top-nav">
        <UnlicensedComponentBanner />
        <NavBar />
        <SubNav />
      </div>
      <FC />
      <div className="help-menu">
        <HelpMenu />
      </div>
    </>
  );
}
