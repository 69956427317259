import { uniqWith } from "lodash";
import { Edge, Graph, Node } from "../../graphql/generated";

export type NodeId = string;

export class BPGraph {
  constructor(public graph: Graph) {}

  /**
   * getSources returns all of the source nodes in the graph
   */
  getSources(): Node[] {
    return this.graph.sources;
  }

  /**
   * getDestinations returns all of the destination nodes in the graph
   */
  getDestinations(): Node[] {
    return this.graph.targets;
  }

  /**
   * getAllNodes returns all nodes in the graph
   */
  getAllNodes(): Node[] {
    return [
      ...this.graph.sources,
      ...this.graph.intermediates,
      ...this.graph.targets,
    ];
  }

  /**
   * findNode returns the node with the given id
   */
  findNode(id: NodeId): Node | undefined {
    return this.getAllNodes().find((node) => node.id === id);
  }

  /**
   * getNodesFromPath returns the nodes that are in the given path
   */
  getNodesFromPath(path: GraphPath): Node[] {
    return path.path
      .map((id) => this.findNode(id))
      .filter((n) => n !== undefined) as Node[];
  }

  /**
   * getNextNodes follows all of the edges from the given node and returns the nodes that
   * are the target of those edges
   */
  getNextNodes(id: NodeId): Node[] {
    const edges = this.getEdgesOutbound(id);
    return edges
      .map((edge) => this.findNode(edge.target))
      .filter((n) => n !== undefined) as Node[];
  }

  /**
   * getEdgesOutbound returns all edges that have the given node as the source
   */
  getEdgesOutbound(id: NodeId): Edge[] {
    return this.graph.edges.filter((edge) => edge.source === id);
  }

  /**
   * getEdgesInbound returns all edges that have the given node as the target
   */
  getEdgesInbound(id: NodeId): Edge[] {
    return this.graph.edges.filter((edge) => edge.target === id);
  }

  /**
   * getNodesInbound returns all nodes that have an edge with the given node as the target
   */
  getNodesInbound(id: NodeId): Node[] {
    const edges = this.getEdgesInbound(id);
    return edges
      .map((edge) => this.findNode(edge.source))
      .filter((n) => n !== undefined) as Node[];
  }

  /**
   * getPaths returns all paths in the graph
   */
  getPaths(): GraphPath[] {
    const result: GraphPath[] = [];

    // start paths at the sources
    for (const source of this.graph.sources) {
      const paths = this.getPathsFrom(source.id);
      result.push(...paths);
    }
    // include any unreferenced intermediates
    for (const intermediate of this.graph.intermediates) {
      if (!isVisited(intermediate, result)) {
        const paths = this.getPathsFrom(intermediate.id);
        for (const path of paths) {
          // prepend space for source and source processors
          result.push(path.prepend("", ""));
        }
      }
    }
    // targets should always get picked up by destination processor nodes

    // only return unique results
    return uniqWith(result, (a, b) => a.path.join("|") === b.path.join("|"));
  }

  /**
   * getPathsFrom returns all paths that start at the given node
   */
  getPathsFrom(id: NodeId): GraphPath[] {
    const next = this.getNextNodes(id);
    if (next.length === 0) {
      return [new GraphPath([id])];
    }
    const result: GraphPath[] = [];
    for (const nextId of next) {
      const nextPaths = this.getPathsFrom(nextId.id);
      for (const path of nextPaths) {
        result.push(path.prepend(id));
      }
    }
    return result;
  }

  /**
   * getMaxPathLength returns the length of the longest path in the graph. This will
   * determine the width of the graph layout.
   */
  getMaxPathLength(): number {
    return this.getLongestPath().path.length;
  }

  /**
   * getLongestPath returns the longest path in the graph
   */
  getLongestPath(): GraphPath {
    return this.getPaths().reduce((longest, path) => {
      return path.path.length > longest.path.length ? path : longest;
    }, new GraphPath([]));
  }
}

export class GraphPath {
  constructor(public path: string[]) {}

  /**
   * prepend returns a new GraphPath with the given list of ids prepended to the path
   */
  prepend(...id: string[]): GraphPath {
    return new GraphPath([...id, ...this.path]);
  }

  /**
   * append returns a new GraphPath with the given list of ids appended to the path
   */
  append(...id: string[]): GraphPath {
    return new GraphPath([...this.path, ...id]);
  }
}

function isVisited(node: Node, paths: GraphPath[]): boolean {
  return paths.some((path) => path.path.includes(node.id));
}
