import { useEffect } from "react";
import colors from "../../../styles/colors";
import styles from "../snap-shot-console.module.scss";

export function highlightSearchQuery(
  text?: string | null,
  searchRegex?: RegExp | undefined,
): JSX.Element {
  if (!searchRegex || !text) {
    return <>{text}</>;
  }

  const parts = text.split(searchRegex);
  if (parts.length === 1) {
    return <>{text}</>;
  }

  return (
    <>
      {parts.map((part, index) =>
        index % 2 === 0 ? (
          part
        ) : (
          <span
            key={index}
            style={{
              backgroundColor: colors.palePrim,
            }}
          >
            {part}
          </span>
        ),
      )}
    </>
  );
}

// useWatchForOpen creates a MutationObserver to observe when the referenced element has the open class added.
// When the open class is added, setOpen is called with true.
export function useWatchForOpen(
  ref: React.RefObject<HTMLDivElement>,
  setOpen: (open: boolean) => void,
) {
  useEffect(() => {
    const row = ref.current;

    if (row) {
      const observer = new MutationObserver((_mutations) => {
        if (row.classList.contains(styles.open)) {
          setOpen(true);
        }
      });

      // Start observing the element for attribute changes
      observer.observe(row, {
        attributes: true,
      });

      // Clean up observer to prevent memory leaks
      return () => observer.disconnect();
    }
  }); // Empty dependency array ensures the effect runs only once after the initial render
}
