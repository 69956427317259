import { Stack } from "@mui/material";
import styles from "./auth0-imitation.module.scss";

export const Auth0Card: React.FC = ({ children }) => {
  return (
    <div className={styles.bg}>
      <Stack
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <div className={styles.auth0Card}>{children}</div>
      </Stack>
    </div>
  );
};
