import { ApolloError, gql } from "@apollo/client";
import { Stack } from "@mui/material";
import {
  GridDensity,
  GridPaginationModel,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid";
import { debounce, isFunction, throttle } from "lodash";
import { useSnackbar } from "notistack";
import { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  PaginatedAgentsTableQuery,
  Suggestion,
  useAgentsTableChangedSubscription,
  usePaginatedAgentsTableQuery,
} from "../../graphql/generated";
import { AgentStatus } from "../../types/agents";
import { SearchBar } from "../SearchBar";
import { EEAgentsDataGrid } from "./EEAgentsDataGrid";
import { AgentOnboardingNoRowsOverlay } from "./OnboardingNoRowsOverlay";
import { AgentMetrics, AgentsTableField } from "./types";

gql`
  query PaginatedAgentsTable($options: AgentsTableOptions!) {
    agentsTable(options: $options) {
      agents {
        id
        labels
        version
        name
        status
        operatingSystem
        configurationStatus {
          current
        }
        upgradeAvailable
      }
      agentCount
      query
      suggestions {
        query
        label
      }
      agentMetrics {
        name
        nodeID
        unit
        value
        agentID
        pipelineType
      }
    }
  }

  subscription AgentsTableChanged($agentIDs: [String!]!, $selector: String) {
    agentsTableChanged(agentIDs: $agentIDs, selector: $selector)
  }
`;

type TableAgent = PaginatedAgentsTableQuery["agentsTable"]["agents"][0];

const INITIAL_SORT: GridSortModel = [
  { field: AgentsTableField.STATUS, sort: "asc" },
];
export const AGENT_TABLE_QUERY_PARAM = "atq";

const AGENTS_TABLE_FILTER_OPTIONS: Suggestion[] = [
  { label: "Agents with errors", query: "status:error" },
  { label: "Disconnected agents", query: "status:disconnected" },
  { label: "Outdated agents", query: "-version:latest" },
  { label: "No managed configuration", query: "-configuration:" },
];

interface AgentsTableProps {
  onAgentsSelected?: (agentIds: GridRowSelectionModel) => void;
  onDeletableAgentsSelected?: (agentIds: GridRowSelectionModel) => void;
  onUpdatableAgentsSelected?: (agentIds: GridRowSelectionModel) => void;
  onLabelableAgentsSelected?: (
    agentIds: GridRowSelectionModel,
    selectedAgentLabels: Record<string, string>[],
  ) => void;
  isRowSelectable?: (params: GridRowParams<TableAgent>) => boolean;
  clearSelectionModelFnRef?: React.MutableRefObject<(() => void) | null>;
  selector?: string;
  minHeight?: string;
  maxHeight?: string;
  columnFields?: AgentsTableField[];
  density?: GridDensity;
  initQuery?: string;
  allowSelection: boolean;

  // If true, the table will render an empty state
  // when zero agents are connected that links to the
  // install agents page.
  onboardingNoRowsOverlay?: boolean;

  // urlQuerySearchParam is used to store the query in the url
  // The table will update this param in the URL onQueryChange if it is set
  // It will not use the search query from the URL automatically though, that must
  // be set from the renderer in initQuery.
  urlQuerySearchParam?: string;
}

export const EEAgentsTable: React.FC<AgentsTableProps> = ({
  allowSelection,
  onAgentsSelected,
  onDeletableAgentsSelected,
  onUpdatableAgentsSelected,
  onLabelableAgentsSelected,
  isRowSelectable,
  clearSelectionModelFnRef,
  selector,
  minHeight,
  maxHeight,
  columnFields,
  initQuery = "",
  density,
  urlQuerySearchParam,
  onboardingNoRowsOverlay,
}) => {
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: 100,
    page: 0,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>(INITIAL_SORT);
  const [rowCount, setRowCount] = useState(0);
  const [agents, setAgents] = useState<TableAgent[]>([]);
  const [agentMetrics, setAgentMetrics] = useState<AgentMetrics>();
  const [suggestionsQuery, setSuggestionsQuery] = useState<string>();
  const [suggestions, setSuggestions] =
    useState<PaginatedAgentsTableQuery["agentsTable"]["suggestions"]>();
  const [loading, setLoading] = useState<boolean>(true);

  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  function onCompleted(data: PaginatedAgentsTableQuery) {
    setRowCount(data.agentsTable.agentCount);
    setAgents(data.agentsTable.agents);
    setSuggestionsQuery(data.agentsTable.query);
    setSuggestions(data.agentsTable.suggestions);
    setAgentMetrics(data.agentsTable.agentMetrics);
    setLoading(false);
  }

  function onError(err: ApolloError) {
    console.error(err);
    enqueueSnackbar("Oops! Something went wrong.", { variant: "error" });
    setLoading(false);
  }

  const { refetch } = usePaginatedAgentsTableQuery({
    fetchPolicy: "network-only",
    variables: {
      options: {
        query: suggestionsQuery ?? initQuery,
        selector,
        offset: paginationModel.page * paginationModel.pageSize,
        limit: paginationModel.pageSize,
        sort: muiSortModelToString(sortModel),
      },
    },
    onCompleted,
    onError,
  });

  const handleSelect = useMemo(
    () => (agentIds: GridRowSelectionModel) => {
      if (isFunction(onAgentsSelected)) {
        onAgentsSelected(agentIds);
      }

      if (isFunction(onDeletableAgentsSelected)) {
        const deletable = agentIds.filter((id) =>
          isDeletable(agents, id as string),
        );
        onDeletableAgentsSelected(deletable);
      }

      if (isFunction(onUpdatableAgentsSelected)) {
        const updatable = agentIds.filter((id) =>
          isUpdatable(agents, id as string),
        );
        onUpdatableAgentsSelected(updatable);
      }

      if (isFunction(onLabelableAgentsSelected)) {
        const selectedAgents = agents.filter((a) => agentIds.includes(a.id));
        onLabelableAgentsSelected(
          agentIds,
          selectedAgents.map((a) => a.labels ?? {}),
        );
      }
    },
    [
      agents,
      onAgentsSelected,
      onDeletableAgentsSelected,
      onLabelableAgentsSelected,
      onUpdatableAgentsSelected,
    ],
  );

  // the debouncedRefetch is used to refetch when the search bar query changes
  const debouncedRefetch = useMemo(() => debounce(refetch, 200), [refetch]);

  // the throttledRefetch is used to refetch when the
  // we get notified of a table change by the subscription
  const throttledRefetch = useMemo(() => throttle(refetch, 1000), [refetch]);

  useAgentsTableChangedSubscription({
    variables: {
      agentIDs: agents.map((a) => a.id),
      selector,
    },
    onData() {
      throttledRefetch({
        options: {
          selector,
          query: suggestionsQuery ?? "",
          offset: paginationModel.page * paginationModel.pageSize,
          limit: paginationModel.pageSize,
          sort: muiSortModelToString(sortModel),
        },
      });
    },
  });

  function onQueryChange(newQuery: string) {
    debouncedRefetch({
      options: {
        selector,
        query: newQuery,
        offset: 0,
        limit: paginationModel.pageSize,
        sort: muiSortModelToString(sortModel),
      },
    });
    if (urlQuerySearchParam) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set(urlQuerySearchParam, newQuery);
      window.history.replaceState(
        {},
        "",
        `${location.pathname}?${searchParams.toString()}`,
      );
    }
  }

  function handlePaginationChange(newPaginationModel: GridPaginationModel) {
    setLoading(true);
    setPaginationModel(newPaginationModel);
  }

  function handleSortChange(newSortModel: GridSortModel) {
    setLoading(true);
    setSortModel(newSortModel);
  }

  const allowDataGridSelection =
    (isFunction(onAgentsSelected) && allowSelection) ||
    (isFunction(onDeletableAgentsSelected) && allowSelection) ||
    (isFunction(onUpdatableAgentsSelected) && allowSelection);

  const noRowsOverlay = useMemo(() => {
    if (onboardingNoRowsOverlay && suggestionsQuery === "") {
      return AgentOnboardingNoRowsOverlay;
    }
  }, [onboardingNoRowsOverlay, suggestionsQuery]);

  return (
    <Stack spacing={1} height="100%">
      <SearchBar
        filterOptions={AGENTS_TABLE_FILTER_OPTIONS}
        suggestions={suggestions}
        onQueryChange={onQueryChange}
        suggestionQuery={suggestionsQuery}
        initialQuery={initQuery}
      />
      <EEAgentsDataGrid
        allowSelection={allowDataGridSelection}
        clearSelectionModelFnRef={clearSelectionModelFnRef}
        isRowSelectable={isRowSelectable}
        onAgentsSelected={handleSelect}
        density={density}
        minHeight={minHeight}
        maxHeight={maxHeight}
        loading={loading}
        agents={agents}
        agentMetrics={agentMetrics}
        columnFields={columnFields}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationChange}
        sortModel={sortModel}
        onSortModelChange={handleSortChange}
        rowCount={rowCount}
        noRowsOverlay={noRowsOverlay}
      />
    </Stack>
  );
};

export function muiSortModelToString(
  sortModel: GridSortModel,
): string | undefined {
  if (sortModel.length === 0) {
    return undefined;
  }

  let field = sortModel[0].field;
  const direction = sortModel[0].sort;

  if (direction === "desc") {
    field = `-${field}`;
  }

  return field;
}

/**
 * isDeletable returns true if the agent with that id is disconnected.
 *
 * @param agents the list of table agents
 * @param id the agent ID to check
 * @returns
 */
function isDeletable(agents: TableAgent[], id: string): boolean {
  return agents.some(
    (a) => a.id === id && a.status === AgentStatus.DISCONNECTED,
  );
}

/**
 * isUpdatable returns true if the agent with that id is connected and the agent's version
 * is not equal to the latest version.
 *
 * @param agents the list of table agents
 * @param id the agent ID to check
 * @param latestVersion the latest agent version
 * @returns
 */
function isUpdatable(agents: TableAgent[], id: string): boolean {
  return agents.some(
    (a) =>
      a.id === id && a.status === AgentStatus.CONNECTED && a.upgradeAvailable,
  );
}
