import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { edition } from "../BindplaneVersion/utils";

type EULAFieldProps = Pick<CheckboxProps, "onChange" | "checked">;

export const EULAField: React.FC<EULAFieldProps> = ({ onChange, checked }) => {
  const href =
    edition() === "Honeycomb"
      ? "https://www.honeycomb.io/acceptable-use-policy"
      : "https://observiq.com/legal/eula";
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox size="small" checked={checked} onChange={onChange} />
        }
        label={
          <Typography variant="body2">
            I accept the{" "}
            <a href={href} target="_blank" rel="noopener noreferrer">
              End User License Agreement
            </a>
            .
          </Typography>
        }
      />
    </FormGroup>
  );
};
