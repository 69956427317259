import { gql } from "@apollo/client";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Stack,
  Tab,
  Tabs,
  Tooltip,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useMemo, useState } from "react";
import { RBACWrapper } from "../../components/RBACWrapper/RBACWrapper";
import {
  Role,
  useGetConfigurationVersionsQuery,
  useGetLatestMeasurementIntervalQuery,
} from "../../graphql/generated";
import { useRefetchOnConfigurationChange } from "../../hooks/useRefetchOnConfigurationChanges";
import { useRole } from "../../hooks/useRole";
import { LiveOrDraft } from "../../pages/configurations/configuration";
import { hasPermission } from "../../utils/has-permission";
import { asCurrentVersion, nameAndVersion } from "../../utils/version-helpers";
import { DiffDialog } from "../DiffDialog/DiffDialog";
import { DiscardDialog } from "../DiscardDialog";
import { EditingControlBar } from "../EditingControlBar";
import { EditingState } from "../EditingControlBar/EditingControlBar";
import {
  DEFAULT_PERIOD,
  DEFAULT_TELEMETRY_TYPE,
  MeasurementControlBar,
} from "../MeasurementControlBar";
import { OtelConfigEditor } from "../OtelConfigEditor/OtelConfigEditor";
import { V2Switch } from "../PipelineGraph/V2Switch";
import { RolloutProgress } from "../RolloutProgress";
import { VersionsData } from "./versions-data";
import styles from "../PipelineGraph/pipeline-graph.module.scss";

gql`
  query getConfigurationVersions($name: String!) {
    configurationVersions(name: $name) {
      metadata {
        name
        id
        version
      }

      activeTypes

      status {
        current
        pending
        latest
      }
    }
  }

  query getLatestMeasurementInterval($name: String!) {
    configuration(name: $name) {
      metadata {
        name
        id
        version
      }

      spec {
        measurementInterval
      }
    }
  }
`;

interface ConfigurationEditorProps {
  configurationName: string;
  isOtel: boolean;
  hideRolloutActions?: boolean;
  historyVersion?: number;
  setHistoryVersion: (n?: number) => void;
  diffDialogOpen: boolean;
  setDiffDialogOpen: (b: boolean) => void;
  view: LiveOrDraft;
  setView: (v: LiveOrDraft) => void;
}

/**
 * ConfigurationEditor is a component used to edit and show information about a
 * BPOP Configuration.  It can show a YAML editor for an oTel configuration
 * or a pipeline graph for a pipeline configuration.
 *
 * @param configurationName should be the non-versioned name of the config
 * @param isOtel is a boolean that determines whether to display a PipelineGraph
 * or OtelConfig component.
 * @param hideRolloutActions is a boolean that determines whether to display the
 * rollout actions (i.e pause, resume, start)
 * @param historyVersion is used to sync RolloutHistory with DiffDialog
 * @param setHistoryVersion is used to sync RolloutHistory with DiffDialog
 * @param diffDialogOpen is used to sync RolloutHistory with DiffDialog
 * @param setDiffDialogOpen is used to sync RolloutHistory with DiffDialog
 * @returns
 */
export const ConfigurationEditor: React.FC<ConfigurationEditorProps> = ({
  configurationName,
  isOtel,
  hideRolloutActions,
  historyVersion,
  setHistoryVersion,
  diffDialogOpen,
  setDiffDialogOpen,
  view,
  setView,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [versionsData, setVersionsData] = useState<VersionsData>();

  const [discardDialogOpen, setDiscardDialogOpen] = useState(false);

  const [selectedTelemetry, setSelectedTelemetry] = useState<string>(
    DEFAULT_TELEMETRY_TYPE,
  );
  const [period, setPeriod] = useState<string>();
  const [measurementPeriods, setMeasurementPeriods] = useState<string[]>();
  const [versionState, setVersionState] = useState<
    "current" | "new" | "pending"
  >();

  const [showCompareVersions, setShowCompareVersions] =
    useState<boolean>(false);

  const [editingState, setEditingState] =
    useState<EditingState>("pastCompleted");

  const role = useRole();

  const { refetch } = useGetConfigurationVersionsQuery({
    variables: {
      name: configurationName,
    },
    onError(error) {
      console.error(error);
      enqueueSnackbar("Failed to fetch configuration versions.", {
        variant: "error",
      });
    },
    fetchPolicy: "network-only",
    onCompleted(data) {
      const newVersionsData = new VersionsData(data);
      if (newVersionsData.findNew()) {
        setVersionState("new");
      } else if (newVersionsData.findPending()) {
        setVersionState("pending");
      } else if (newVersionsData.findCurrent()) {
        setVersionState("current");
      }
      setVersionsData(newVersionsData);

      setSelectedTelemetry(
        newVersionsData.firstActiveType() ?? DEFAULT_TELEMETRY_TYPE,
      );

      if (newVersionsData.draftVersion()) {
        setView("draft");
      }
    },
  });

  const { refetch: refetchMI } = useGetLatestMeasurementIntervalQuery({
    variables: {
      name: asCurrentVersion(configurationName),
    },
    onCompleted(data) {
      if (data.configuration?.spec?.measurementInterval != null) {
        switch (data.configuration.spec.measurementInterval) {
          case "1m":
            setMeasurementPeriods(["5m", "1h", "24h"]);
            setPeriod("5m");
            break;
          case "15m":
            setMeasurementPeriods(["1h", "24h"]);
            setPeriod("1h");
            break;
          default:
            setMeasurementPeriods(["1m", "5m", "1h", "24h"]);
            setPeriod(DEFAULT_PERIOD);
        }
      }
    },
  });

  useRefetchOnConfigurationChange(configurationName, () => {
    refetch();
    refetchMI();
  });

  const discardTooltip = useMemo(() => {
    if (versionsData?.liveVersion() === undefined) {
      return "No previous version exists";
    }

    if (versionState === "pending") {
      return "Cannot discard draft after rollout begins";
    }

    if (versionState === "current") {
      return "No changes since last version";
    }

    return "";
  }, [versionsData, versionState]);

  const compareTooltip = useMemo(() => {
    if (versionsData?.liveVersion() === undefined) {
      return "No previous version exists";
    }

    if (versionState === "current") {
      return "No changes since last version";
    }

    return "";
  }, [versionsData, versionState]);

  if (versionState == null || versionsData == null) {
    return (
      <Card className={styles.card}>
        <Stack height={300} alignItems="center" justifyContent={"center"}>
          <CircularProgress size={100} />
        </Stack>
      </Card>
    );
  }

  const EditorComponent = isOtel ? OtelConfigEditor : V2Switch;

  const configurationNameAndVersion =
    view === "draft"
      ? nameAndVersion(configurationName, versionsData.draftVersion())
      : nameAndVersion(configurationName, versionsData.liveVersion());

  async function handleDiscardConfiguration() {
    try {
      await discardConfiguration(configurationName);
      setDiscardDialogOpen(false);
      setEditingState("discarding");
      enqueueSnackbar("Discarded draft!", {
        variant: "success",
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to discard draft", {
        variant: "error",
      });
    }
  }

  async function discardConfiguration(configurationName: string) {
    const url = `/v1/configurations/${configurationName}/revert`;
    const resp = await fetch(url, { method: "PUT" });

    if (!resp.ok) {
      throw new Error(
        `Failed to discard draft: ${resp.status} ${resp.statusText}`,
      );
    }
  }

  function handleLiveDraftSwitcherChange(v: LiveOrDraft) {
    setView(v);
    if (v === "live") {
      setEditingState("live");
    } else {
      setEditingState("draft");
    }
  }

  return (
    <>
      <RBACWrapper requiredRole={Role.User}>
        <EditingControlBar
          editingState={editingState}
          setEditingState={setEditingState}
          bottomMargin={isOtel}
        >
          <Stack
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            data-testid="edit-config-bar"
          >
            <Tooltip title={discardTooltip}>
              <span>
                <Button
                  variant="outlined"
                  disabled={
                    versionsData.liveVersion() === undefined ||
                    versionState === "pending" ||
                    versionState === "current"
                  }
                  onClick={() => setDiscardDialogOpen(true)}
                  color="error"
                  data-testid="config-edit-discard-button"
                >
                  Discard
                </Button>
              </span>
            </Tooltip>
            <Tooltip title={compareTooltip}>
              <span>
                <Button
                  variant="outlined"
                  disabled={!showCompareVersions}
                  onClick={() => setDiffDialogOpen(true)}
                  data-testid="config-edit-compare-button"
                >
                  Compare
                </Button>
              </span>
            </Tooltip>
            <RolloutProgress
              configurationName={configurationName}
              configurationVersion={
                versionState === "new" ? "latest" : versionState
              }
              hideActions={hideRolloutActions || versionState === "current"}
              setShowCompareVersions={setShowCompareVersions}
              setEditingState={setEditingState}
              editingState={editingState}
              view={view}
            />
          </Stack>
        </EditingControlBar>
      </RBACWrapper>
      <DiscardDialog
        open={discardDialogOpen}
        onDiscard={handleDiscardConfiguration}
        onClose={() => setDiscardDialogOpen(false)}
      />
      <DiffDialog
        onClose={() => setDiffDialogOpen(false)}
        configurationName={configurationName}
        open={diffDialogOpen}
        historyVersion={historyVersion}
        setHistoryVersion={setHistoryVersion}
      />
      <Box className={styles.card}>
        {!isOtel && (
          <MeasurementControlBar
            telemetry={selectedTelemetry!}
            onTelemetryTypeChange={setSelectedTelemetry}
            period={period ?? DEFAULT_PERIOD}
            onPeriodChange={setPeriod}
            periods={measurementPeriods}
          >
            <Stack
              direction={"row"}
              position={"absolute"}
              left={"50%"}
              justifyContent={"center"}
            >
              {versionsData.draftVersion() &&
                versionsData.findCurrent() !== undefined &&
                view !== undefined && (
                  <Tabs
                    value={view}
                    onChange={(_e, v: LiveOrDraft) =>
                      handleLiveDraftSwitcherChange(v)
                    }
                    className={styles["live-draft-switcher"]}
                  >
                    <Tab
                      label="Live"
                      value="live"
                      data-testid="config-live-tab"
                    />
                    <Tab
                      label="Draft"
                      value="draft"
                      data-testid="config-draft-tab"
                    />
                  </Tabs>
                )}
            </Stack>
          </MeasurementControlBar>
        )}
        <EditorComponent
          configurationName={configurationNameAndVersion}
          selectedTelemetry={selectedTelemetry!}
          period={period ?? DEFAULT_PERIOD}
          readOnly={
            (view === "live" && versionsData.draftVersion() != null) ||
            !hasPermission(Role.User, role)
          }
        />
      </Box>
    </>
  );
};
